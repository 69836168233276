import { createSlice } from '@reduxjs/toolkit'

const courseSlice = createSlice({
    name: 'course',
    initialState: {
        courseList: []
    },
    reducers: {
        setCourseList(state, action) {
            state.courseList = action.payload
        },
    }
})

export default courseSlice