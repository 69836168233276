module.exports = {
    styleParis: cv => {

        const { modelBaseColor = "#247BA0", modelTitleColor } = cv.Metadata

        return `
            /* ----------------------------------------------- DOCUMENT */
            .header {
                background-color: ${modelBaseColor};
                padding-top: 40px;
            }
            .body {
                display: block !important;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                margin-bottom: 5px;
            }
            .section-title{
                color: white;
                padding: 5px;
                background-color: ${modelTitleColor || modelBaseColor};
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                text-align: center;
                display: block;
                background-color: transparent;
                margin-bottom: 10px;
            }
            .profile-pic {
                border: 3px solid white;
                width: 140px;
                height: 140px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                border-radius: 75px;
                margin: 0px auto;
                display: inline-block;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                display: block;
                background-color: transparent;
                padding: 0px 0px 20px 0px;
            }
            .name {
                color: white;
                text-align: center;
                text-shadow: 0px 0px 5px black;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);		
            }
            .professional-title{
                color: white;
                text-align: center;
                text-shadow: 0px 0px 5px black;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);		
            }
            /* ----------------------------------------------- SECTION PERIOD */
            .section-period-container{
                text-align: right;
            }
            /* ----------------------------------------------- PORTFOLIO */
            .unique-column > * .portfolio-item{
                height: 105px !important;
                width: 105px !important;
            }
            /* ----------------------------------------------- ATTACHMENT */
            .unique-column > * .attachment-item{
                height: 165px !important;
                width: 165px !important;
            }
        `

    }
}