import { getNewUserData } from "cvpop-utility-sdk";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";

import { db } from "./firebase";

export const postLogin = async ({ user, language, platform }) => {

    if (!user) return await Promise.reject({ code: 'postlogin/user-null' })

    const uid = user.uid;
    const newUserData = getNewUserData(user, language, platform, window.navigator.userAgent)
    
    const udoc = await getDoc(doc(db, "00_USERS_00", uid))

    if (!udoc.exists()) 
        await setDoc(doc(collection(db, "00_USERS_00"), uid), newUserData);

    return udoc.exists() ? udoc.data() : newUserData
}