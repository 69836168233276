const { sectionTitle } = require("./section-title")

module.exports = {
    attachments: cv => {

        const { Attachment = [] } = cv.SkillsPassport.LearnerInfo

        if (Attachment.length === 0) return ""

        return `
        <div class="attachment-container container">
            ${sectionTitle(cv, "cvLabelAttachment", "Attachment", "fas fa-paperclip")}
            <div class="attachment-content content">
                ${Attachment.map(({ qrCodeData, filename }) => `
                    <div class="attachment-item-content" style="display: inline-block;">
                        <div class="attachment-item" style="background-image: url(data:$image/png;base64,${qrCodeData})"></div>
                        <span class="attachment-file-name">${filename}</span>
                    </div>
                `).join("")}
            </div>
        </div>   
        `

    }
}