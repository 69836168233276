import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Select, SelectItem, Popover, PopoverTrigger, PopoverContent, Button, ButtonGroup, } from "@nextui-org/react";

import { produce } from "immer"

import { setCv } from "cvpop-redux-sdk";
import { colors, fontCatalogue } from 'cvpop-constants-sdk';
import { t } from "cvpop-localization-sdk"

import WebColorPickerView from './WebColorPickerView';

import { useColorScheme } from '../../../controllers/hooks';



const WebCommonTextView = ({ title = "", k = "" }) => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const cvMet = useMemo(() => cv.Metadata, [cv.Metadata])
    const isFontChangeOn = useMemo(() => (!["zh", "ja"].includes(cvMet.lng)), [cvMet])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <p className="text-xs text-default-600 font-bold uppercase ml-3 mt-2 mb-2">{title}</p>
            {
                isFontChangeOn && (
                    <Select
                        label={t("font")}
                        size={"sm"}
                        disallowEmptySelection
                        classNames={{ popoverContent: `${cs} text-foreground` }}
                        defaultSelectedKeys={[cvMet[`${k}Font`] || (cvMet.modelBaseFont || 'Montserrat')]}
                        onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata[`${k}Font`] = target.value }) }))}
                    >
                        {fontCatalogue.map(({ name }) => (
                            <SelectItem key={name} value={name} style={{ fontFamily: `${name}, sans-serif` }}>
                                {name}
                            </SelectItem>
                        ))}
                    </Select>
                )
            }


            <div className="flex items-stretch py-4">
                <Popover backdrop={"blur"} className={`${cs} text-foreground`}>
                    <PopoverTrigger>
                        <div className="w-24 self-stretch rounded-xl" style={{ backgroundColor: cvMet[`${k}Color`] || colors.light.text }} />
                    </PopoverTrigger>
                    <PopoverContent className="p-0">
                        <WebColorPickerView
                            onChange={c => d(setCv({ cv: produce(cv, d => { d.Metadata[`${k}Color`] = c }) }))}
                            color={cvMet[`${k}Color`] || colors.light.text}
                        />
                    </PopoverContent>
                </Popover>

                <ButtonGroup className="mx-4">
                    <Button
                        isIconOnly
                        color={"primary"}
                        size={"lg"}
                        onPress={() => d(setCv({ cv: produce(cv, d => { d.Metadata[`${k}InUppercase`] = !(!!d.Metadata[`${k}InUppercase`]) }) }))}
                        variant={cvMet[`${k}InUppercase`] ? "solid" : "bordered"}
                    >
                        <span className="material-symbols-outlined text-3xl">match_case</span>
                    </Button>
                    <Button
                        isIconOnly
                        color={"primary"}
                        size={"lg"}
                        onPress={() => d(setCv({ cv: produce(cv, d => { d.Metadata[`${k}InBold`] = !(!!d.Metadata[`${k}InBold`]) }) }))}
                        variant={(cvMet[`${k}InBold`] || !["company", "institute"].includes(k) && cvMet[`${k}InBold`] === undefined) ? "solid" : "bordered"}
                    >
                        <span className="material-symbols-outlined text-3xl">format_bold</span>
                    </Button>
                    <Button
                        isIconOnly
                        color={"primary"}
                        size={"lg"}
                        onPress={() => d(setCv({ cv: produce(cv, d => { d.Metadata[`${k}InItalic`] = !(!!d.Metadata[`${k}InItalic`]) }) }))}
                        variant={(cvMet[`${k}InItalic`] || ["company", "institute"].includes(k) && cvMet[`${k}InItalic`] === undefined) ? "solid" : "bordered"}
                    >
                        <span className="material-symbols-outlined text-3xl">format_italic</span>
                    </Button>
                    <Button
                        isIconOnly
                        color={"primary"}
                        size={"lg"}
                        onPress={() => d(setCv({ cv: produce(cv, d => { d.Metadata[`${k}InUnderline`] = !(!!d.Metadata[`${k}InUnderline`]) }) }))}
                        variant={cvMet[`${k}InUnderline`] ? "solid" : "bordered"}
                    >
                        <span className="material-symbols-outlined text-3xl">format_underlined</span>
                    </Button>
                </ButtonGroup>

            </div>
        </div>
    )

}

export default WebCommonTextView