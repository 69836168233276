import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { User } from "@nextui-org/react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Button, Link, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Image } from "@nextui-org/react";
import { signOut } from 'firebase/auth';

import { t } from 'cvpop-localization-sdk';
import { urls } from 'cvpop-constants-sdk';
import { logos, icons } from 'cvpop-assets-sdk';
import { setUser } from 'cvpop-redux-sdk';
import { getAvatarUser, isProUser } from 'cvpop-utility-sdk';

import { confirmAlert, errorHandler, goToPaywall, openUrl } from '../../controllers/app';
import { auth } from '../../controllers/firebase';
import { useColorScheme } from '../../controllers/hooks';

import WebIcon from './WebIcon';


const WebNavbar = ({ onboarding, navbarProps }) => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform = "web" } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------------------------
    const { displayName, isAnonymous } = fbuser || {}; //in case of logout
    const { firstName, lastName } = user || {}
    const { legal } = urls

    // ------------------------------------------------------------------------------------------------------------------------
    const avatar = useMemo(() => getAvatarUser(user, platform) || icons.avatar, [user, platform])
    const isWeb = useMemo(() => platform === "web", [platform])
    const isPro = useMemo(() => isProUser(user), [user])

    // ------------------------------------------------------------------------------------------------------------------------
    const onProfileAction = useCallback(k => {
        if (k === "terms" || k === "privacy") return openUrl(legal[k], platform)
        if (k === "premium") return goToPaywall(offering, platform, n)
        if (k === "logout") return confirmAlert(t("logout"), t("logoutMsg"))
            .then(() => signOut(auth))
            .then(() => {
                n(`/app/${platform}/onboarding`)
                d(setUser({ user: null, ignoreListener: true }))
            })
            .catch(e => errorHandler("err_logout", e))
        n(`/app/${platform}/home/${k}`)
    }, [legal, offering, platform, n])

    const onPremiumPress = useCallback(() => goToPaywall(offering, platform, n), [offering, platform, n])

    // ------------------------------------------------------------------------------------------------------------------------
    if (!isWeb) return null
    return (
        <Navbar maxWidth={"full"} className='bg-content1' isBordered {...navbarProps}>
            <NavbarBrand>
                <Image
                    radius={"none"}
                    alt={"cvpop"}
                    src={logos.apptext[cs]}
                    className='w-28 cursor-pointer'
                    onClick={() => n(onboarding ? `/app/${platform}/onboarding` : `/app/${platform}/home/cv-list`)}
                />
            </NavbarBrand>
            {
                !onboarding && (
                    fbuser ? (
                        <NavbarContent justify="end">

                            {
                                !isPro && (
                                    <NavbarItem className="mr-4">
                                        <Button
                                            variant={"light"}
                                            size={"sm"}
                                            color={"warning"}
                                            startContent={(<WebIcon name={"workspace_premium"} className={"filled text-yellow-500"} />)}
                                            onClick={onPremiumPress}
                                        >
                                            {t("passToPremium")}
                                        </Button>
                                    </NavbarItem>
                                )
                            }
                            <NavbarItem className='flex items-center'>

                                <Dropdown placement="bottom-end">
                                    <DropdownTrigger>
                                        <User
                                            as={"button"}
                                            name={!firstName && !displayName && !lastName ? t("account") : `${firstName || displayName || ""} ${lastName || ""}`}
                                            avatarProps={{ isBordered: true, color: "primary", size: "sm", src: avatar }}
                                        />
                                    </DropdownTrigger>
                                    <DropdownMenu aria-label="Profile Actions" color={"primary"} onAction={onProfileAction}>
                                        <DropdownItem key="account" title={t("account")} showDivider endContent={<WebIcon name={"account_circle"} />} />
                                        <DropdownItem key="terms" title={t("terms")} endContent={<WebIcon name={"contract"} />} />
                                        <DropdownItem key="privacy" title={t("privacy")} endContent={<WebIcon name={"verified_user"} />} />
                                        <DropdownItem key="credits" title={t("credits")} showDivider={!isAnonymous} endContent={<WebIcon name={"sdk"} />} />
                                        {!isAnonymous && (<DropdownItem key="logout" title={t("logout")} endContent={<WebIcon name={"logout"} />} />)}
                                    </DropdownMenu>
                                </Dropdown>

                            </NavbarItem>
                        </NavbarContent>
                    ) : (
                        <NavbarContent justify="end">
                            <NavbarItem>
                                <Button as={Link} color="primary" href="app/web/sign" variant={"bordered"}>
                                    {t("login")}
                                </Button>
                            </NavbarItem>
                            <NavbarItem>
                                <Button as={Link} color="primary" href="app/web" variant="solid">
                                    {t("createYourCv")}
                                </Button>
                            </NavbarItem>
                        </NavbarContent>
                    )
                )
            }

        </Navbar>
    )

}

export default WebNavbar