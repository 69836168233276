import { createSlice } from '@reduxjs/toolkit'

const jobSlice = createSlice({
    name: 'job',
    initialState: {
        jobList: []
    },
    reducers: {
        setJobList(state, action) {
            state.jobList = action.payload
        },
    }
})

export default jobSlice