module.exports = {
    stylePechino: cv => {

        const { modelBaseColor = "#247BA0", modelTitleColor, lng } = cv.Metadata
        
        return `
            /* ----------------------------------------------- DOCUMENT */
            .header {
                padding: 0px;
                position: relative;
            }
            .header-divider {
                position: absolute;
                height: 135px;
                width: 100%;
                background: ${modelBaseColor};
                z-index: 1;
            }
            .header-content{
                padding: 20px 40px 0px 40px;
                position: relative;
                display: table;
                width: -webkit-fill-available;
            }
            .body {
                display: block !important;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                margin-bottom: 0px;
            }
            .section-title{
                color: white;
                padding: 5px;
                background-color: ${modelTitleColor || modelBaseColor};
                border: 1px solid ${modelTitleColor || modelBaseColor};
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            .section-title-keyline{
                margin: 0px 0 5px;
                border-top: 2px solid ${modelTitleColor || modelBaseColor};
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                background-color: transparent;
                position: relative;
                overflow: hidden;
                z-index: 2;
                ${lng === "ar" ? "padding-left: 20px" : "padding-right: 20px"}; 
            }
            .profile-pic {
                width: 160px;
                height: 240px;
                margin: 0;
                border-radius: 0px !important;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                display: block;
            }
            .name, .surname {
                font-weight: bold;
                position: relative;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .name {
                z-index: 1;
                color: white;
                display: table;
                height: 115px;
            }
            .name > span {
                display: table-cell;
                vertical-align: middle;
                padding-top: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 490px;
            }
            .surname > span{
                display: table-cell;
                vertical-align: middle;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 490px;
            }
            .surname {
                display: table;
                height: 90px;
                color: ${modelBaseColor};
            }
            .professional-title{
                color: ${modelBaseColor};
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .professional-title > span {
                display: table-cell;
                vertical-align: middle;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 490px;
            }
            /* ----------------------------------------------- SECTION PERIOD */
            .section-period-container{
                text-align: right;
            }
            /* ----------------------------------------------- PORTFOLIO */
            .unique-column > * .portfolio-item{
                height: 105px !important;
                width: 105px !important;
            }
            /* ----------------------------------------------- ATTACHMENT */
            .unique-column > * .attachment-item{
                height: 165px !important;
                width: 165px !important;
            }
        `

    }
}