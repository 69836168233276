const _ = require("lodash")

const { sectionDescription } = require("./section-description")
const { sectionTitle } = require("./section-title")

module.exports = {
    summary: cv => {

        const { Summary = {} } = cv.SkillsPassport.LearnerInfo.Identification

        if (_.isEmpty(Summary) || !Summary?.Description) return ""

        return `
        <div class="summary-container container">
            ${sectionTitle(cv, "cvLabelSummary", "Summary", "fas fa-user")}
            <div class="summary-content content">
                ${sectionDescription(Summary.Description)}
            </div>
        </div>
        `

    }
}