import { useEffect } from "react"
import { Spinner } from "@nextui-org/react";

const WebLinkedinCallback = () => {

    useEffect(() => {
        window.ReactNativeWebView?.postMessage("CALLBACK")
    }, [])

    return (
        <div className="flex items-center justify-center h-screen">
            <Spinner />
        </div>
    )
}

export default WebLinkedinCallback