import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useLocation, useParams, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setOffering, setUser, setUserLogged } from 'cvpop-redux-sdk';
import { onAuthStateChanged } from "firebase/auth";
import { fetchAndActivate } from 'firebase/remote-config';

import { auth, db, remoteConfig } from '../../controllers/firebase';
import { errorHandler, getLemonSqueezyCheckoutUrl, initIubendaCookieBanner, setAppLocalization } from '../../controllers/app';
import { postLogin } from "../../controllers/user"

import WebSpinnerView from '../molecules/views/WebSpinnerView';
import WebErrorView from '../molecules/views/WebErrorView';
import WebSpinnerModal from '../molecules/modals/WebSpinnerModal';
import WebPrivacyModal from '../molecules/modals/WebPrivacyModal';
import { doc, onSnapshot } from 'firebase/firestore';


const WebAppContainer = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { language } = useSelector(state => state.webReducer)
    const { platform } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const d = useDispatch()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------------------------
    const tryAutoLogin = useCallback(fbuser => {

        setLoading(true)
        setError(false)
        postLogin({ user: fbuser, language })
            .then(user => {
                d(setUser({ user }))
                d(setUserLogged(true))
                if ([`/app/${platform}`, `/app/${platform}/`].includes(pathname)) return n(`/app/${platform}/home/cv-list`)
            })
            .catch(e => {
                errorHandler("err_autologin", e, true)
                setError(true)
            })
            .finally(() => setLoading(false))

    }, [language])

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        fetchAndActivate(remoteConfig)
            .then(r => console.log("REMOTE CONFIG: ", r))
            .catch(e => errorHandler("err_fetch_remote_config", e, true));

        const obs = onAuthStateChanged(auth, fbuser => {
            if (fbuser) return tryAutoLogin(fbuser)
            setLoading(false)
            return n(`/app/${platform}/onboarding`)
        });
        return () => obs()
    }, [])

    useEffect(() => {
        if (!fbuser || !user) return

        if (user && user.isMarketing) setAppLocalization("en")
        if (platform === "web") initIubendaCookieBanner()
        platform === "web" ?
            d(setOffering(getLemonSqueezyCheckoutUrl(fbuser, user))) :
            d(setOffering(null))

    }, [fbuser, user, platform])

    
    useEffect(() => {
        if (!fbuser || !user) return
        const unsub = onSnapshot(doc(db, "00_USERS_00", fbuser.uid), doc => d(setUser({ user: doc.data(), ignoreListener: true })));
        return () => unsub()
    }, [db, fbuser])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <>
            {loading ? (
                <WebSpinnerView />
            ) : error ? (
                <WebErrorView buttonProps={{ onClick: tryAutoLogin }} />
            ) : (
                <div>
                    <Outlet />
                </div>
            )}

            <WebPrivacyModal />
            <WebSpinnerModal />
        </>
    )
}

export default WebAppContainer