const _ = require("lodash")

const { sectionDescription } = require("./section-description")
const { sectionTitle } = require("./section-title")

module.exports = {
    headline: cv => {

        const { Headline = {} } = cv.SkillsPassport.LearnerInfo
        const { Type = {}, Description = {} } = Headline

        if (_.isEmpty(Type) || !Type?.Code) return ""

        return `
        <div class="headline-container container">
            ${sectionTitle(cv, Type.Code, "headline", "fas fa-trophy")}
            <div class="headline-content content">
                ${Description.Label ? sectionDescription(Description.Label) : ""}
            </div>
        </div>
        `
    }
}
