const { currencies } = require("cvpop-collections-sdk");

module.exports = {

    getJobSalary: job => {
        const { salary_min, salary_max, salary_currency_code, salary_type } = job || {};

        if (!salary_min || !salary_max || !salary_currency_code || !salary_type) return null

        return {
            min: salary_min,
            max: salary_max,
            currency: currencies[salary_currency_code] || salary_currency_code,
            period: `P1${salary_type}`
        }

    },

    getJobSalaryMultiplier: p => p === "HOUR" ? 8766 : p === "DAY" ? 356 : p === "WEEK" ? 52 : p === "MONTH" ? 12 : 1,

    getJobIdFromUrl: url => url.split("/").pop().split(".html")[0]

}