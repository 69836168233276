const { sectionTitle } = require("./section-title")

module.exports = {
    driverLicencesSpecial: cv => {

        const { DriverLicenceSpecial = [] } = cv.SkillsPassport.LearnerInfo

        if (DriverLicenceSpecial.length === 0) return ""

        return `
            <div class="drive-licence-container container">
                ${sectionTitle(cv, "cvLabelSpecialDriverLicence", "DrivingSpecial", "fas fa-bus")}
                <div class="drive-licence-content content">
                    <p class="drive-licence-text ptXS">
                        ${DriverLicenceSpecial.map(({ Title }) => Title).join(", ")}
                    </p>
                </div>
            </div>
        `
    }
}
