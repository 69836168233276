import React, { useMemo, useState } from 'react';
import { Card, CardBody, Button, Pagination } from "@nextui-org/react"
import { useNavigate, useParams } from "react-router-dom";

import { illustrations } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk";

import { auth } from "../../../controllers/firebase"

import WebIcon from '../../atoms/WebIcon';

const WebIntroFeedback = () => {

    // ------------------------------------------------------------------------------------------------------
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                {
                    platform !== "web" && (
                        <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }
                <CardBody>
                    <div className="max-w-80 self-center">
                        <img alt={t("introFeedbackTitle")} src={illustrations.review} />
                    </div>
                    <p className="font-sans font-bold text-2xl text-center mt-3">{t("introFeedbackTitle")}</p>
                    <p className="font-sans text-center mt-3">{t("introFeedbackMsg")}</p>

                    <Button
                        color="primary"
                        className='mt-3'
                        onClick={() => {
                            window.webkit.messageHandlers["askForRate"].postMessage("appstore");
                            n(`/app/${platform}/onboarding/paywall`, { state: { onboarding: true } })
                        }}
                    >
                        {t("continue")}
                    </Button>

                </CardBody>
            </Card>
        </div>
    )
}

export default WebIntroFeedback