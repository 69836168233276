import { useEffect, useState } from 'react'

export const useColorScheme = () => {

    const [scheme, setScheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => setScheme(e.matches ? 'dark' : 'light'));
        return () => window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => { })
    }, []);

    return scheme;
}