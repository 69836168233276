import { useEffect } from 'react';
import { Link, Button, Divider, Card, CardBody, CardFooter, CardHeader, Avatar } from "@nextui-org/react";

import _ from "lodash"

import { t } from "cvpop-localization-sdk"
import { urls } from "cvpop-constants-sdk"
import { icons, illustrations, logos, pictures } from "cvpop-assets-sdk"

import { useColorScheme } from '../../controllers/hooks';
import { initIubendaCookieBanner } from '../../controllers/app';

import WebIcon from '../atoms/WebIcon';
import WebNavbar from '../atoms/WebNavbar';

const WebLanding = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { terms, privacy, cookie } = urls.legal
    const { appstore, googleplay } = urls.store
    const { facebook, instagram, x, youtube, linkedin } = urls.social

    // ------------------------------------------------------------------------------------------------------------------------
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        initIubendaCookieBanner()
    }, [])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className={`landing ${cs}`}>
            <WebNavbar navbarProps={{ isBordered: false, maxWidth: "lg", className: "" }} />
            <div className="container mx-auto max-w-[1024px] px-6">

                <div className="grid grid-cols-1 sm:grid-cols-5 gap-6 items-center mt-28">
                    <div className="col-span-3">
                        <p className="text-sm text-content">{t("landingTopTitle").toUpperCase()}</p>
                        <p className="font-bold text-6xl mt-2">{t("landingTitle")}</p>
                        <p className="mt-6 text-2xl">{t("landingSubTitle")}</p>
                        <div className="mt-6">
                            <Button as={Link} size={"lg"} color="primary" href="app/web">
                                {t("createCvIn5Min")}
                            </Button>
                        </div>
                    </div>
                    <div className='col-span-2 hidden sm:inline-block'>
                        <img alt={t("appName")} className="rounded-2xl shadow-xl" src={pictures.cvlanding} />
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 items-center items-stretch mt-14">
                    {([{ name: "@IsraelOdyssey" }, { name: "@VyrleesDrakkar" }, { name: "@juzinha2003" }]).map((e, i) => (
                        <Card key={`review${e.name}`} className="" shadow={"none"}>
                            <CardHeader className="justify-between">
                                <div className="flex gap-5">
                                    <Avatar isBordered radius="full" size="md" src={`https://i.pravatar.cc/100?img=${i + 3}`} />
                                    <div className="flex flex-col gap-1 items-start justify-center">
                                        <h4 className="text-small font-semibold leading-none text-default-600">{e.name}</h4>
                                        <div className='text-warning'>
                                            {_.range(5).map(e => <WebIcon key={`start${e}`} name={"star"} className={"filled -ml-1"} />)}
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="p-3 pt-0">
                                <p className='font-seminbold'>{t(`landingReviewTitle${i + 1}`)}</p>
                                <p className='text-small mt-1 text-default-400'>{t(`landingReviewText${i + 1}`)}</p>
                            </CardBody>
                        </Card>
                    ))}

                </div>

                <Divider orientation={"horizontal"} className='my-20' />

                <div className="grid grid-cols-1 sm:grid-cols-5 items-center">
                    <div className='col-span-2 flex justify-center'>
                        <img alt={t("landingModelsTitle")} className="max-h-80" src={illustrations.cv} />
                    </div>
                    <div className="col-span-3">
                        <p className="text-sm text-content">{t("models").toUpperCase()}</p>
                        <p className="font-bold text-4xl mt-2">{t("landingModelsTitle")}</p>
                        <p className="mt-6 text-2xl">{t("landingModelsMsg")}</p>
                        <div className="grid grid-cols-1 mt-6">
                            {[0, 2].map(e => (
                                <div key={`modelFeature${e}`} className='flex'>
                                    <div className='flex items-center'>
                                        <WebIcon name={"done"} className={"text-success text-md"} />
                                        <p className='ml-1 text-sm'>{t(`landingModelTip${e + 1}`)}</p>
                                    </div>
                                    <div className='flex items-center ml-6'>
                                        <WebIcon name={"done"} className={"text-success text-md"} />
                                        <p className='ml-1 text-sm'>{t(`landingModelTip${e + 2}`)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Button as={Link} size={"lg"} className='mt-6' color="primary" href="app/web">
                            {t("chooseYourModel")}
                        </Button>
                    </div>
                </div>

                <Divider orientation={"horizontal"} className='my-20' />

                <div className="grid grid-cols-1 sm:grid-cols-5 gap-6 items-center">
                    <div className="col-span-3 hidden sm:inline-block">
                        <p className="text-sm text-content">{t("customization").toUpperCase()}</p>
                        <p className="font-bold text-4xl mt-2"> {t("landingCustomizationTitle")}</p>
                        <p className="mt-6 text-2xl">{t("introCustomizationFeat")}</p>
                        <div className="grid grid-cols-1 mt-6">
                            {[0, 2].map(e => (
                                <div key={`customizationFeature${e}`} className='flex'>
                                    <div className='flex items-center'>
                                        <WebIcon name={"done"} className={"text-success text-md"} />
                                        <p className='ml-1 text-sm'>{t(`landingCustomizationTip${e + 1}`)}</p>
                                    </div>
                                    <div className='flex items-center ml-6'>
                                        <WebIcon name={"done"} className={"text-success text-md"} />
                                        <p className='ml-1 text-sm'>{t(`landingCustomizationTip${e + 2}`)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Button as={Link} size={"lg"} className='mt-6' color="primary" href="app/web">
                            {t("customizeYourCv")}
                        </Button>
                    </div>
                    <div className='col-span-2 flex justify-center'>
                        <img alt={t("landingCustomizationTitle")} className="max-h-80" src={illustrations.customization} />
                    </div>
                    <div className="col-span-3 block sm:hidden">
                        <p className="text-sm text-content">{t("customization").toUpperCase()}</p>
                        <p className="font-bold text-4xl mt-2"> {t("landingCustomizationTitle")}</p>
                        <p className="mt-6 text-2xl">{t("introCustomizationFeat")}</p>
                        <div className="grid grid-cols-1 mt-6">
                            {[0, 2].map(e => (
                                <div key={`customizationFeatureReverse${e}`} className='flex'>
                                    <div className='flex items-center'>
                                        <WebIcon name={"done"} className={"text-success text-md"} />
                                        <p className='ml-1 text-sm'>{t(`landingCustomizationTip${e + 1}`)}</p>
                                    </div>
                                    <div className='flex items-center ml-6'>
                                        <WebIcon name={"done"} className={"text-success text-md"} />
                                        <p className='ml-1 text-sm'>{t(`landingCustomizationTip${e + 2}`)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Button as={Link} size={"lg"} className='mt-6' color="primary" href="app/web">
                            {t("customizeYourCv")}
                        </Button>
                    </div>
                </div>

                <Divider orientation={"horizontal"} className='my-20' />

                <div className="grid grid-cols-1 sm:grid-cols-5 gap-6 items-center">
                    <div className='col-span-2 flex justify-center'>
                        <img alt={t("landingRatingTitle")} className="max-h-80" src={illustrations.rating} />
                    </div>
                    <div className="col-span-3">
                        <p className="text-sm text-content">{t("rates").toUpperCase()}</p>
                        <p className="font-bold text-4xl mt-2">{t("landingRatingTitle")}</p>
                        <p className="mt-6 text-2xl">{t("introRatingFeat")} </p>
                        <div className="grid grid-cols-1 mt-6">
                            {[0, 2].map(e => (
                                <div key={`ratingFeature${e}`} className='flex'>
                                    <div className='flex items-center'>
                                        <WebIcon name={"done"} className={"text-success text-md"} />
                                        <p className='ml-1 text-sm'>{t(`landingRatingTip${e + 1}`)}</p>
                                    </div>
                                    <div className='flex items-center ml-6'>
                                        <WebIcon name={"done"} className={"text-success text-md"} />
                                        <p className='ml-1 text-sm'>{t(`landingRatingTip${e + 2}`)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Button as={Link} size={"lg"} className='mt-6' color="primary" href="app/web"  >
                            {t("getCvRate")}
                        </Button>
                    </div>
                </div>

                <Divider orientation={"horizontal"} className='my-20' />

                <div>
                    <p className="font-bold text-5xl text-center">{t("muchMore")}</p>
                    <p className="mt-6 text-2xl text-center">{t("muchMoreMsg")}</p>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-3 gap-6 items-stretch  mt-6">
                        {
                            ["MultipleCv", "Translate", "AiEditor", "Import", "Salary", "Interview"].map(e => (
                                <Card key={`featureMore${e}`} shadow={"none"}>
                                    <CardBody>
                                        <div className='flex items-center h-full'>
                                            <img src={icons[`feature${e.toLowerCase()}`]} className='h-24 w-24 object-contain' />
                                            <div className='ml-4 w-full'>
                                                <p className='font-bold text-xl'>{t(`feat${e}`)}</p>
                                                <p className='mt-1'>{t(`feat${e}Msg`)}</p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))
                        }
                    </div>
                    <div className='mt-6 text-center'>
                        <Button size={"lg"} color={"primary"}>{t("tryAppNowForFree")}</Button>
                    </div>
                </div>

                <Divider orientation={"horizontal"} className='my-20' />

                <div>
                    <p className="font-bold text-5xl text-center">{t("availableEverywhere")}</p>
                    <p className="mt-6 text-2xl text-center">{t("availableEverywhereMsg")}</p>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 items-stretch mt-6">
                        {
                            ["appstore", "playstore", "macappstore", "webapp"].map(e => (
                                <Card
                                    key={e}
                                    isPressable
                                    as={Link}
                                    href={e === "appstore" || e === "macappstore" ? appstore : e === "playstore" ? googleplay : "app/web"}
                                    target={e !== "webapp" ? "_" : undefined}
                                >
                                    <CardBody>
                                        <img src={pictures[`previewapp${e.toLowerCase()}`]} className='h-60 object-contain' />
                                    </CardBody>
                                    <CardFooter className='justify-center'>
                                        <img src={logos.store[`${e.toLowerCase()}`]} className='max-h-12 object-contain' />
                                    </CardFooter>
                                </Card>
                            ))
                        }

                    </div>
                </div>


                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 items-center mt-6">
                    <div className='text-sm text-center mb-0 sm:mb-6'>
                        <p>&copy; {new Date().getFullYear()} Giovanna Clemente</p>
                        <p>VAT ID: IT04793140619</p>
                    </div>
                    <div className='text-center mb-0 sm:mb-6'>
                        <Button isIconOnly variant={"light"} as={Link} href={facebook} target='_'>
                            <i className="fa-brands fa-facebook"></i>
                        </Button>
                        <Button isIconOnly variant={"light"} as={Link} href={instagram} target='_'>
                            <i className="fa-brands fa-instagram"></i>
                        </Button>
                        <Button isIconOnly variant={"light"} as={Link} href={linkedin} target='_'>
                            <i className="fa-brands fa-linkedin"></i>
                        </Button>
                        <Button isIconOnly variant={"light"} as={Link} href={x} target='_'>
                            <i className="fa-brands fa-x-twitter"></i>
                        </Button>
                        <Button isIconOnly variant={"light"} as={Link} href={youtube} target='_'>
                            <i className="fa-brands fa-youtube"></i>
                        </Button>
                        <Button isIconOnly variant={"light"} as={Link} href={"mailto:info@cvpop.com"} target='_'>
                            <i className="fa-regular fa-envelope"></i>
                        </Button>
                    </div>
                    <div className="grid grid-cols-3 text-center items-center justify-center mb-6">
                        <Button as={Link} href={privacy} target='_' color={"primary"} variant={"light"} size={"sm"} className='text-wrap'>
                            {t("privacy")}
                        </Button>
                        <Button as={Link} href={terms} target='_' color={"primary"} variant={"light"} size={"sm"} className='text-wrap'>
                            {t("terms")}
                        </Button>
                        <Button as={Link} href={cookie} target='_' color={"primary"} variant={"light"} size={"sm"} className='text-wrap'>
                            {t("Cookie")}
                        </Button>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default WebLanding