import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer"
import { Listbox, ListboxItem, Card, CardBody, ButtonGroup, Button } from "@nextui-org/react";

import { setCv } from "cvpop-redux-sdk";
import { defaultModelLayout, modelCatalogue } from "cvpop-constants-sdk";
import { getLabelFromCvSection, getCvSectionOrder } from "cvpop-utility-sdk";
import { t } from "cvpop-localization-sdk";

import _ from "lodash";

import WebHeader from '../../../molecules/views/WebHeader';
import WebListItemHeaderView from '../../../molecules/views/WebListItemHeaderView';
import WebIcon from '../../../atoms/WebIcon';


const WebSectionSort = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------
    const layoutCv = useMemo(() => getCvSectionOrder(cv) || defaultModelLayout[_.find(modelCatalogue, o => o.code === (cv.Metadata.modelType || 'london')).layout], [cv])
    const layoutKeys = useMemo(() => Object.keys(layoutCv), [cv])
    const orderedKeys = useMemo(() => _.compact([layoutKeys.includes("left") && "left", layoutKeys.includes("right") && "right", layoutKeys.includes("center") && "center"]), [cv])

    // ------------------------------------------------------------------------------------------------------
    const onItemUp = useCallback((el, secK, secIndex) => {

        let newLayout = _.cloneDeep(layoutCv);

        if (secIndex === 0) {
            const orderSecIndex = orderedKeys.findIndex(e => e === secK)
            newLayout[orderedKeys[orderSecIndex - 1]].push(el)
            _.remove(newLayout[orderedKeys[orderSecIndex]], e => e === el)
        } else {
            newLayout[secK][secIndex - 1] = el
            newLayout[secK][secIndex] = layoutCv[secK][secIndex - 1]
        }

        d(setCv({ cv: produce(cv, d => { d.Metadata.modelArrange = newLayout }) }))

    }, [cv])

    const onItemDown = useCallback((el, secK, secIndex) => {

        let newLayout = _.cloneDeep(layoutCv);

        if (secIndex === layoutCv[secK].length - 1) {
            const orderSecIndex = orderedKeys.findIndex(e => e === secK)
            newLayout[orderedKeys[orderSecIndex + 1]].unshift(el)
            _.remove(newLayout[orderedKeys[orderSecIndex]], e => e === el)
        } else {
            newLayout[secK][secIndex + 1] = el
            newLayout[secK][secIndex] = layoutCv[secK][secIndex + 1]
        }

        d(setCv({ cv: produce(cv, d => { d.Metadata.modelArrange = newLayout }) }))

    }, [cv])


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t('sectionSort')} />
            <div className="mx-5 mb-5">

                {
                    orderedKeys.map((k, keyI) => (
                        <div key={`cont${keyI}`}>
                            <WebListItemHeaderView text={t(`${k}Column`)} />
                            <Card>
                                <CardBody className="p-2">
                                    <Listbox variant={"light"} aria-label={t(`${k}Column`)} emptyContent={<p className='text-sm'>{t("emptyListMsg")}</p>}>
                                        {layoutCv[k].map((e, secI) => (
                                            <ListboxItem
                                                key={`sec${e}`}
                                                title={t(getLabelFromCvSection(e))}
                                                endContent={(
                                                    <ButtonGroup variant={"light"}>
                                                        <Button isDisabled={secI === 0 && keyI === 0} isIconOnly size={"sm"} onClick={() => onItemUp(e, k, secI)}>
                                                            <WebIcon name={"arrow_upward"} className={"text-base"} />
                                                        </Button>
                                                        <Button isDisabled={secI === layoutCv[k].length - 1 && keyI === orderedKeys.length - 1} isIconOnly size={"sm"} onClick={() => onItemDown(e, k, secI)}>
                                                            <WebIcon name={"arrow_downward"} className={"text-base"} />
                                                        </Button>
                                                    </ButtonGroup>
                                                )}
                                            />
                                        ))}
                                    </Listbox>
                                </CardBody>
                            </Card>

                        </div >
                    ))
                }
            </div>
        </div >
    )
}

export default WebSectionSort