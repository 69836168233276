import { Divider } from '@nextui-org/divider';
import { colors } from 'cvpop-constants-sdk';
import React from 'react';

import { HexColorPicker } from "react-colorful";


const WebColorPickerView = ({ color, onChange }) => (
    <div className="p-4">

        <HexColorPicker color={color} onChange={onChange} />

        <Divider className="my-4" />

        <div className="flex flex-row items-stretch">
            <div className="flex rounded w-full mr-4" style={{ backgroundColor: color }}>

            </div>
            <div className="flex w-full grid grid-cols-4 gap-2">
                {
                    [colors.primary, "#7828C8", "#17C964", "#F31260", "#FF4ECD", "#F5A524", "#7EE7FC", "#71717A"].map(c => (
                        <div
                            key={c}
                            onClick={() => onChange(c)}
                            style={{ backgroundColor: c }}
                            className="rounded-full w-5 h-5"
                        />
                    ))
                }
            </div>
        </div>

    </div>
)

export default WebColorPickerView