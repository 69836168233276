import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'app',
    initialState: {
        offering: null, // deprecated in mobil, TODO web
        offeringProductList: [], // deprecated in mobil, TODO web

        iapOn: true,
        iapProductList: [],

        cvNewFlowEnabled: true,
        cvPreviewEnabled: false,
        reviewModeEnabled: false,

        spin: false
    },
    reducers: {
        setOffering(state, action) {
            state.offering = action.payload
        },
        setOfferingProductList(state, action) {
            state.offeringProductList = action.payload
        },
        setIapOn(state, action) {
            state.iapOn = action.payload
        },
        setIapProductList(state, action) {
            state.iapProductList = action.payload
        },
        setCvNewFlowEnabled(state, action) {
            state.cvNewFlowEnabled = action.payload
        },
        setCvPreviewEnabled(state, action) {
            state.cvPreviewEnabled = action.payload
        },
        setReviewModeEnabled(state, action) {
            state.reviewModeEnabled = action.payload
        },
        setSpin(state, action) {
            state.spin = action.payload
        }
    }
})

export default appSlice