module.exports = {
    styleTokyo: cv => {

        const { modelBaseColor = "#247BA0", modelExAreaColor } = cv.Metadata
        
        return `
            /* ----------------------------------------------- DOCUMENT */
            .left-column{
                background: ${modelBaseColor};
                color: white;
                padding: 40px 20px 10px 20px;
            }
            .right-column {
                padding-top: 40px;
            }
            .right-column > .container {
                border-bottom: 2px solid ${modelBaseColor};
                margin-bottom: 10px;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                margin-bottom: 5px;
            }
            .left-column > * .section-title {
                color: white !important
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                display: block;
                background-color: transparent;
                margin-bottom: 20px;
            }
            .profile-pic {
                border: 3px solid white;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                padding-bottom: 20px;
                background-color: transparent;
            }
            .name {
                text-align: center;
                color: white;
                padding: 0;
            }
            .professional-title{
                text-align: center;
                color: white;
            }
            /* ----------------------------------------------- CONTAC INFO */
            .right-column > * .contact-info-tag-icon {
                color: ${modelBaseColor};
            }
            .contact-info-tag-icon{
                color: white;
            }
            /* ----------------------------------------------- EXPERTISE AREA */
            ${((modelExAreaColor || modelBaseColor) === modelBaseColor) ? `
                .left-column > .container > .content > .expertise-area-text {
                    background: white;
                    color: black
                }
                ` : ""}
        `

    }
}