import React, { useCallback, useMemo, useState } from "react";
import { Accordion, AccordionItem, Input, Button, Select, SelectItem, ButtonGroup } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'

import { produce } from "immer";

import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"
import { illustrations } from "cvpop-assets-sdk";
import { cvPickerItems } from "cvpop-constants-sdk";

import _ from "lodash"

import { confirmDelete, createFbId } from "../../../../controllers/app";
import { useColorScheme } from "../../../../controllers/hooks";

import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const WebAbility = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const [itemSelectedKeys, setItemSelectedKeys] = useState([]);

    // ------------------------------------------------------------------------------------------------------
    const list = useMemo(() => cv.SkillsPassport.LearnerInfo.Ability || [], [cv]);

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])

    const addItem = useCallback(() => {
        const Id = createFbId()
        editCv(produce(cv, d => { d.SkillsPassport.LearnerInfo.Ability.push({ Id, Title: "", Level: 0 }) }))
        setItemSelectedKeys([Id])
    }, [cv])

    const deleteItem = useCallback(Id => {
        editCv(
            produce(cv, d => {
                const newList = d.SkillsPassport.LearnerInfo.Ability.filter(t => t.Id !== Id)
                d.SkillsPassport.LearnerInfo.Ability = newList
            })
        )
    }, [cv])

    const moveItem = useCallback((a, b) => {
        const copy = (cv.SkillsPassport.LearnerInfo.Ability).slice();
        [copy[a], copy[b]] = [copy[b], copy[a]];
        d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Ability = copy }) }))
    }, [cv])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvLabelAbility")} />
            <div className="p-5">

                {
                    list.length === 0 && (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations.ability }}
                                title={t(`cvLabelAbility`)}
                                message={t("emptyListMsg")}
                            >
                                <Button fullWidth color={"primary"} className="mt-6" onClick={addItem}>{t("add")}</Button>
                            </WebCenterView>
                        </div>
                    )
                }



                <Accordion variant="splitted" selectedKeys={itemSelectedKeys} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {
                        list.map(({ Id, Title, Level }, i) => (
                            <AccordionItem
                                isCompact
                                startContent={list.length > 1 && (
                                    <ButtonGroup variant={"light"}>
                                        <Button isDisabled={i === 0} isIconOnly size={"sm"} onClick={() => moveItem(i, i - 1)}>
                                            <WebIcon name={"arrow_upward"} className={"text-base"} />
                                        </Button>
                                        <Button isDisabled={i == list.length - 1} isIconOnly size={"sm"} onClick={() => moveItem(i, i + 1)}>
                                            <WebIcon name={"arrow_downward"} className={"text-base"} />
                                        </Button>
                                    </ButtonGroup>
                                )}
                                onPress={() => setItemSelectedKeys((itemSelectedKeys[0] || "") === Id ? [] : [Id])}
                                title={Title || t("elementWithNoTitle")}
                                key={Id}
                            >
                                <Select
                                    label={t('level')}
                                    defaultSelectedKeys={[String(Level)]}
                                    className="mt-3"
                                    classNames={{ popoverContent: `${cs} text-foreground` }}
                                    onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Ability[${i}].Level`, parseInt(target.value))))}
                                >
                                    {cvPickerItems.ability().map(el => (<SelectItem key={String(el.code)} value={el.code}>{t(el.label)}</SelectItem>))}
                                </Select>

                                <Input
                                    label={t("cvLabelAbility")}
                                    defaultValue={Title}
                                    onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Ability[${i}].Title`, target.value)))}
                                    className="mt-3"
                                />

                                <Button
                                    fullWidth
                                    color={"danger"}
                                    className="my-2"
                                    onPress={() => confirmDelete({ cb: () => deleteItem(Id) })}
                                >
                                    {t("delete")}
                                </Button>

                            </AccordionItem>
                        ))
                    }
                </Accordion>

                {list.length > 0 && (
                    <Button
                        fullWidth
                        color={"primary"}
                        variant={"ghost"}
                        className="mt-4"
                        onClick={addItem}
                    >
                        {t("addNewItem")}
                    </Button>
                )}

                <WebAIAssistantButton sectionId={"ABILITY"} />
            </div>

        </div>
    )
}

export default WebAbility