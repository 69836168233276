import { useMemo, useRef, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { produce } from "immer"
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

import { setCv } from "cvpop-redux-sdk";
import { illustrations } from "cvpop-assets-sdk";
import { userHasCap } from "cvpop-utility-sdk";
import { t } from "cvpop-localization-sdk"

import { confirmDelete, errorHandler, goToPaywall, showAlert } from "../../../../controllers/app";
import { auth, functions, storage } from "../../../../controllers/firebase";
import { useColorScheme } from "../../../../controllers/hooks";

import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const WebVideo = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const inputRef = useRef()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'createqrcode'), []);
    const hasCap = useMemo(() => userHasCap(user, "VIDEO"), [user])
    const video = useMemo(() => cv.SkillsPassport.LearnerInfo.Video, [cv])
    const hasVideo = useMemo(() => (video && video.url !== ""), [video])

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const deleteVideo = useCallback(() => {

        setLoading(true)
        deleteObject(ref(storage, `videoResume/${fbuser.uid}-${cv.Metadata.id}.mp4`))
            .then(() => d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Video = null }) })))
            .catch(e => errorHandler("err_delete_video", e))
            .finally(() => setLoading(false))

    }, [fbuser, cv]);


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>

            <WebHeader title={t("cvLabelVideo")} />
            <div className="p-5">
                <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                    <WebCenterView
                        imageProps={{
                            src: hasVideo && hasCap ? `data:image/png;base64,${video.qrCodeData}` : illustrations.video,
                            className: `${(hasVideo && hasCap) ? "bg-white" : ""} rounded`
                        }}
                        title={t("featVideo")}
                        message={t(`videoPresentation${hasCap ? 'Msg' : 'LongDesc'}`)}
                    >

                        {
                            !hasCap ? (
                                <Button
                                    color="primary"
                                    className="mt-6"
                                    onPress={() => goToPaywall(offering, platform, n)}
                                    endContent={(<WebIcon name={"workspace_premium"} className={`filled text-yellow-400`} />)}
                                >
                                    {t("unlock")}
                                </Button>
                            ) : !hasVideo ? (
                                <Button
                                    color="primary"
                                    className="mt-6"
                                    isLoading={loading}
                                    onPress={() => {
                                        if (!inputRef.current) return
                                        inputRef.current.value = ""
                                        inputRef.current.click()
                                    }}
                                >
                                    {t("add")}
                                </Button>
                            ) : (
                                <Dropdown backdrop="blur" className={`${cs} text-foreground`} isDisabled={loading}>
                                    <DropdownTrigger>
                                        <Button color="primary" className="mt-6" isLoading={loading}>
                                            {t("showOptions")}
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownMenu
                                        aria-label="Static Actions"
                                        color={"primary"}
                                        onAction={k => {
                                            if (k === "delete") return confirmDelete({ cb: deleteVideo })
                                            if (k === "edit") {
                                                if (!inputRef.current) return
                                                inputRef.current.value = ""
                                                inputRef.current.click()
                                            }

                                        }}
                                    >
                                        <DropdownItem key="edit" title={t("edit")} endContent={<WebIcon name={"edit"} />} />
                                        <DropdownItem key="delete" title={t("delete")} className="text-danger" color="danger" endContent={<WebIcon name={"delete"} />} />
                                    </DropdownMenu>
                                </Dropdown>
                            )
                        }

                        <WebAIAssistantButton color={"secondary"} sectionId={"VIDEO"} />
                    </WebCenterView>

                    <input
                        type="file"
                        className='hidden'
                        accept="video/mp4"
                        ref={inputRef}
                        onChange={async ({ target }) => {
                            if (target.files.length === 0) return
                            if (!["video/mp4"].includes(target.files[0].type)) return showAlert(t("errTitle"), t("notSupportedTypeFile"))

                            const video = target.files[0]
                            let stRef = ref(storage, `videoResume/${fbuser.uid}-${cv.Metadata.id}.mp4`);

                            try {
                                setLoading(true)
                                const snap = await uploadBytes(stRef, video)
                                const url = await getDownloadURL(snap.ref)
                                const qrCodeData = await api({ url })
                                d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Video = { url, qrCodeData: qrCodeData.data, createdAt: new Date().getTime() } }) }));
                                setLoading(false)
                            } catch (e) {
                                setLoading(false)
                                deleteObject(stRef)
                                errorHandler("err_get_video", e)
                            }

                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default WebVideo