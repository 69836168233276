
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';
import { getDatabase } from "firebase/database";
import { getStorage } from 'firebase/storage';
import { getRemoteConfig } from "firebase/remote-config";

const getFbConfig = () => {
    let conf = {
        apiKey: "AIzaSyCP_dyTU2eXw5d7M_58StsjXCAEhFUY_sM",
        authDomain: "curriculify.firebaseapp.com",
        databaseURL: "https://curriculify.firebaseio.com",
        projectId: "curriculify",
        storageBucket: "curriculify.appspot.com",
        messagingSenderId: "699830719819",
        appId: "1:699830719819:web:bf258b22cc9f728e9744ce",
        measurementId: "G-XY0L225CVM"
    }

    if (window.webkit && window.webkit.messageHandlers) {
        if (window.webkit.messageHandlers.macos)
            conf = { ...conf, appId: "1:699830719819:web:8cc7bfcbe4f9ffd39744ce", measurementId: "G-LHFB9RSWRN" }
        if (window.webkit.messageHandlers.visionos)
            conf = { ...conf, appId: "1:699830719819:web:f02c78c9734988609744ce", measurementId: "G-WNT822YHLT" }
    }
    return conf

}

// Initialize Firebase
export const app = initializeApp(getFbConfig());

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const rtDb = getDatabase(app);
export const functions = getFunctions(app);
export const storage = getStorage(app)
export const remoteConfig = getRemoteConfig(app);
