import { Spinner } from "@nextui-org/react";

const WebSpinnerView = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className="grid w-screen, h-screen place-content-center">
            <Spinner size="lg" />
        </div>
    )

}

export default WebSpinnerView