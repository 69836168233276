import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { setCv } from "cvpop-redux-sdk"
import { colors, modelCatalogue, basePatternCatalogue } from "cvpop-constants-sdk"
import { t } from "cvpop-localization-sdk"
import { patterns } from "cvpop-assets-sdk"

import _ from "lodash"

import { produce } from "immer"

import WebHeader from "../../../molecules/views/WebHeader";


const WebTexture = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------------------------
    const { modelBaseColor, modelType, modelBasePattern } = cv.Metadata

    // ------------------------------------------------------------------------------------------------------------------------
    const defPattern = useMemo(() => modelCatalogue.find(o => o.code === (modelType || "london")).defaultPattern, [modelType]);

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("texture")} />
            <div className="p-5">
                {
                    basePatternCatalogue.map(el => (
                        <div
                            onClick={() => d(setCv({ cv: produce(cv, d => { d.Metadata.modelBasePattern = el.code }) }))}
                            className="mb-5"
                        >
                            {el.code === "0" ? (
                                <div className={`rounded-xl h-32 p-1 border-4 border-${el.code === (modelBasePattern || defPattern) ? "primary" : "default"} rounded-2xl`} >
                                    <div className={"w-full h-full rounded-lg"} style={{ backgroundColor: modelBaseColor || colors.primary }} />
                                </div>
                            ) : (
                                <div className={`p-1 rounded-2xl border-4 border-${el.code === (modelBasePattern || defPattern) ? "primary" : "default"}`}>
                                    <img
                                        src={patterns[`pattern${el.code}`]}
                                        style={{ backgroundColor: "white" }}
                                        className={`rounded-xl w-full h-32 object-cover`}
                                    />
                                </div>
                            )
                            }
                        </div>
                    ))
                }

            </div>

        </div>
    )
}

export default WebTexture