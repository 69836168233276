import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button } from "@nextui-org/react"

import { t } from "cvpop-localization-sdk"

import moment from "moment"

import { openUrl } from "../../../../controllers/app"

import WebIcon from "../../../atoms/WebIcon"

const WebNewsDetail = ({ }) => {

    // ------------------------------------------------------------------------------------------------------------------------ 
    const { newsList } = useSelector(state => state.newsReducer)
    const { newsId, platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------ 
    const isWeb = useMemo(() => platform === "web", [platform])
    const news = useMemo(() => newsList.find(e => e.id === newsId), [newsId, newsList])

    // ------------------------------------------------------------------------------------------------------------------------ 
    const { creator, postedAt, image, link, blog, title, content } = news || {}
    const { description, name } = blog || {}

    // ------------------------------------------------------------------------------------------------------------------------ 
    return (
        <div className="w-8/12 bg-content1" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>
            <div className="overflow-y-auto" style={{ height: `calc(100% - 104px)` }}>

                <div className="p-8">
                    <p className="text-3xl font-bold">{title}</p>

                    <p className="mt-4 text-foreground-500">{name} - {description} | By {creator} | {moment(postedAt, 'x').fromNow()}</p>

                    {image && (<img alt={title} src={image} className={"mt-4 object-cover h-64 w-full rounded-xl"} />)}

                    <div className="mt-4">
                        {content}
                    </div>

                </div>

            </div>
            <div className="p-8">
                <Button
                    color={"primary"}
                    fullWidth
                    endContent={(<WebIcon name={"open_in_new"} />)}
                    onClick={() => openUrl(link, platform)}
                >
                    {t("open")}
                </Button>
            </div>

        </div>
    )
}

export default WebNewsDetail