import React, { useCallback, useMemo } from 'react';
import { Listbox, ListboxItem, Card, CardBody, Button } from "@nextui-org/react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { produce } from "immer"

import { setUser } from 'cvpop-redux-sdk';
import { t } from "cvpop-localization-sdk";

import WebIcon from '../../atoms/WebIcon';

const WebIntroPersonality = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()
    const { personality } = user.introChoices || {};

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------
    const personalityList = useMemo(() => ["Proactive", "Creative", "Dynamic", "Analytical", "Communicative", "Empathetic", "Efficient", "Organized", "Resilient", "Perceptive", "Minimalist", "Accurate"], [])

    // ------------------------------------------------------------------------------------------------------
    const setPersonality = useCallback(o => {
        d(setUser({ user: produce(user, d => { d.introChoices = { ...d.introChoices, personality: o } }) }))

        if (platform === "macos" || platform === "visionos") return n(`/app/${platform}/onboarding/import-cv`)
        n(`/app/${platform}/onboarding/location`)

    }, [user, d, n])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                <CardBody>
                    {
                        platform !== "web" && (
                            <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                                <WebIcon name={"west"} />
                            </Button>
                        )
                    }
                    <h1 className='text-3xl mb-0 m-3 font-bold'>
                        {t("introPersonality")}
                    </h1>
                    <h1 className='text-xl m-3'>
                        {t("introPersonalityMsg")}
                    </h1>
                    <Listbox
                        color='primary'
                        disallowEmptySelection
                        aria-label={t("introPersonalityMsg")}
                        defaultSelectedKeys={[personality || ""]}
                        selectionMode="single"
                        itemClasses={{ title: "whitespace-normal" }}
                    >
                        {
                            personalityList.map(e => (
                                <ListboxItem
                                    key={e}
                                    title={t(`introPersonality${e}`)}
                                    onPress={() => setPersonality(e)}
                                />
                            ))
                        }
                    </Listbox>
                </CardBody>
            </Card>
        </div>
    )
}

export default WebIntroPersonality