const { languages } = require("cvpop-collections-sdk");

const { sectionTitle } = require("./section-title")

module.exports = {
    language: cv => {

        const { lng } = cv.Metadata
        const { MotherTongue = [] } = cv.SkillsPassport.LearnerInfo.Skills.Linguistic

        if (MotherTongue.length === 0) return ""

        const locale = lng === 'nb' ? 'no' : lng

        return `
        <div class="mothertongue-container container">
            ${sectionTitle(cv, "cvLabelMotherTongue", "MotherTongue", "fas fa-globe-europe")}
            <div class="mothertongue-content content">
            ${MotherTongue.map(({ Description }) => `
                <div class="mothertongue-item ptXS">
                    ${(languages[locale].find(v => v.code === Description.Code) || { label: Description.Code }).label}
                </div>
            `).join("")}
            </div>
        </div>
        `
    }
}
