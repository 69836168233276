const _ = require("lodash")

module.exports = {
    getCommonHeaders: cv => {

        const { modelBaseFont, modelTitleFont, modelNameFont, workTypeFont, companyFont, qualificationFont, instituteFont, skillFont, addInfoFont, lng } = cv.Metadata

        const fixedF = ["zh", "ja"].includes(lng) ? "Noto Sans SC" : null // non trad language
        const baseF = fixedF || modelBaseFont || 'Montserrat',
            titleF = fixedF || modelTitleFont || baseF,
            nameF = fixedF || modelNameFont || baseF,
            workTypeF = fixedF || workTypeFont || baseF || 'Montserrat',
            companyF = fixedF || companyFont || baseF || 'Montserrat',
            qualificationF = fixedF || qualificationFont || baseF || 'Montserrat',
            instituteF = fixedF || instituteFont || baseF || 'Montserrat',
            skillF = fixedF || skillFont || baseF || 'Montserrat',
            addInfoF = fixedF || addInfoFont || baseF || 'Montserrat'

        const fontList = _.sortedUniq([baseF, titleF, nameF, workTypeF, companyF, qualificationF, instituteF, skillF, addInfoF])

        return `
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no, minimal-ui">
        ${fontList.map(font =>
            `<link href="https://fonts.googleapis.com/css2?family=${font.replace(" ", "+")}:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap" rel="stylesheet">`
        ).join("")}

        <link 
            rel="stylesheet" 
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css" 
            integrity="sha512-q3eWabyZPc1XTCmF+8/LuE1ozpg5xxn7iO89yfSOd5/oKvyqLngoNGsx8jq92Y8eXJ/IRxQbEC+FGSYxtk2oiw==" 
            crossorigin="anonymous" 
            referrerpolicy="no-referrer" 
        />

    `
    }
}