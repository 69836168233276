const { sectionTitle } = require("./section-title")

module.exports = {
    expertiseAreas: cv => {

        const { ExpertiseArea = [] } = cv.SkillsPassport.LearnerInfo

        if (ExpertiseArea.length === 0) return ""

        return `
        <div class="expertise-areas-container container">
            ${sectionTitle(cv, "cvLabelKeyword", "ExpertiseArea", "fas fa-tags")}
            <div class="expertise-areas-content content ptXS">
                ${ExpertiseArea.map(({ Title }) => `
                    ${Title ? `<p class="expertise-area-text">${Title}</p>` : ""}
                `).join("")}
            </div>
        </div>
        `

    }
}