const imagesAssets = require("./src/images")
const lottieAssets = require("./src/lotties")

module.exports = {
    icons: imagesAssets.icons,
    pictures: imagesAssets.pictures,
    models: imagesAssets.models,
    flags: imagesAssets.flags,
    patterns: imagesAssets.patterns,
    bgImages: imagesAssets.bgImages,
    logos: imagesAssets.logos,
    illustrations: imagesAssets.illustrations,
    
    lotties: lottieAssets.lotties,
}
