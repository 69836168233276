const _ = require("lodash")

module.exports = {
    signature: cv => {

        const { signatureDim = "STANDARD" } = cv.Metadata
        const { Signature = {} } = cv.SkillsPassport.LearnerInfo.Identification

        if (_.isEmpty(Signature) || !Signature?.Data) return ""
        return `
        <div class="signature-pic-container">
            <div class="signature-pic signature-${signatureDim.toLowerCase()}" style="background-image: url(${Signature.Data})"></div>
        </div>
        `

    }
}

