import { createSlice } from '@reduxjs/toolkit'

const lng = (window.navigator?.language || "en").toLocaleLowerCase()

const webSlice = createSlice({
    name: 'web',
    initialState: {
        language: lng.slice(0, 2),
        languageTag: lng.replace("-", "_"),
    },
    reducers: {
    }
})

export default webSlice