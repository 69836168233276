module.exports = {
    styleNewYork: cv => {

        const { modelBaseColor = "#247BA0", lng } = cv.Metadata
        
        return `
            /* ----------------------------------------------- DOCUMENT */
            .header{
                background-color: ${modelBaseColor};
                padding: 40px;
            }
            .body{
                padding: 15px 40px 0px 40px;
            }
            .container{
                display: table;
                padding-top: 5px;
            }
            .content{
                min-width: 490px;
                max-width: 490px;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                display: table-cell;
                width: 170px;
                text-align: right;
                padding-top: 5px;
                padding-right: 20px;
                vertical-align: top;
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                ${lng === "ar" ? "padding-right: 20px" : "padding-left: 20px"}; 
                background-color: transparent;
            }
            .profile-pic{
                border-color: white;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                background-color: transparent;
            }
            .name, .professional-title{
                color: #FFF;
                text-shadow: 0px 0px 5px black;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);	
            }
            /* ----------------------------------------------- CONTAC INFO */
            .contact-info-tag-icon{
                color: black
            }
            /* ----------------------------------------------- PORTFOLIO */
            .portfolio-item {
                height: 160px;
                width: 160px;
                margin-right: 0;
            }
            /* ----------------------------------------------- ATTACHMENT */
            .attachment-item {
                height: 115px;
                width: 115px;
            }
        `

    }
}