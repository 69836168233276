const WebListItemHeaderView = ({ style, text, textStyle, ...props }) => {

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className="m-4 mt-6 mb-2">
            <p className="text-xs text-default-600 font-bold uppercase" style={{ ...textStyle }}>
                {text}
            </p>
        </div>
    )

    // return (
    //     <View
    //         accessible
    //         accessibilityRole="header"
    //         {...props}
    //         style={[
    //             margin.T20, margin.B5, padding.L30, padding.R30,
    //             style
    //         ]}
    //     >
    //         <AppText style={[
    //             typography.footnoteEmphasized,
    //             { textTransform: "uppercase", color: colors[cs].gray },
    //             textStyle
    //         ]}>
    //             {text}
    //         </AppText>
    //     </View>
    // )
}

export default WebListItemHeaderView