
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Listbox, ListboxItem, Card, CardBody, Avatar, Button } from "@nextui-org/react"
import { httpsCallable } from "firebase/functions"

import { t } from "cvpop-localization-sdk"
import { pictures } from "cvpop-assets-sdk"

import { functions } from "../../../../controllers/firebase"
import { errorHandler, openUrl } from "../../../../controllers/app"

import WebIcon from "../../../atoms/WebIcon"
import WebSpinnerView from "../../../molecules/views/WebSpinnerView"
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView"
import WebErrorView from "../../../molecules/views/WebErrorView"


const WebCourseDetail = ({ }) => {

    // ------------------------------------------------------------------------------------------------------------------------ 
    const { courseId, platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------ 
    const isWeb = useMemo(() => platform === "web", [platform])

    // ------------------------------------------------------------------------------------------------------
    const apiCourse = useMemo(() => httpsCallable(functions, "getcoursedetail"), [functions]);
    const apiLink = useMemo(() => httpsCallable(functions, 'getcoursedeeplink'), [functions]);

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(true)
    const [linkLoading, setLinkLoading] = useState(false)
    const [error, setError] = useState(false)
    const [course, setCourse] = useState({})

    // ------------------------------------------------------------------------------------------------------
    const { genericPlaceholder } = pictures
    const { title, headline, rating, url, image_480x270, price, num_reviews, num_subscribers, primary_category, locale } = course
    const { what_you_will_learn_data, requirements_data, who_should_attend_data, visible_instructors } = course

    // ------------------------------------------------------------------------------------------------------------------------ 
    const getCourseDetail = useCallback(() => {
        setError(false)
        setLoading(true)
        apiCourse({ id: courseId })
            .then(({ data }) => setCourse(data))
            .catch(e => { errorHandler("err_get_course_detail", e, true); setError(true) })
            .finally(() => setLoading(false))
    }, [courseId])

    const openCourse = useCallback(() => {

        setLinkLoading(true)
        apiLink({ url })
            .then(({ data }) => openUrl(data.deep_link_url, platform))
            .catch(e => {
                errorHandler("err_get_affiliate_link", e, true)
                openUrl(`https://www.udemy.com${url}`, platform)
            })
            .finally(() => setLinkLoading(false))
    }, [url])

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => { getCourseDetail() }, [courseId])

    // ------------------------------------------------------------------------------------------------------------------------ 
    return (
        <div className="w-8/12" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>
            {
                error ? (
                    <div className="h-full flex items-center justify-center" style={{ minHeight: `calc(100vh - 4rem)` }}>
                        <WebErrorView buttonProps={{ onClick: getCourseDetail }} />
                    </div>
                ) : loading ? (
                    <WebSpinnerView />
                ) : (
                    <>
                        <div className="overflow-y-auto" style={{ height: `calc(100% - 128px)` }}>
                            <div className="p-8">
                                <p className="text-3xl font-bold">{title}</p>

                                <img alt={title} src={image_480x270 || genericPlaceholder} className={"mt-4 object-cover h-64 w-full rounded-xl"} />

                                <WebListItemHeaderView text={t('courseOverview')} />
                                <Card shadow={"none"}>
                                    <CardBody>
                                        <p className="text-lg font-semibold">{headline}</p>
                                        <div className="flex mt-4">
                                            <p>{rating.toFixed(1)}</p>
                                            <div className="ml-2">
                                                {[1, 2, 3, 4, 5].map(star => (<WebIcon name={"star"} className={`filled text-${parseInt(rating.toFixed(1)) >= star ? "warning" : "default"}`} />))}
                                            </div>
                                        </div>

                                        <div className="flex mt-4">
                                            <div className="flex">
                                                <WebIcon name={"star"} />
                                                <p className="ml-2">{num_reviews} {t(num_reviews === 1 ? 'rate' : 'rates')}</p>
                                            </div>
                                            <div className="flex ml-4">
                                                <WebIcon name={"group"} />
                                                <p className="ml-2">{num_subscribers} {t(num_subscribers === 1 ? 'student' : 'students')}</p>
                                            </div>
                                        </div>
                                        <div className="flex mt-2">
                                            <div className="flex">
                                                <WebIcon name={"sell"} />
                                                <p className="ml-2">{primary_category.title}</p>
                                            </div>
                                            <div className="flex ml-4">
                                                <WebIcon name={"translate"} />
                                                <p className="ml-2">{locale.title}</p>
                                            </div>
                                        </div>

                                        <div className="flex mt-4">
                                            <p className="text-lg font-semibold">{price}</p>
                                        </div>


                                    </CardBody>
                                </Card>

                                {what_you_will_learn_data.items.length > 0 && (<WebListItemHeaderView text={t('whatYouWillLearn')} />)}
                                {what_you_will_learn_data.items.length > 0 && (
                                    <Card shadow={"none"}>
                                        <CardBody>
                                            <Listbox aria-label="Actions" disabledKeys={what_you_will_learn_data.items.map((e, i) => `learn-${i}`)}>
                                                {
                                                    what_you_will_learn_data.items.map((el, i) => (
                                                        <ListboxItem
                                                            startContent={<WebIcon name="check" />}
                                                            key={`learn-${i}`}
                                                            className={`opacity-100`}
                                                            classNames={{ title: "whitespace-normal" }}
                                                            title={el}
                                                        />
                                                    ))
                                                }
                                            </Listbox>
                                        </CardBody>
                                    </Card>
                                )}

                                {requirements_data.items.length > 0 && (<WebListItemHeaderView text={t('requirements')} />)}
                                {requirements_data.items.length > 0 && (
                                    <Card shadow={"none"}>
                                        <CardBody>
                                            <Listbox aria-label="Actions" disabledKeys={requirements_data.items.map((e, i) => `req-${i}`)}>
                                                {
                                                    requirements_data.items.map((el, i) => (
                                                        <ListboxItem
                                                            startContent={<WebIcon name="check_circle" />}
                                                            key={`req-${i}`}
                                                            className={`opacity-100`}
                                                            classNames={{ title: "whitespace-normal" }}
                                                            title={el}
                                                        />
                                                    ))
                                                }
                                            </Listbox>
                                        </CardBody>
                                    </Card>
                                )}

                                {who_should_attend_data.items.length > 0 && (<WebListItemHeaderView text={t('whoShouldAttend')} />)}
                                {who_should_attend_data.items.length > 0 && (
                                    <Card shadow={"none"}>
                                        <CardBody>
                                            <Listbox aria-label="Actions" disabledKeys={who_should_attend_data.items.map((e, i) => `who-${i}`)}>
                                                {
                                                    who_should_attend_data.items.map((el, i) => (
                                                        <ListboxItem
                                                            startContent={<WebIcon name="person_check" />}
                                                            key={`who-${i}`}
                                                            className={`opacity-100`}
                                                            classNames={{ title: "whitespace-normal" }}
                                                            title={el}
                                                        />
                                                    ))
                                                }
                                            </Listbox>
                                        </CardBody>
                                    </Card>
                                )}

                                {visible_instructors.length > 0 && (<WebListItemHeaderView text={t('teachers')} />)}
                                {visible_instructors.length > 0 && (
                                    <Card shadow={"none"}>
                                        <CardBody>
                                            <Listbox aria-label="Actions" disabledKeys={visible_instructors.map((e, i) => `instructor-${i}`)}>
                                                {
                                                    visible_instructors.map((el, i) => (
                                                        <ListboxItem
                                                            startContent={<Avatar src={el.image_100x100} name={el.title} />}
                                                            key={`instructor-${i}`}
                                                            title={el.title}
                                                            description={el.job_title}
                                                            className={`opacity-100`}
                                                        />
                                                    ))
                                                }
                                            </Listbox>
                                        </CardBody>
                                    </Card>
                                )}
                            </div>
                        </div>
                        <div className="p-8">
                            <Button
                                color={"primary"}
                                fullWidth
                                isLoading={linkLoading}
                                endContent={(<WebIcon name={"open_in_new"} />)}
                                onClick={() => openCourse()}
                            >
                                {t("open")}
                            </Button>
                            <p className="text-center text-xs mt-2">{t("udemyDisclaimer")}</p>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default WebCourseDetail