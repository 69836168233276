import { createSlice } from '@reduxjs/toolkit'

const newsSlice = createSlice({
    name: 'news',
    initialState: {
        newsList: []
    },
    reducers: {
        setNewsList(state, action) {
            state.newsList = action.payload
        },
    }
})

export default newsSlice