import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { produce } from "immer"
import { useNavigate, useParams } from 'react-router-dom';
import { Listbox, ListboxItem, Card, CardBody, Button } from "@nextui-org/react";

import { setUser } from 'cvpop-redux-sdk';
import { t } from "cvpop-localization-sdk";

import WebIcon from '../../atoms/WebIcon';

const WebIntroGoal = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()
    const { goal } = user.introChoices || {};

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------
    const goalList = useMemo(() => ["JobApplication", "Networking", "PromotionOrTransfer", "Freelancing", "PersonalBranding", "CareerTransition", "Other"], [])

    // ------------------------------------------------------------------------------------------------------
    const setGoal = useCallback(o => {
        d(setUser({ user: produce(user, d => { d.introChoices = { ...d.introChoices, goal: o } }) }))
        n(`/app/${platform}/onboarding/timing`)
    }, [user, d, n])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                <CardBody>
                    {
                        platform !== "web" && (
                            <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                                <WebIcon name={"west"} />
                            </Button>
                        )
                    }
                    <h1 className='text-3xl m-3 font-bold'>
                        {t("whatsYourGoal")}
                    </h1>
                    <Listbox
                        color='primary'
                        disallowEmptySelection
                        aria-label={t("whatsYourGoal")}
                        defaultSelectedKeys={[goal || ""]}
                        selectionMode="single"
                        itemClasses={{ title: "whitespace-normal" }}
                    >
                        {
                            goalList.map(e => (
                                <ListboxItem
                                    key={e}
                                    title={t(`introGoal${e}`)}
                                    description={t(`introGoal${e}Msg`)}
                                    onPress={() => setGoal(e)}
                                />
                            ))
                        }
                    </Listbox>
                </CardBody>
            </Card>
        </div>
    )
}

export default WebIntroGoal