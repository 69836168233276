module.exports = {
    contactInfoTag: (text = "", icon = null) => `
    <div class="contact-info-tag-container ptXS">
        
        <div class="contact-info-tag-icon">
            ${icon ? `<i class="${icon}"></i>` : ""}
        </div>

        <p class="contact-info-tag-text">${text}</p>
    </div>
`
}
