module.exports = {
    styleIstanbul: cv => {

        const { modelBaseColor = "#247BA0", modelTitleColor, modelBaseFontSize = 11, lng } = cv.Metadata

        return `
            /* ----------------------------------------------- DOCUMENT */
            .header {
                display: table;
                padding: ${lng === "ar" ? "40px 20px 20px 0px" : "40px 0px 20px 40px"};
            }
            .container {
                border-bottom: 2px solid #e9e9e9;
                margin-bottom: 10px;
            }
            .body {
                display: block !important;
            }
            .half-column{
                padding-top: 0px;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                margin-bottom: 5px;
            }
            .section-title-container > * i {
                font-size: 15px;
                background-color: ${modelTitleColor || modelBaseColor};
                border-radius: 5px;
                color: white;
                padding: 5px;
            }
            .section-title-content{
                vertical-align: middle;
                display: table-cell;
            }
            .section-title-icon{
                display: table-cell;
                vertical-align: middle;
                width: 20px;
                text-align: center;
            }
            .section-title{
                vertical-align: middle;
                font-size: ${parseInt(modelBaseFontSize) + 4}px;
                padding-left: 10px;
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                ${lng === "ar" ? "padding-left: 20px" : "padding-right: 20px"}; 
            }
            /* ----------------------------------------------- NAME */
            .name-with-pro-title{
                margin: 0px;
                padding-bottom: 1px;
            }
            .name, .professional-title {
                color: white;
                background-color: ${modelBaseColor};
                padding-right: 40px;
                padding-left: 20px;
            }
            .professional-title {
                margin-top: -1px;
                padding-bottom: 2px;
            }
            /* ----------------------------------------------- SECTION PERIOD */
            .section-period-container{
                text-align: right;
            }
            /* ----------------------------------------------- PORTFOLIO */
            .unique-column > * .portfolio-item{
                height: 105px !important;
                width: 105px !important;
            }
            /* ----------------------------------------------- ATTACHMENT */
            .unique-column > * .attachment-item{
                height: 165px !important;
                width: 165px !important;
            }
        `

    }
}