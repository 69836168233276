import { useState, useMemo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, DropdownTrigger, DropdownMenu, Spinner, DropdownItem } from "@nextui-org/react";
import { httpsCallable } from 'firebase/functions';
import { doc, collection, setDoc } from "firebase/firestore";

import { duplicateCvObject, getCvReadyToTranslate, isProUser } from "cvpop-utility-sdk"
import { t } from "cvpop-localization-sdk"
import { cvPickerItems } from "cvpop-constants-sdk";
import { languages } from 'cvpop-collections-sdk';
import { setCv } from "cvpop-redux-sdk";

import _ from "lodash"

import { db, functions } from "../../../controllers/firebase";
import { errorHandler, goToPaywall, showAlert } from "../../../controllers/app";
import { useColorScheme } from "../../../controllers/hooks";


const WebTranslateCVButton = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv, cvList } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'gettranslatedcv'), []);
    const isPro = useMemo(() => isProUser(user), [user])
    const languageList = useMemo(() => cvPickerItems.labelLanguage().filter(e => e.code !== cv.Metadata.lng), [cv])
    const localizedLanguageList = useMemo(() => _.orderBy((languages[window.language] || languages["en"]), [e => e.label.toLowerCase()], ['asc']), [window.language])


    // ------------------------------------------------------------------------------------------------------------------------
    const performTranslate = useCallback(async language => {

        if (cvList.length >= 10) return showAlert(t("cvNumLimit"), t("cvNumLimitMsg"));

        const targetLng = language.code === 'no' ? 'nb' : language.code // update metadata:: (norvergese is not supported by gtranslate - convert to "nb")

        try {
            setLoading(true)

            const cleanedCv = getCvReadyToTranslate(cv)

            const response = await api({ cleanedCv, targetLng });

            const ref = doc(collection(db, "00_RESUMES_00"));
            const newCv = duplicateCvObject(_.merge(_.cloneDeep(cv), { Metadata: { lng: targetLng }, ...response.data }), ref.id, language.label)
            await setDoc(doc(db, "00_RESUMES_00", ref.id), newCv);

            d(setCv({ cv: newCv, ignoreListener: true }));
            showAlert(t("cvTranslated"), t("cvTranslatedMsg", { l: (localizedLanguageList.find(i => i.code === language.code) || { label: "" }).label }))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            errorHandler("err_translate_cv", e)
        };


    }, [cv, cvList])

    // ------------------------------------------------------------------------------------------------------------------------
    const renderCoreButton = useCallback(() => (
        <div className="flex flex-col items-center" onClick={() => !isPro && goToPaywall(offering, platform, n)}>
            <span className=" material-symbols-outlined">translate</span>
            <span className="text-xs">{t("translate")}</span>
            {!isPro && (<span className="text-yellow-500" style={{ fontSize: "0.6rem" }}>({t("premium")})</span>)}
        </div>
    ), [isPro, loading, offering])


    // ------------------------------------------------------------------------------------------------------------------------ 
    if (loading) return (
        <div className="flex flex-col items-center justify-center min-h-10">
            <Spinner size={"sm"} />
        </div>
    )
    return !isPro ? renderCoreButton() : (
        <Dropdown
            placement={"right"}
            backdrop={"blur"}
            shouldBlockScroll={false}
            className={`${cs} text-foreground`}
            classNames={{ base: "max-h-72 overflow-y-scroll" }}
        >
            <DropdownTrigger>
                {renderCoreButton()}
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions" color={"primary"}>
                {
                    languageList.map(({ code, label }, i) => (
                        <DropdownItem onClick={() => performTranslate({ code, label })} key={code} title={label} />
                    ))
                }
            </DropdownMenu>
        </Dropdown >
    )
}

export default WebTranslateCVButton