const i18next = require('i18next')

const it = require("./translations/it.json") //italiano
const en = require("./translations/en.json") //inglese
const bg = require("./translations/bg.json") //bulgaro
const cs = require("./translations/cs.json") //ceco
const da = require("./translations/da.json") //danese
const de = require("./translations/de.json") //tedesco
const el = require("./translations/el.json") //greco
const es = require("./translations/es.json") //spagnolo
const et = require("./translations/et.json") //estone
const fi = require("./translations/fi.json") //finlandese
const fr = require("./translations/fr.json") //francese
const hr = require("./translations/hr.json") //croato
const hu = require("./translations/hu.json") //ungherese
const is = require("./translations/is.json") //islandese
const lt = require("./translations/lt.json") //lituano
const lv = require("./translations/lv.json") //lettone
const mk = require("./translations/mk.json") //macedone
const mt = require("./translations/mt.json") //maltese
const nl = require("./translations/nl.json") //olandese
const nb = require("./translations/nb.json") //norvergese
const pl = require("./translations/pl.json") //polacco
const pt = require("./translations/pt.json") //portoghese
const ro = require("./translations/ro.json") //rumeno
const sl = require("./translations/sl.json") //sloveno
const sk = require("./translations/sk.json") //slovacco
const sv = require("./translations/sv.json") //svedese
const tr = require("./translations/tr.json") //turco
const zh = require("./translations/zh.json") //cinese
const ja = require("./translations/ja.json") //giapponese
const ar = require("./translations/ar.json") //giapponese

i18next.init({
    compatibilityJSON: 'v3',
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
        prefix: "%{",
        suffix: "}"
    },
    // debug: true,
    resources: {
        en: { translation: en },
        it: { translation: it },
        bg: { translation: bg },
        cs: { translation: cs },
        da: { translation: da },
        de: { translation: de },
        el: { translation: el },
        es: { translation: es },
        et: { translation: et },
        fi: { translation: fi },
        fr: { translation: fr },
        hr: { translation: hr },
        hu: { translation: hu },
        is: { translation: is },
        lt: { translation: lt },
        lv: { translation: lv },
        mk: { translation: mk },
        mt: { translation: mt },
        nl: { translation: nl },
        nb: { translation: nb },
        pl: { translation: pl },
        pt: { translation: pt },
        ro: { translation: ro },
        sl: { translation: sl },
        sk: { translation: sk },
        sv: { translation: sv },
        tr: { translation: tr },
        zh: { translation: zh },
        ja: { translation: ja },
        ar: { translation: ar },
    }
});

module.exports = {
    i18n: i18next,
    t: (k = "", opt = {}) => {
        let remappedOpt = { ...opt }
        if (opt.locale) remappedOpt.lng = opt.locale
        return i18next.t(k, remappedOpt)
    }
}