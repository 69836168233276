import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, Input } from '@nextui-org/react';
import { produce } from "immer"

import { t } from "cvpop-localization-sdk";
import { setCv } from "cvpop-redux-sdk";
import { sectionGroupedCatalogue } from "cvpop-constants-sdk"

import _ from "lodash";

import WebHeader from '../../../molecules/views/WebHeader';
import WebListItemHeaderView from '../../../molecules/views/WebListItemHeaderView';


const WebCustomizeTitle = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------
    const editTitle = useCallback(cv => { d(setCv({ cv })) }, [cv])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t('customizeLabels')} />
            <div className="mx-5 mb-5">
                {
                     sectionGroupedCatalogue.map(s => (
                        <div key={`section${s.key}`}>
                            <WebListItemHeaderView text={t(s.key)} />
                            <Card>
                                <CardBody>
                                    {s.items.map((e, i) => (
                                        <Input
                                            placeholder={t(`cvLabel${e.defaultLabelKey}`)}
                                            defaultValue={cv.Metadata[`customTitle${e.metadataKey}`]}
                                            onChange={({ target }) => editTitle(produce(cv, d => void _.set(d, `Metadata.customTitle${e.metadataKey}`, target.value)))}
                                            className={i > 0 && "mt-4"}
                                        />
                                    ))}
                                </CardBody>
                            </Card>
                        </div>
                    ))
                }
                <p className="text-xs text-default-600 mt-2">{t('customTitleHelperText')}</p>
            </div>

        </div>
    )
}

export default WebCustomizeTitle