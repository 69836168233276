import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Button } from "@nextui-org/react"
import { useNavigate, useParams } from 'react-router-dom';
// import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { httpsCallable } from 'firebase/functions';
import { produce } from "immer"

import { setUser } from 'cvpop-redux-sdk'
import { illustrations } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk";

import _ from "lodash"

import { functions } from '../../../controllers/firebase';
import { errorHandler } from '../../../controllers/app';

import WebIcon from '../../atoms/WebIcon';


const WebIntroLocation = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { language } = useSelector(state => state.webReducer)
    const { platform } = useParams();

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState("")

    // ------------------------------------------------------------------------------------------------------
    const { geolocation = null } = window.navigator

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'getlocationfromcoords'), []);

    // ------------------------------------------------------------------------------------------------------
    const navToNext = useCallback(e => {
        if (e) errorHandler("err_get_user_location", e, true)
        n(`/app/${platform}/onboarding/import-cv`)
        setLoading(false)
    }, [n])

    const convertCoordsToLocation = useCallback(coords => {

        api({ coords, language }).then(({ data }) => {
            if (data.results.length === 0) return
            const city = (data.results[0].address_components.find(i => i.types.includes("locality")) || { long_name: "" }).long_name
            const province = (data.results[0].address_components.find(i => i.types.includes("administrative_area_level_2")) || { short_name: "" }).short_name
            const country = (data.results[0].address_components.find(i => i.types.includes("country")) || { long_name: "" }).long_name
            const location = _.compact([city, province, country]).join(", ")
            d(setUser({ user: produce(user, d => { d.location = location }) }))
        }).catch(e => navToNext(e)).finally(navToNext)

    }, [user, language, d, api, navToNext])

    const requestUserLocation = useCallback(() => {

        if (!geolocation) return navToNext()
        setLoading(true)
        geolocation.getCurrentPosition(({ coords }) => convertCoordsToLocation({ latitude: coords.latitude, longitude: coords.longitude }), e => navToNext(e))

    }, [geolocation, convertCoordsToLocation, navToNext])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                {
                    platform !== "web" && (
                        <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }
                <CardBody>
                    <div className="max-w-80 self-center">
                        <img alt={t("introLocationTitle")} src={illustrations.location} />
                    </div>
                    <p className="font-sans font-bold text-2xl text-center mt-3">{t("introLocationTitle")}</p>
                    <p className="font-sans text-center mt-3">{t("introLocationMsg")}</p>

                    <Button
                        color="primary"
                        className='mt-3'
                        isLoading={loading}
                        // onClick={() => requestUserLocation()}
                        onClick={() => navToNext()}
                    >
                        {t("continue")}
                    </Button>

                    {/* <Button className='mt-3' color='primary' variant={"light"} onClick={onOpen}>
                        {t("addManually")}
                    </Button> */}

                </CardBody>
            </Card>

            {/* <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t("cutImage")}</ModalHeader>

                            <ModalBody>
                                TODO
                            </ModalBody>

                            <ModalFooter>
                                <Button
                                    color="danger"
                                    variant="light"
                                    onPress={() => {
                                        // if (inputRef.current) inputRef.current.value = ""
                                        onClose()
                                    }}
                                >
                                    {t("cancel")}
                                </Button>
                                <Button
                                    color="primary"
                                    onPress={() => {
                                        onClose()
                                    }}
                                >
                                    {t("done")}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal> */}

        </div>
    )
}

export default WebIntroLocation