const { sectionTitle } = require("./section-title")

module.exports = {
    abilites: cv => {

        const { Ability = [] } = cv.SkillsPassport.LearnerInfo
        const { modelBaseColor, modelAbilityColor, modelAbilityFormat = "abilityFormatSingleBar" } = cv.Metadata
       
        if (Ability.length === 0) return ""

        const baseColor = modelBaseColor || '#247BA0';
        const abilityColor = modelAbilityColor || baseColor

        return `
            <div class="abilities-container container">
                ${sectionTitle(cv, "cvLabelAbility", "Ability", "fas fa-chart-bar")}

                ${modelAbilityFormat === "abilityFormatSingleBar" ? `
                        <div class="abilities-content content">
                            ${Ability.map(({ Title, Level }) => `
                                <div class="ability-item ptXS">
                                    <div class="ability-title">
                                        ${Title}&nbsp;
                                    </div>
                                    <div class="ability-level-container ability-level-single-bar">
                                        <div class="ability-level-single-bar-background">
                                            <div class="ability-level-single-bar-content ability-level-${Level}"></div>
                                        </div>
                                    </div>
                                </div>
                            `).join("")}
                        </div>
                    ` : modelAbilityFormat === "abilityFormatBars" ? `
                        <div class="abilities-content content">
                            ${Ability.map(({ Title, Level }) => `
                                <div class="ability-item ptXS">
                                    <div class="ability-title">
                                        ${Title}&nbsp;
                                    </div>
                                    <div class="ability-level-container ability-level-bars">
                                        <div class="ability-level-bars-background">
                                            <div class="ability-level-bars-content ability-level-${parseInt(Level) < 1 ? "0" : "5"}"></div>
                                        </div>
                                        <div class="ability-level-bars-background">
                                            <div class="ability-level-bars-content ability-level-${parseInt(Level) < 2 ? "0" : "5"}"></div>
                                        </div>
                                        <div class="ability-level-bars-background">
                                            <div class="ability-level-bars-content ability-level-${parseInt(Level) < 3 ? "0" : "5"}"></div>
                                        </div>
                                        <div class="ability-level-bars-background">
                                            <div class="ability-level-bars-content ability-level-${parseInt(Level) < 4 ? "0" : "5"}"></div>
                                        </div>
                                        <div class="ability-level-bars-background">
                                            <div class="ability-level-bars-content ability-level-${parseInt(Level) < 5 ? "0" : "5"}"></div>
                                        </div>
                                    </div>
                                </div>
                            `).join("")}
                        </div>
                    ` : modelAbilityFormat === "abilityFormatCircular" ? `  
                        <div class="ability-circle-container-all">
                            ${Ability.map(({ Title, Level }) => `
                                <div class="ability-circle-container">
                                    <svg width="50" height="50" viewBox="0 0 50 50">
                                        <circle class="bg" cx="25" cy="25" r="22.5" fill="none" stroke="#ddd" stroke-width="5"></circle>
                                        <circle class="fg" cx="25" cy="25" r="22.5" fill="none" stroke="${abilityColor}" stroke-width="5" stroke-dasharray="${28.26 * Level} ${28.26 * (5 - Level)}"></circle>
                                    </svg>
                                    <div class="ability-circle-title">
                                        ${Title}
                                    </div>
                                </div>
                            `).join("")}
                        </div>  
                    ` : modelAbilityFormat === "abilityFormatDots" ? `
                        <div class="abilities-content content">
                            ${Ability.map(({ Title, Level }) => `
                                <div class="ability-item ptXS">
                                    <div class="ability-title">
                                        ${Title}&nbsp;
                                    </div>
                                    <div class="ability-level-container">
                                        <i class="fa${parseInt(Level) < 1 ? "r" : "s"} fa-circle fa-border"></i>
                                        <i class="fa${parseInt(Level) < 2 ? "r" : "s"} fa-circle fa-border"></i>
                                        <i class="fa${parseInt(Level) < 3 ? "r" : "s"} fa-circle fa-border"></i>
                                        <i class="fa${parseInt(Level) < 4 ? "r" : "s"} fa-circle fa-border"></i>
                                        <i class="fa${parseInt(Level) < 5 ? "r" : "s"} fa-circle fa-border"></i>
                                    </div>
                                </div>
                            `).join("")}
                        </div>
                    ` : modelAbilityFormat === "abilityFormatStars" ? `
                        <div class="abilities-content content">
                            ${Ability.map(({ Title, Level }) => `
                                <div class="ability-item ptXS">
                                    <div class="ability-title">
                                        ${Title}&nbsp;
                                    </div>
                                    <div class="ability-level-container">
                                        <i class="fa${parseInt(Level) < 1 ? "r" : "s"} fa-star fa-border"></i>
                                        <i class="fa${parseInt(Level) < 2 ? "r" : "s"} fa-star fa-border"></i>
                                        <i class="fa${parseInt(Level) < 3 ? "r" : "s"} fa-star fa-border"></i>
                                        <i class="fa${parseInt(Level) < 4 ? "r" : "s"} fa-star fa-border"></i>
                                        <i class="fa${parseInt(Level) < 5 ? "r" : "s"} fa-star fa-border"></i>
                                    </div>
                                </div>
                            `).join("")}
                        </div>
                    ` : modelAbilityFormat === "abilityFormatSquares" ? `
                        <div class="abilities-content content">
                            ${Ability.map(({ Title, Level }) => `
                                <div class="ability-item ptXS">
                                    <div class="ability-title">
                                        ${Title}&nbsp;
                                    </div>
                                    <div class="ability-level-container">
                                        <i class="fa${parseInt(Level) < 1 ? "r" : "s"} fa-square fa-border"></i>
                                        <i class="fa${parseInt(Level) < 2 ? "r" : "s"} fa-square fa-border"></i>
                                        <i class="fa${parseInt(Level) < 3 ? "r" : "s"} fa-square fa-border"></i>
                                        <i class="fa${parseInt(Level) < 4 ? "r" : "s"} fa-square fa-border"></i>
                                        <i class="fa${parseInt(Level) < 5 ? "r" : "s"} fa-square fa-border"></i>
                                    </div>
                                </div>
                            `).join("")}
                        </div>
                    ` : modelAbilityFormat === "abilityFormatChecks" ? `
                        <div class="abilities-content content">
                            ${Ability.map(({ Title, Level }) => `
                                <div class="ability-item ptXS">
                                    <div class="ability-title">
                                        ${Title}&nbsp;
                                    </div>
                                    <div class="ability-level-container">
                                        ${parseInt(Level) < 1 ? `<i class="far fa-square fa-border"></i>` : `<i class="fas fa-check-square fa-border"></i>`}
                                        ${parseInt(Level) < 2 ? `<i class="far fa-square fa-border"></i>` : `<i class="fas fa-check-square fa-border"></i>`}
                                        ${parseInt(Level) < 3 ? `<i class="far fa-square fa-border"></i>` : `<i class="fas fa-check-square fa-border"></i>`}
                                        ${parseInt(Level) < 4 ? `<i class="far fa-square fa-border"></i>` : `<i class="fas fa-check-square fa-border"></i>`}
                                        ${parseInt(Level) < 5 ? `<i class="far fa-square fa-border"></i>` : `<i class="fas fa-check-square fa-border"></i>`}
                                    </div>
                                </div>
                            `).join("")}
                        </div>
                    ` : ""
                    }
            </div>
            `
    }
}