module.exports = {
    styleLisbon: cv => {

        const { modelBaseColor = "#247BA0", modelTitleColor, modelExAreaColor, lng } = cv.Metadata

        return `
            /* ----------------------------------------------- DOCUMENT */
            .header {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                background-color: ${modelBaseColor};
                padding: 40px 20px 40px 20px;
                display: flex;
                position: relative;
            }
            .header::before {
                content: "";
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                background-color: rgba(0,0,0,0.3);
                z-index: 1;
            }
            .left-column{
                background: ${modelBaseColor};
                color: white;
                padding: 20px 20px 10px 20px;
            }
            .right-column > .container {
                border-bottom: 2px solid #e9e9e9;
                margin-bottom: 10px;
            }
            .left-column > .container, .right-column > .container {
                position: relative;
            }
            /* ----------------------------------------------- TITLES */
            .left-column > * .section-title {
                color: white !important
            }
            .section-title-container{
                margin-bottom: 5px;
            }

            .left-column > * .section-title-container > * i {
                background-color: white !important;
                color: ${modelTitleColor || modelBaseColor} !important;
            }
            .section-title-container > * i {
                background-color: ${modelTitleColor || modelBaseColor};
                border-radius: 10px 0px 10px 0px;
                color: white;
                padding: 8px;
            }
            .section-title-content{
                vertical-align: middle;
                display: table-cell;
            }
            .section-title-icon{
                display: table-cell;
                vertical-align: middle;
                width: 20px;
                text-align: center;
            }
            .section-title{
                vertical-align: middle;
                ${lng === "ar" ? "padding-right: 10px" : "padding-left: 10px"}; 
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                ${lng === "ar" ? "padding-right: 20px" : "padding-left: 20px"}; 
                display: block;
                background-color: transparent;
                z-index: 2;
            }
            .profile-pic {
                border: 3px solid white;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                background-color: transparent;
                align-self: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                z-index: 2;
            }
            .name, .professional-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: white;
                font-weight: bold;
                background-color: transparent;
                text-shadow: 0px 0px 5px black;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);
            }
            /* ----------------------------------------------- CONTAC INFO */
            .right-column > * .contact-info-tag-icon {
                color: ${modelBaseColor};
            }
            .contact-info-tag-icon{
                color: white;
            }
            /* ----------------------------------------------- EXPERTISE AREA */
            ${((modelExAreaColor || modelBaseColor) === modelBaseColor) ? `
                .left-column > .container > .content > .expertise-area-text {
                    background: white;
                    color: black
                }
                ` : ""}
            `

    }
}