const { modelCatalogue } = require("cvpop-constants-sdk");

const { image0 } = require("./image0");
const { image1 } = require("./image1");
const { image2 } = require("./image2");
const { image3 } = require("./image3");
const { image4 } = require("./image4");
const { image5 } = require("./image5");
const { image6 } = require("./image6");
const { image7 } = require("./image7");
const { image8 } = require("./image8");
const { image9 } = require("./image9");
const { image10 } = require("./image10");
const { image11 } = require("./image11");
const { image12 } = require("./image12");
const { image13 } = require("./image13");
const { image14 } = require("./image14");
const { image15 } = require("./image15");
const { imageCustom } = require("./imageCustom");

module.exports = {
    getBaseImageCss: cv => {
        const baseImage = cv.Metadata.modelBaseImage || modelCatalogue.find(o => o.code === (cv.Metadata.modelType || "london")).defaultImage;
        switch (String(baseImage)) {
            case "0": return image0
            case "1": return image1
            case "2": return image2
            case "3": return image3
            case "4": return image4
            case "5": return image5
            case "6": return image6
            case "7": return image7
            case "8": return image8
            case "9": return image9
            case "10": return image10
            case "11": return image11
            case "12": return image12
            case "13": return image13
            case "14": return image14
            case "15": return image15
            case "CUSTOM": return imageCustom
            default: return ""
        }
    }
}