const _ = require("lodash")

const { userHasCap } = require("./user");

const { getCommonCss } = require("./common/common/common-css");
const { getCommonHeaders } = require("./common/common/common-headers");

const { getBasePatternCss } = require("./common/base-patterns/basepatterns");
const { getBaseImageCss } = require("./common/base-images/baseImage");

const { getTemplateCss } = require("./common/template-styles/template-style");
const { getTemplateBody } = require("./common/template-body/template-body");
const { footer } = require("./common/partials/footer");

module.exports = {
    renderCvPreview: (cvOrig = {}, user = {}, addStyle = "", publicMode = false) => {

        const cv = _.cloneDeep(cvOrig)

        // if the user is not PRO, remove items
        if (!userHasCap(user, 'SIGNATURE')) delete cv.SkillsPassport.LearnerInfo.Identification.Signature
        if (!userHasCap(user, 'VIDEO')) delete cv.SkillsPassport.LearnerInfo.Video
        if (!userHasCap(user, 'PORTFOLIO')) delete cv.SkillsPassport.LearnerInfo.Portfolio
        if (!userHasCap(user, 'ATTACHMENT')) delete cv.SkillsPassport.LearnerInfo.Attachment
        if (!userHasCap(user, 'LOGO')) {
            for (let i in cv.SkillsPassport.LearnerInfo.Education) delete cv.SkillsPassport.LearnerInfo.Education[i].Organisation.Logo;
            for (let j in cv.SkillsPassport.LearnerInfo.WorkExperience) delete cv.SkillsPassport.LearnerInfo.WorkExperience[j].Employer.Logo;
        }


        const footerTemplate = footer(cv, user)
        const html = `
            <!doctype html>
            <html>
                <head>
                    ${getCommonHeaders(cv)}
                    <style>
                        ${getBasePatternCss(cv)}
                        ${getBaseImageCss(cv)}
                        ${getCommonCss(cv)}
                        ${getTemplateCss(cv)}
                    </style>
                    <style>${addStyle}</style>
                </head>
                <body>
                    ${getTemplateBody(cv)}
                </body>
            </html>
        `
        return { html, footerTemplate };
    }
}