module.exports = {
    styleLondon: cv => {

        const { modelBaseColor = "#247BA0", lng } = cv.Metadata
        
        return `
        /* ----------------------------------------------- TITLES */
        .section-title-container{
            margin-bottom: 10px;
        }
        .section-title-keyline{
            width: 45px;
            margin: 8px 0 5px;
            border-top: 2px solid ${modelBaseColor};
        }
        /* ----------------------------------------------- PROFILE PICTURE */
        .profile-pic-container{
            ${lng === "ar" ? "padding-right: 20px" : "padding-left: 20px"}; 
        }`

    }
}