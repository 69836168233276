import { t } from "cvpop-localization-sdk"
import { modelCatalogue } from "cvpop-constants-sdk"


import WebModelPreviewCard from "../../../molecules/cards/WebModelPreviewCard"
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView";
import WebHeader from "../../../molecules/views/WebHeader";

const WebModel = () => (
    <>
        <WebHeader title={t("model")} />

        <div className="grid grid-cols-2 gap-5 p-5">
            {modelCatalogue.map(el => (
                <WebModelPreviewCard key={`model${el.code}`} item={el} />
            ))}
        </div>

    </>
)

export default WebModel