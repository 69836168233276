const { modelCatalogue } = require("cvpop-constants-sdk");

const { pattern0 } = require("./pattern0");
const { pattern1 } = require("./pattern1");
const { pattern2 } = require("./pattern2");
const { pattern3 } = require("./pattern3");
const { pattern4 } = require("./pattern4");
const { pattern5 } = require("./pattern5");
const { pattern6 } = require("./pattern6");
const { pattern7 } = require("./pattern7");
const { pattern8 } = require("./pattern8");
const { pattern9 } = require("./pattern9");
const { pattern10 } = require("./pattern10");
const { pattern11 } = require("./pattern11");
const { pattern12 } = require("./pattern12");

module.exports = {
    getBasePatternCss: cv => {
        const basePattern = cv.Metadata.modelBasePattern || modelCatalogue.find(o => o.code === (cv.Metadata.modelType || "london")).defaultPattern;
        switch (String(basePattern)) {
            case "0": return pattern0
            case "1": return pattern1
            case "2": return pattern2
            case "3": return pattern3
            case "4": return pattern4
            case "5": return pattern5
            case "6": return pattern6
            case "7": return pattern7
            case "8": return pattern8
            case "9": return pattern9
            case "10": return pattern10
            case "11": return pattern11
            case "12": return pattern12
            default: return ""
        }
    }
}