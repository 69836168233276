const { t } = require("cvpop-localization-sdk")

const { sectionTitle } = require("./section-title")
const { sectionSubtitle } = require("./section-subtitle")
const { sectionPeriod } = require("./section-period")
const { sectionDescription } = require("./section-description")

const { formatAddress } = require("../../script")

module.exports = {
    work: cv => {

        const { lng } = cv.Metadata
        const { WorkExperience = [] } = cv.SkillsPassport.LearnerInfo

        const locale = lng === 'nb' ? 'no' : lng

        if (WorkExperience.length === 0) return ""

        return `
        <div class="work-container container">
            ${sectionTitle(cv, "cvLabelExperience", "WorkExperience", "fas fa-briefcase")}
            ${WorkExperience.map(({ Position, Employer, Period, Activities }) => `
                <div class="work-content content">
                    <div class="work-company">
                        <div class="work-company-name">
                            ${Position.Label ? sectionSubtitle("position", Position.Label) : ""}
                            <div class="employer-container ptXS">
                                ${Employer.Name ? `
                                <p class="employer-name">
                                    ${Employer.Name} ${formatAddress(Employer.ContactInfo.Address.Contact, locale) ? `<span class="employer-address">| ${formatAddress(Employer.ContactInfo.Address.Contact, locale)}</span>` : ""}
                                </p>
                                ` : ""}
                            </div>
                        </div>
                        <div class="work-company-period">
                            ${sectionPeriod(cv, Period, "work")}
                        </div>
                    </div>
        
                    <div class="section-with-logo">
                        ${Activities ? sectionDescription(Activities) : ""}
                        ${Employer.Logo ? `
                            <div class="logo-container">
                                <div class="logo" style="background-image: url(data:image/png;base64,${Employer.Logo.Data})"></div>
                            </div>
                        `: ""}
                    </div>

                    ${Employer.Sector?.Label ? `
                    <p class="employer-sector-container ptXS">
                        <span class="employer-sector-label">${t("cvLabelWorkSector", { locale })}</span>: <span class="employer-sector-content">${Employer.Sector.Label}</span>
                    </p>
                    ` : ""}
                </div>
            `).join("")}
        </div>
        `
    }
}