

import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";

import { lotties } from "cvpop-assets-sdk"
import { margin, colors, padding, typography, layout } from "cvpop-constants-sdk";
import { t } from "cvpop-localization-sdk";

import Lottie from 'react-lottie';

import { useColorScheme } from '../../../controllers/hooks';


const WebSpinnerModal = ({ }) => {

    // ------------------------------------------------------------------------------------------------------
    const { spin } = useSelector(state => state.appReducer)

    // ------------------------------------------------------------------------------------------------------
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const { isOpen, onOpen, onClose } = useDisclosure();

    // ------------------------------------------------------------------------------------------------------
    const [progress, setProgress] = useState(0)

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (spin) return // only close

        setProgress(100)
        setTimeout(() => {
            onClose()
            setProgress(0)
        }, 500)

    }, [spin])

    useEffect(() => {
        if (!spin) return // only open

        onOpen()
        setProgress(0)
        const interval = setInterval(() => {
            const delta = Math.random() / 15;
            setProgress(p => (p + delta) > 95 ? 95 : p + delta)
        }, 10);

        return () => clearInterval(interval);
    }, [spin])


    // ------------------------------------------------------------------------------------------------------
    return (
        <Modal
            isOpen={isOpen}
            isDismissable={false}
            isKeyboardDismissDisabled={true}
            backdrop={"blur"}
            className={`${cs} text-foreground`}
            hideCloseButton
        >
            <ModalContent>
                <ModalBody>
                    <div className="max-w-80 self-center cursor-default">
                        <Lottie
                            autoPlay loop
                            options={{ animationData: lotties.spin[cs] }}
                        />
                    </div>
                    <div className='m-4 mt-0 rounded-xl text-center bg-default'>
                        <div className='p-2 rounded-xl truncate bg-primary text-white' style={{ width: `${progress}%` }}>
                            <span>{`${parseInt(progress)}%`}</span>
                        </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    )

}

export default WebSpinnerModal

