import { useCallback, useMemo, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, useDisclosure, Card, CardBody } from "@nextui-org/react";
import { Cropper } from 'react-advanced-cropper';
import { produce } from "immer";

import { isProUser } from "cvpop-utility-sdk"
import { setCv } from "cvpop-redux-sdk";
import { illustrations } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk";

import { createFbId, confirmDelete, errorHandler, imageToBase64, goToPaywall } from "../../../../controllers/app";
import { useColorScheme } from "../../../../controllers/hooks";

import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";



const WebPortfolio = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const inputRef = useRef()
    const cropRef = useRef()
    const cropModal = useDisclosure();
    const imageModal = useDisclosure();

    // ------------------------------------------------------------------------------------------------------
    const [cropImg, setCropImg] = useState("")
    const [selItemInd, setSelItemInd] = useState(-1)

    // ------------------------------------------------------------------------------------------------------
    const isPro = useMemo(() => isProUser(user), [user])

    // ------------------------------------------------------------------------------------------------------
    const { Portfolio = [] } = cv.SkillsPassport.LearnerInfo

    // ------------------------------------------------------------------------------------------------------
    const addItemToPortfolio = useCallback(async () => {

        if (!cropRef.current) return
        if (inputRef.current) inputRef.current.value = ""

        const croppedImage = cropRef.current.getCanvas().toDataURL("image/jpeg");
        const newPhoto = { Id: createFbId(), MimeType: croppedImage.split(',')[0], Data: croppedImage.split(',')[1], "Metadata": [{ "Key": "dimension", "Value": "400x400" }] }

        d(setCv({
            cv: produce(cv, d => {
                if (!d.SkillsPassport.LearnerInfo.Portfolio) d.SkillsPassport.LearnerInfo.Portfolio = []
                d.SkillsPassport.LearnerInfo.Portfolio.push(newPhoto)
            })
        }))

        cropModal.onClose()

    }, [cropRef, inputRef, cv])

    const removeItemFromPortfolio = useCallback(index => {
        confirmDelete({
            cb: () => {
                d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Portfolio.splice(index, 1) }) }))
            }
        })
    })


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvLabelPortfolio")} />
            <div className="p-5">
                {
                    (Portfolio.length === 0 || !isPro) && (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations.portfolio }}
                                title={t(!isPro ? "featPortfolio" : `cvLabelPortfolio`)}
                                message={t(!isPro ? "portfolioMsg" : "emptyListMsg")}
                            >
                                <Button
                                    color="primary"
                                    className="mt-6"
                                    onPress={() => !isPro ? goToPaywall(offering, platform, n) : inputRef.current && inputRef.current.click()}
                                    endContent={!isPro && (
                                        <div className='w-6 h-6 flex justify-center items-center shadow-2xl rounded-full'>
                                            <span className="material-symbols-outlined filled text-yellow-400">
                                                workspace_premium
                                            </span>
                                        </div>
                                    )}
                                >
                                    {t(!isPro ? "unlock" : 'add')}
                                </Button>

                                <WebAIAssistantButton color={"secondary"} sectionId={"PORTFOLIO"} />
                            </WebCenterView>
                        </div>
                    )
                }

                <div className="grid grid-cols-3 gap-4">
                    {
                        isPro && Portfolio.map(({ Id, MimeType, Data }, i) => (
                            <Dropdown key={Id} backdrop={"blur"} className={`${cs} text-foreground`}>
                                <DropdownTrigger>
                                    <Card>
                                        <CardBody>
                                            <img src={`data:${MimeType};base64,${Data}`} style={{ width: 150, height: 150, margin: "0 auto" }} className="rounded object-cover" />
                                        </CardBody>
                                    </Card>

                                </DropdownTrigger>
                                <DropdownMenu
                                    aria-label="Static Actions"
                                    color={"primary"}
                                    onAction={k => {
                                        if (k === "delete") return removeItemFromPortfolio(i)
                                        if (k === "open") {
                                            setSelItemInd(i)
                                            return imageModal.onOpen()
                                        }
                                    }}
                                >
                                    <DropdownItem key="open" title={t("open")} endContent={<WebIcon name={"preview"} />} />
                                    <DropdownItem key="delete" title={t("delete")} className="text-danger" color="danger" endContent={<WebIcon name={"delete"} />} />
                                </DropdownMenu>
                            </Dropdown>

                        ))
                    }
                </div>

                <input
                    type="file"
                    className='hidden'
                    accept="image/png,image/jpeg"
                    ref={inputRef}
                    onChange={({ target }) => {
                        if (target.files.length === 0) return
                        imageToBase64(target.files[0])
                            .then(b64 => {
                                setCropImg(b64);
                                cropModal.onOpen();
                            })
                            .catch(e => errorHandler("err_convert_base64", e))
                    }}
                />

                <Modal
                    isOpen={cropModal.isOpen}
                    onOpenChange={cropModal.onOpenChange}
                    hideCloseButton={true}
                    backdrop={"blur"}
                    className={`${cs} text-foreground`}
                >
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalHeader className="flex flex-col gap-1">{t("cutImage")}</ModalHeader>

                                <ModalBody>
                                    <Cropper ref={cropRef} src={cropImg} aspectRatio={1} />
                                </ModalBody>

                                <ModalFooter>
                                    <Button
                                        color="danger"
                                        variant="light"
                                        onPress={() => {
                                            if (inputRef.current) inputRef.current.value = ""
                                            onClose()
                                        }}
                                    >
                                        {t("cancel")}
                                    </Button>
                                    <Button color="primary" onPress={addItemToPortfolio} >
                                        {t("done")}
                                    </Button>
                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </Modal>

                <Modal
                    isOpen={imageModal.isOpen}
                    onOpenChange={imageModal.onOpenChange}
                    hideCloseButton={true}
                    backdrop={"blur"}
                    size={"lg"}
                    className={`${cs} text-foreground`}
                >
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalHeader>
                                    <p className="text-center w-full">
                                        {t("image")} {selItemInd + 1}/{Portfolio.length}
                                    </p>
                                </ModalHeader>
                                <ModalBody>
                                    {
                                        selItemInd >= 0 && (
                                            <div className="flex justify-center items-center">
                                                {
                                                    Portfolio.length > 1 && (
                                                        <Button isIconOnly isDisabled={selItemInd === 0} onClick={() => setSelItemInd(selItemInd - 1)}>
                                                            <WebIcon name={"chevron_left"} />
                                                        </Button>
                                                    )
                                                }
                                                <img alt={t("image")} src={`data:$image/png;base64,${Portfolio[selItemInd]?.Data}`} className="mx-4 max-h-80" />
                                                {
                                                    Portfolio.length > 1 && (
                                                        <Button isIconOnly isDisabled={selItemInd === Portfolio.length - 1} onClick={() => setSelItemInd(selItemInd + 1)}>
                                                            <WebIcon name={"chevron_right"} />
                                                        </Button>
                                                    )
                                                }

                                            </div>
                                        )
                                    }
                                </ModalBody>

                                <ModalFooter>
                                    <Button color="primary" onPress={onClose}>
                                        {t("close")}
                                    </Button>
                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </Modal>

                {
                    (Portfolio.length > 0 && Portfolio.length < 6 && isPro) && (
                        <>
                            <Button
                                fullWidth
                                color={"primary"}
                                className="mt-3"
                                onPress={() => inputRef.current && inputRef.current.click()}
                            >
                                {t("add")}
                            </Button>
                            <WebAIAssistantButton color={"secondary"} sectionId={"PORTFOLIO"} />
                        </>
                    )
                }



            </div>

        </div>

    )
}

export default WebPortfolio