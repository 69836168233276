const { languages } = require("cvpop-collections-sdk");
const { t } = require("cvpop-localization-sdk")

const _ = require("lodash")

const { sectionTitle } = require("./section-title")
const { sectionSubtitle } = require("./section-subtitle")

module.exports = {
    foreignLanguageTable: cv => {

        const { lng } = cv.Metadata
        const { ForeignLanguage = [] } = cv.SkillsPassport.LearnerInfo.Skills.Linguistic

        const locale = lng === 'nb' ? 'no' : lng

        if (ForeignLanguage.length === 0) return ""
        return `
        <div class="foreign-lng-container container">
            ${sectionTitle(cv, "cvLabelForeignLanguage", "ForeignLanguage", "fas fa-language")}

            ${ForeignLanguage.map(({ Description = {}, ProficiencyLevel = {}, Certificate = [] }) => `

                <div class="foreign-lng-content content">

                    ${Description.Code ? sectionSubtitle("languages", (languages[locale].find(v => v.code === Description.Code) || { label: Description.Code }).label) : ""}

                    ${_.some([ProficiencyLevel.Listening, ProficiencyLevel.Reading, ProficiencyLevel.SpokenInteraction, ProficiencyLevel.SpokenProduction, ProficiencyLevel.Writing]) ? `
                        <table class="foreign-lng-table ptXS">
                            <tr>
                                ${ProficiencyLevel.Listening ? `<th class="foreign-lng-th">${t("cvLabelForeignLanguageListening", { locale })}</th>` : ""}
                                ${ProficiencyLevel.Reading ? `<th class="foreign-lng-th">${t("cvLabelForeignLanguageReading", { locale })}</th>` : ""}
                                ${ProficiencyLevel.SpokenInteraction ? `<th class="foreign-lng-th">${t("cvLabelForeignLanguageSpokenInteraction", { locale })}</th>` : ""}
                                ${ProficiencyLevel.SpokenProduction ? `<th class="foreign-lng-th">${t("cvLabelForeignLanguageSpokenProduction", { locale })}</th>` : ""}
                                ${ProficiencyLevel.Writing ? `<th class="foreign-lng-th">${t("cvLabelForeignLanguageWriting", { locale })}</th>` : ""}
                            </tr>
                            <tr>
                                ${ProficiencyLevel.Listening ? ` <td class="foreign-lng-td">${ProficiencyLevel.Listening}</td>` : ""}
                                ${ProficiencyLevel.Reading ? ` <td class="foreign-lng-td">${ProficiencyLevel.Reading}</td>` : ""}
                                ${ProficiencyLevel.SpokenInteraction ? ` <td class="foreign-lng-td">${ProficiencyLevel.SpokenInteraction}</td>` : ""}
                                ${ProficiencyLevel.SpokenProduction ? ` <td class="foreign-lng-td">${ProficiencyLevel.SpokenProduction}</td>` : ""}
                                ${ProficiencyLevel.Writing ? `<td class="foreign-lng-td">${ProficiencyLevel.Writing}</td>` : ""}
                            </tr>
                        </table>
                    ` : ""}

                    ${Certificate.length > 0 ? `
                        <p class="certificate-container ptXS">
                            <span class="certificate-label">${t("certificates", { locale })}:</span>
                            <span class="certificate-content">
                                ${Certificate.map(({ Title }) => Title).join(", ")}
                            </span>
                        </p>
                    ` : ""}
                </div>

            `).join("")}
        </div>
        `
    }
}