import { useEffect } from "react"
import { useNavigate, useRouteError } from "react-router-dom"

import { t } from "cvpop-localization-sdk"

import { errorHandler } from "../../controllers/app"

import WebErrorView from "../molecules/views/WebErrorView"

const WebError = () => {

    // ------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const e = useRouteError()

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => {
        errorHandler("err_web_navigation", e, true)
    }, [e])


    // ------------------------------------------------------------------------------------------------------
    return (
        <div className="min-h-screen flex justify-center items-center flex-col">
            <WebErrorView
                buttonProps={{
                    onClick: () => n(-1),
                    text: t("back")
                }}
            />
        </div>
    )
}

export default WebError