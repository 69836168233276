
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Listbox, ListboxItem, Card, CardBody, Button, Image } from "@nextui-org/react"

import { t } from "cvpop-localization-sdk"
import { pictures } from "cvpop-assets-sdk"

import { openUrl } from "../../../../controllers/app"

import WebIcon from "../../../atoms/WebIcon"
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView"


const WebBookDetail = ({ }) => {

    // ------------------------------------------------------------------------------------------------------------------------ 
    const { bookList } = useSelector(state => state.bookReducer)
    const { bookId, platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------ 
    const book = useMemo(() => bookList.find(e => e.id === bookId), [bookId, bookList])
    const isWeb = useMemo(() => platform === "web", [platform])

    // ------------------------------------------------------------------------------------------------------
    const { genericPlaceholder } = pictures
    const { volumeInfo, saleInfo } = book
    const { title, subtitle, imageLinks, description, authors, publisher, categories, previewLink } = volumeInfo
    const { buyLink } = saleInfo

    // ------------------------------------------------------------------------------------------------------------------------ 
    return (
        <div className="w-8/12" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>
            <div className="overflow-y-auto" style={{ height: `calc(100% - 104px)` }}>
                <div className="p-8">
                    <p className="text-3xl font-bold">{title}</p>
                    <div className="flex mt-5 items-center">
                        <div>
                            <Image
                                src={imageLinks ? (imageLinks.thumbnail || imageLinks.smallThumbnail) : genericPlaceholder}
                                radius={"none"}
                                fallbackSrc={genericPlaceholder}
                                className="max-w-48 max-h-48"
                            />
                        </div>
                        <div className="ml-4">
                            {subtitle && <p className="text-lg">{subtitle}</p>}
                        </div>
                    </div>

                    {description && (<WebListItemHeaderView text={t('description')} />)}
                    {description && (
                        <Card shadow={"none"}>
                            <CardBody>
                                <p>{description}</p>
                            </CardBody>
                        </Card>
                    )}

                    {authors && authors.length > 0 && (<WebListItemHeaderView text={t('authors')} />)}
                    {authors && authors.length > 0 && (
                        <Card shadow={"none"}>
                            <CardBody>
                                <Listbox aria-label="Actions" disabledKeys={authors.map((e, i) => `author-${i}`)}>
                                    {
                                        authors.map((el, i) => (
                                            <ListboxItem
                                                key={`author-${i}`}
                                                className={`opacity-100`}
                                                classNames={{ title: "whitespace-normal" }}
                                                title={el}
                                            />
                                        ))
                                    }
                                </Listbox>
                            </CardBody>
                        </Card>
                    )}

                    {publisher && (<WebListItemHeaderView text={t('publisher')} />)}
                    {publisher && (
                        <Card shadow={"none"}>
                            <CardBody>
                                <p>{publisher}</p>
                            </CardBody>
                        </Card>
                    )}

                    {categories && categories.length > 0 && (<WebListItemHeaderView text={t('categories')} />)}
                    {categories && categories.length > 0 && (
                        <Card shadow={"none"}>
                            <CardBody>
                                <Listbox aria-label="Actions" disabledKeys={categories.map((e, i) => `category-${i}`)}>
                                    {
                                        categories.map((el, i) => (
                                            <ListboxItem
                                                key={`category-${i}`}
                                                className={`opacity-100`}
                                                classNames={{ title: "whitespace-normal" }}
                                                title={el}
                                            />
                                        ))
                                    }
                                </Listbox>
                            </CardBody>
                        </Card>
                    )}

                </div>
            </div>
            <div className="p-8">
                <Button color={"primary"} fullWidth endContent={(<WebIcon name={"open_in_new"} />)} onClick={() => openUrl(buyLink || previewLink, platform)} >
                    {t("open")}
                </Button>
            </div>
        </div>
    )
}

export default WebBookDetail