import React, { useCallback, useMemo, useState } from "react";
import { Input, Button, Card, CardBody } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";

import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"


import { confirmDelete, createFbId } from "../../../../controllers/app";
import WebHeader from "../../../molecules/views/WebHeader";


const WebDriverLicenseSpecial = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------
    const [newTitle, setNewTitle] = useState("")

    // ------------------------------------------------------------------------------------------------------
    const list = useMemo(() => cv.SkillsPassport.LearnerInfo.DriverLicenceSpecial || [], [cv]);

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])

    const addItem = useCallback(() => {
        const Id = createFbId()
        editCv(produce(cv, d => { d.SkillsPassport.LearnerInfo.DriverLicenceSpecial.push({ Id, Title: newTitle }) }))
        setNewTitle("")
    }, [cv, newTitle])

    const deleteItem = useCallback(Id => {
        editCv(
            produce(cv, d => {
                const newList = d.SkillsPassport.LearnerInfo.DriverLicenceSpecial.filter(t => t.Id !== Id)
                d.SkillsPassport.LearnerInfo.DriverLicenceSpecial = newList
            })
        )
    }, [cv])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvLabelSpecialDriverLicence")} />

            <div className="p-5">
                <Card shadow={"none"}>
                    <CardBody>
                        {
                            list.map(({ Id, Title }, i) => (
                                <div key={`dlContainer${Id}`} className={`flex items-center ${i > 0 && "mt-3"}`}>
                                    <Input
                                        key={`dl${Id}`}
                                        placeholder={t("cvLabelSpecialDriverLicence")}
                                        defaultValue={Title}
                                        onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.DriverLicenceSpecial[${i}].Title`, target.value)))}
                                    />
                                    <Button isIconOnly color="danger" aria-label="Like" className="ml-3" onPress={() => confirmDelete({ cb: () => deleteItem(Id) })}>
                                        <span className="material-symbols-outlined">delete</span>
                                    </Button>
                                </div>
                            ))
                        }

                        <div className={`flex ${list.length > 0 ? "mt-3" : ""} items-center`}>
                            <Input
                                key={"newDl"}
                                placeholder={t("cvLabelSpecialDriverLicence")}
                                value={newTitle}
                                onChange={({ target }) => setNewTitle(target.value)}
                                onKeyDown={({ key }) => (key === "Enter" && newTitle) && addItem()}
                            />
                            <Button isIconOnly color="primary" aria-label="Like" className="ml-3" isDisabled={!newTitle} onPress={addItem}>
                                <span className="material-symbols-outlined">add</span>
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>

        </div>
    )
}

export default WebDriverLicenseSpecial