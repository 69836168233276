const _ = require("lodash")

module.exports = {
    profilePic: cv => {

        const { modelPhotoFormat, modelPhotoBgYPosition } = cv.Metadata
        const { Photo } = cv.SkillsPassport.LearnerInfo.Identification

        if (_.isEmpty(Photo) || !Photo?.Data) return ""
        return `
        <div class="profile-pic-container">
            <div class="profile-pic pic-format-${modelPhotoFormat}" style="background-position-y: ${modelPhotoBgYPosition || 50}%; background-image: url(data:image/png;base64,${Photo.Data})"></div>
        </div>
        `
    }
}