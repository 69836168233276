const { t } = require("cvpop-localization-sdk")

const _ = require("lodash")

const { sectionTitle } = require("./section-title")
const { sectionSubtitle } = require("./section-subtitle")
const { sectionDescription } = require("./section-description")

module.exports = {
    skills: cv => {

        const { Metadata } = cv
        const { Communication = {}, Organisational = {}, JobRelated = {}, Computer = {}, Other = {} } = cv.SkillsPassport.LearnerInfo.Skills

        const locale = Metadata.lng === 'no' ? 'nb' : Metadata.lng

        if (!_.some([Communication?.Description, Organisational?.Description, JobRelated?.Description, Computer?.Description, Other?.Description])) return ""

        return `
        <div class="skill-container container">
            ${sectionTitle(cv, "cvLabelSkills", "Skills", "fas fa-tools")}
            ${Communication?.Description ? `
                <div class="skill-content content">
                    ${sectionSubtitle("skill", t("cvLabelSkillsCommunication", { locale }))}
                    ${sectionDescription(Communication.Description)}
                </div>
            `: ""}
            ${Organisational?.Description ? `
            <div class="skill-content content">
                    ${sectionSubtitle("skill", t("cvLabelSkillsOrganisational", { locale }))}
                    ${sectionDescription(Organisational.Description)}
                </div>
            `: ""}
            ${JobRelated?.Description ? `
                <div class="skill-content content">
                    ${sectionSubtitle("skill", t("cvLabelSkillsJobRelated", { locale }))}
                    ${sectionDescription(JobRelated.Description)}
                </div>
            `: ""}
            ${Computer?.Description ? `
                <div class="skill-content content">
                    ${sectionSubtitle("skill", t("cvLabelSkillsComputer", { locale }))}
                    ${sectionDescription(Computer.Description)}
                </div>
            `: ""}
            ${Other?.Description ? `
            <div class="skill-content content">
                    ${sectionSubtitle("skill", t("cvLabelSkillsOther", { locale }))}
                    ${sectionDescription(Other.Description)}
                </div>
            `: ""}
        </div>
        `
    }
}
