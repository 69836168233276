module.exports = {
    styleHelsinki: cv => {

        const { modelBaseColor = "#247BA0", lng } = cv.Metadata
        
        return `
        /* ----------------------------------------------- DOCUMENT */
        .header {
            display: table;
        }
        .body {
            display: block !important;
        }
        /* ----------------------------------------------- TITLES */
        .section-title-container{
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .section-title-keyline{
            margin: 8px 0 5px;
            border-top: 2px solid ${modelBaseColor};
        }
        /* ----------------------------------------------- PROFILE PICTURE */
        .profile-pic-container{
            ${lng === "ar" ? "padding-left: 20px" : "padding-right: 20px"}; 
        }
        /* ----------------------------------------------- SECTION PERIOD */
        .section-period-container{
            text-align: right;
        }
        /* ----------------------------------------------- PORTFOLIO */
        .unique-column > * .portfolio-item{
            height: 105px !important;
            width: 105px !important;
        }
        /* ----------------------------------------------- ATTACHMENT */
        .unique-column > * .attachment-item{
            height: 165px !important;
            width: 165px !important;
        }
        `

    }
}