module.exports = {
    styleRio: cv => {

        const { modelBaseColor = "#247BA0", lng } = cv.Metadata

        return `
            /* ----------------------------------------------- DOCUMENT */
            .header {
                display: table;
                border-left: 50px solid ${modelBaseColor};
                padding-top: 40px;
            }
            .body {
                display: block !important;
                border-left: 50px solid ${modelBaseColor};
                margin-top: -1px;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .section-title-keyline{
                margin: 8px 0 5px;
                border-top: 2px solid ${modelBaseColor};
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                ${lng === "ar" ? "padding-left: 20px" : "padding-right: 20px"}; 
            }
            /* ----------------------------------------------- PORTFOLIO */
            .half-column > * .portfolio-item {
                height: 145px !important;
                width: 145px !important;
            }
            /* ----------------------------------------------- ATTACHMENT */
            .half-column > * .attachment-item {
                height: 145px !important;
                width: 145px !important;
            }

        `

    }
}