import { createSlice } from '@reduxjs/toolkit'

const bookSlice = createSlice({
    name: 'book',
    initialState: {
        bookList: []
    },
    reducers: {
        setBookList(state, action) {
            state.bookList = action.payload
        },
    }
})

export default bookSlice