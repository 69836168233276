import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from "@nextui-org/react";
import { useNavigate, useParams } from 'react-router-dom';
import { produce } from "immer"
import { updateProfile } from 'firebase/auth';

import { setUser } from 'cvpop-redux-sdk'
import { t } from "cvpop-localization-sdk";

import { auth } from '../../../controllers/firebase';
import { errorHandler } from '../../../controllers/app';

import WebIcon from '../../atoms/WebIcon';


const WebIntroDisplayName = ({ type }) => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const fbuser = auth.currentUser;

    // ------------------------------------------------------------------------------------------------------
    const [name, setName] = useState(user[`${type}Name`] || "")
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const isFirst = useMemo(() => type === "first", [type])

    // ------------------------------------------------------------------------------------------------------
    const setNameType = useCallback(() => {
        d(setUser({ user: produce(user, d => { d[`${type}Name`] = name }) }))

        if (type === "last") return n(`/app/${platform}/onboarding/job-title`)

        setLoading(true)
        updateProfile(fbuser, { displayName: name })
            .then(() => n(`/app/${platform}/onboarding/last-name`))
            .catch(e => errorHandler("err_set_account_name", e))
            .finally(() => setLoading(false))
    }, [d, n, fbuser, type, name, user])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className=' bg-content1 flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <div className='max-w-md min-w-80 m-3'>

                {
                    (!isFirst && platform !== "web") && (
                        <Button isIconOnly variant={"light"} className='mb-3' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }

                <h1 className='text-3xl mb-3 font-bold'>
                    {isFirst ? t("introOurselves") : `${t("hello")} ${fbuser.displayName}, ${t("whatsYourSurname")}`}
                </h1>

                <Input
                    defaultValue={user[`${type}Name`] || ""}
                    size={"lg"}
                    autoFocus
                    isClearable
                    autoCapitalize={"words"}
                    onChange={({ target }) => setName(target.value)}
                    onKeyDown={({ key }) => (key === "Enter" && !loading && name) && setNameType()}
                    placeholder={t(isFirst ? "whatsYourName" : "lastNamePh")}
                />

                <Button
                    color={"primary"}
                    className='mt-3'
                    isLoading={loading}
                    isDisabled={!name}
                    fullWidth
                    onPress={() => setNameType()}
                >
                    {t('continue')}
                </Button>
            </div>
        </div>
    )
}

export default WebIntroDisplayName