module.exports = {
    styleAmsterdam: cv => {

        const { modelBaseColor = "#247BA0", lng } = cv.Metadata

        return `
            /* ----------------------------------------------- DOCUMENT */
            .header{
                background-color: ${modelBaseColor};
                margin: ${lng === "ar" ? "-20px -20px 20px -40px" : "-20px -40px 20px -20px"};
                padding: 20px 0px 0px 20px;
            }
            .right-column > .container {
                border-bottom: 2px solid #e9e9e9;
                margin-bottom: 10px;
            }
            .left-column{
                background: #e9e9e9;
                padding: 40px 20px 10px 20px;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                margin-bottom: 5px;
                display: block;
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                display: block;
                background: transparent;
            }
            .profile-pic {
                margin: 18px auto;
                height: 280px;
                width: unset;
                margin: -40px -20px 20px -20px;
                border: 0px !important;
                border-radius: 0px !important;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                background-color: transparent;
                margin-top: -20px;
                height: 280px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-shadow: 0px 0px 5px black;
                max-width: 460px;
            }
            .name, .surname {
                color: white;
                padding-left: 0px;
                position: relative;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-right: 20px;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);
            }
            .surname {
                font-weight: bold;
            }
            .professional-title{
                color: white;
                padding-right: 20px;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);
            }
            /* ----------------------------------------------- ABILITIES */
            .left-column > * .ability-level-single-bar-background, .ability-level-bars-background {
                border: 3px solid white;
            }
        `

    }
}