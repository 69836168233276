import React, { useCallback, useMemo } from "react";
import { Card, CardBody, Listbox, ListboxItem } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";

import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"
import WebHeader from "../../../molecules/views/WebHeader";


const WebDriverLicense = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------
    const list = useMemo(() => ["AM", "A1", "A2", "A", "B1", "B", "BE", "C1", "C1E", "C", "CE", "D1", "D1E", "D", "DE"], []);

    // ------------------------------------------------------------------------------------------------------
    const onItemPress = useCallback(item => {

        const newCv = produce(cv, d => {
            if (!d.SkillsPassport.LearnerInfo.Skills.Driving) d.SkillsPassport.LearnerInfo.Skills.Driving = { Description: [] }
            const list = d.SkillsPassport.LearnerInfo.Skills.Driving.Description;
            const index = list.findIndex(e => e === item);
            index !== -1 ?
                list.splice(index, 1) :
                list.push(item)
        })

        d(setCv({ cv: newCv }))
    }, [cv])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvLabelDriverLicence")} />

            <div className="p-5">
                <Card shadow={"none"}>
                    <CardBody>
                        <Listbox
                            aria-label="Multiple selection example"
                            variant="flat"
                            selectionMode="multiple"
                            defaultSelectedKeys={cv.SkillsPassport.LearnerInfo.Skills.Driving?.Description || []}
                        >
                            {list.map(el => (
                                <ListboxItem
                                    key={el}
                                    value={el}
                                    title={el}
                                    description={t(`driverLicenceMsg${el}`)}
                                    onClick={() => onItemPress(el)}
                                />
                            ))}
                        </Listbox>
                    </CardBody>
                </Card>
            </div>
        </div>

    )
}

export default WebDriverLicense