import { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUser, setUserLogged } from 'cvpop-redux-sdk'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Cropper } from 'react-advanced-cropper';
import { Card, CardHeader, CardBody, CardFooter, Input, Button, Avatar, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { updateProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider, signOut, sendEmailVerification } from 'firebase/auth';
import { collection, query, where, getDocs, doc, deleteDoc } from "firebase/firestore";
import { produce } from "immer"

import { icons } from 'cvpop-assets-sdk';
import { isProUser, getUnsubcribeUserUrl, getAvatarUser } from 'cvpop-utility-sdk'
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { confirmAlert, confirmDelete, errorHandler, imageToBase64, showAlert } from "../../../controllers/app"
import { auth, db } from '../../../controllers/firebase'
import { deleteCv } from '../../../controllers/cv'
import { useColorScheme } from '../../../controllers/hooks';

import 'react-advanced-cropper/dist/style.css'



const WebAccount = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()
    const { runDeleteAccount } = useLocation().state || {}

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const d = useDispatch()
    const cs = useColorScheme()
    const inputRef = useRef()
    const cropRef = useRef()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------
    const [cropImg, setCropImg] = useState("")
    const [oldPwd, setOldPwd] = useState("");
    const [newPwd, setNewPwd] = useState("");
    const [repeatPwd, setRepeatPwd] = useState("");
    const [loadingChangePwd, setLoadingChangePwd] = useState(false)
    const [loadingLogout, setLoadingLogout] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingVerifyEmail, setLoadingVerifyEmail] = useState(false);

    // ------------------------------------------------------------------------------------------------------
    const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
    const { displayName, email, emailVerified, isAnonymous } = fbuser || {}; //in case of logout
    const { isAms, isMarketing, gender, firstName, lastName, professionalTitle, location, planSubscriptionState } = user || {}

    // ------------------------------------------------------------------------------------------------------
    const avatar = useMemo(() => getAvatarUser(user, platform) || icons.avatar, [user, platform])
    const isPro = useMemo(() => isProUser(user), [user])
    const changePwdOn = useMemo(() => user && !user.isAnonymous && fbuser.providerData.find(e => e.providerId === "password"), [user])

    // ------------------------------------------------------------------------------------------------------------------------
    const runLogout = useCallback(() => {

        setLoadingLogout(true)
        confirmAlert(t("logout"), t("logoutMsg"))
            .then(() => signOut(auth))
            .then(() => {
                d(setUserLogged(false))
                d(setUser({ user: null, ignoreListener: true }))
                n(`/app/${platform}/onboarding`)
            })
            .catch(e => errorHandler("err_logout", e))
            .finally(() => setLoadingLogout(false))

    }, [d, n])

    const runDeleteUser = useCallback(async () => {

        if (isAms || isMarketing) return alert("DEV: Not allowed");

        await confirmAlert(t('areYouSure'), t('deleteDataLost'), "question", t('delete'));

        setLoadingDelete(true)

        try {

            const uid = fbuser.uid

            const [interviewSnap, quizSnap, cvSnap] = await Promise.all([
                getDocs(query(collection(db, "00_INTERVIEWS_00"), where("uid", "==", uid))),
                getDocs(query(collection(db, "00_QUIZES_00"), where("uid", "==", uid))),
                getDocs(query(collection(db, "00_RESUMES_00"), where("Metadata.uid", "==", uid))),
            ])

            interviewSnap.docs.forEach(async doc => await deleteDoc(doc(db, "00_INTERVIEWS_00", doc.id)));
            quizSnap.docs.forEach(async doc => await deleteDoc(doc(db, "00_QUIZES_00", doc.id)));
            cvSnap.docs.forEach(async doc => await deleteCv(doc.data(), true));

            deleteDoc(doc(db, "00_FCM_TOKENS_00", uid)).catch(e => errorHandler("err_delete_all_fcm_toket", e, true))

            await deleteDoc(doc(db, "00_USERS_00", uid))

            await fbuser.delete()
            d(setUser({ user: null, ignoreListener: true }))
            setLoadingDelete(false)
            n(`/app/${platform}/onboarding`)

        } catch (e) {
            errorHandler("err_delete_account", e)
            setLoadingDelete(false)
        }

    }, [fbuser]);

    // ------------------------------------------------------------------------------------------------------
    const runUpdatePwd = useCallback(async () => {

        setLoadingChangePwd(true)
        reauthenticateWithCredential(fbuser, EmailAuthProvider.credential(fbuser.email, oldPwd))
            .then(() => updatePassword(fbuser, newPwd))
            .then(() => confirmAlert(t('pwdUpdated'), t('pwdUpdatedMsg')))
            .catch(e => errorHandler("err_update_pwd", e))
            .finally(() => setLoadingChangePwd(false))

    }, [newPwd, oldPwd, repeatPwd, n])

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (runDeleteAccount) setTimeout(() => runDeleteUser(), [1000])
    }, [runDeleteAccount])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8 p-8">
            <Card>
                <CardHeader>
                    {t("profile")}
                </CardHeader>
                <CardBody>
                    <div className='flex'>
                        <div className='self-center m-3'>
                            <Avatar
                                isBordered
                                onClick={() => {
                                    if (!inputRef.current) return
                                    inputRef.current.value = ""
                                    inputRef.current.click()
                                }}
                                src={avatar}
                            />
                        </div>
                        <div className='w-full'>
                            <Input
                                placeholder={t('name')}
                                defaultValue={firstName || displayName || ""}
                                className='mt-2'
                                onChange={({ target }) => {
                                    d(setUser({ user: produce(user, d => void _.set(d, "firstName", target.value)) }))
                                    updateProfile(fbuser, { displayName: target.value }).catch(e => errorHandler(e, "err_update_usr_name", true))
                                }}
                            />
                            <Input
                                placeholder={t('surname')}
                                defaultValue={lastName || ""} className='mt-2'
                                onChange={({ target }) => d(setUser({ user: produce(user, d => void _.set(d, "lastName", target.value)) }))}
                            />
                        </div>
                    </div>

                    <Input
                        placeholder={t('jobTitle')}
                        defaultValue={professionalTitle || ""}
                        onChange={({ target }) => d(setUser({ user: produce(user, d => void _.set(d, "professionalTitle", target.value)) }))}
                        className='mt-2'
                    />
                    {/* <Input placeholder={t('location')} defaultValue={location || ""} className='mt-2' /> */}
                    {email && (<Input value={email} disabled className='mt-2' />)}
                </CardBody>
                <CardFooter>
                    {
                        !emailVerified && !isAnonymous && (
                            <Button
                                fullWidth
                                isLoading={loadingVerifyEmail}
                                color={"warning"}
                                onClick={async () => {
                                    await confirmAlert(t('verifyEmail'), t('verifyEmailMsg'))

                                    setLoadingVerifyEmail(true)
                                    sendEmailVerification(fbuser)
                                        .then(() => showAlert(t('verifyEmailSent'), t('verifyEmailSentMsg')))
                                        .catch(e => errorHandler("err_validate_email", e))
                                        .finally(() => setLoadingVerifyEmail(false))
                                }}
                            >
                                {t('verifyEmail')}
                            </Button>
                        )
                    }
                </CardFooter>
            </Card>

            {
                changePwdOn && (
                    <Card>
                        <CardHeader>
                            {t("changePwd")}
                        </CardHeader>
                        <CardBody>
                            <Input type="password" placeholder={t('currentPwd')} onChange={({ target }) => setOldPwd(target.value)} className='mt-2' />
                            <Input type="password" placeholder={t('newPwd')} onChange={({ target }) => setNewPwd(target.value)} className='mt-2' />
                            <Input type="password" placeholder={t('newPwdRepeat')} onChange={({ target }) => setRepeatPwd(target.value)} className='mt-2' />
                        </CardBody>
                        <CardFooter className='flex-row-reverse'>
                            <Button
                                fullWidth
                                isLoading={loadingChangePwd}
                                isDisabled={!newPwd || !repeatPwd || !oldPwd || (newPwd !== repeatPwd)}
                                color={"primary"}
                                onClick={runUpdatePwd}
                            >
                                {t("changePwd")}
                            </Button>
                        </CardFooter>
                    </Card>
                )
            }


            <Card>
                <CardHeader>
                    {t("account")}
                </CardHeader>
                <CardFooter className='flex-col'>

                    {
                        isPro && (
                            <Button
                                fullWidth
                                color={"secondary"}
                                onClick={() => {
                                    if (platform === "macos" || platform === "visionos")
                                        return window.webkit.messageHandlers["openUrl"].postMessage(getUnsubcribeUserUrl(user));

                                    window.open(getUnsubcribeUserUrl(user), "_blank")
                                }
                                }
                            >
                                {t('manageSubscription')}
                            </Button>
                        )
                    }

                    {
                        !isAnonymous && (
                            <Button fullWidth color='primary' isLoading={loadingLogout} onClick={runLogout} className='mt-3'>{t("logout")}</Button>
                        )
                    }

                    {
                        (
                            isPro && planSubscriptionState === 'paused') || (isPro && planSubscriptionState) ?
                            (
                                <p className='text-default-600 text-center text-xs mt-3 '>{t('deleteAccountConstraints')}</p>
                            ) : (
                                <Button
                                    fullWidth
                                    color={"danger"}
                                    isLoading={loadingDelete}
                                    className='mt-3'
                                    onPress={() => confirmDelete({ cb: () => isAnonymous ? runDeleteUser() : n(`/app/${platform}/reauth`) })}
                                >
                                    {t('deleteAccount')}
                                </Button>
                            )
                    }


                </CardFooter>

            </Card>

            <input
                type="file"
                className='hidden'
                accept="image/png,image/jpeg"
                ref={inputRef}
                onChange={({ target }) => {
                    if (target.files.length === 0) return
                    if (!["image/png", "image/jpeg"].includes(target.files[0].type)) return showAlert(t("errTitle"), t("notSupportedTypeFile"))
                    imageToBase64(target.files[0])
                        .then(b64 => {
                            setCropImg(b64);
                            onOpen();
                        })
                        .catch(e => errorHandler("err_convert_base64", e))
                }}
            />

            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                hideCloseButton={true}
                backdrop={"blur"}
                className={`${cs} text-foreground`}
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">{t("cutImage")}</ModalHeader>

                    <ModalBody>
                        <Cropper ref={cropRef} src={cropImg} aspectRatio={0.75} />
                    </ModalBody>

                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={onClose} >
                            {t("cancel")}
                        </Button>
                        <Button
                            color="primary"
                            onPress={() => {
                                if (!cropRef.current) return
                                const croppedImage = cropRef.current.getCanvas().toDataURL("image/jpeg");
                                d(setUser({ user: produce(user, d => { d.avatar = croppedImage.split(',')[1] }) }));
                                onClose()
                            }}
                        >
                            {t("done")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </div>
    )

}

export default WebAccount