module.exports = {
    styleShanghai: cv => {

        const { modelBaseColor = "#247BA0", modelBaseFontSize = 11, modelTitleColor, modelExAreaColor, modelNameColor = "black" } = cv.Metadata
        
        return `
            /* ----------------------------------------------- DOCUMENT */
            .left-column{
                background: #e9e9e9;
                padding-right: 20px;
                padding-bottom: 10px;
                padding-left: 20px;
            }
            .right-column {
                padding-top: 40px;
            }
            .left-column-header{
                margin: -20px -20px 10px -20px;
                padding-top: 20px;
                background-color: ${modelBaseColor};
            }
            .right-column > .container {
                border-bottom: 2px solid #e9e9e9;
                margin-bottom: 10px;
            }
            .left-column > .container, .right-column > .container {
                position: relative;
            }
            /* ----------------------------------------------- TITLES */
            .left-column > * .section-title-container {
                display: block;
            }
            .section-title-container{
                display: table;
                color: ${modelTitleColor || modelBaseColor};
                margin-bottom: 5px;
            }
            .left-column > * .section-title-content{
                display: block;
                text-align: center;
                vertical-align: middle;
            }
            .section-title-content{
                vertical-align: middle;
                display: table-cell;
            }
            .section-title{
                vertical-align: middle;
                font-size: ${parseInt(modelBaseFontSize) + 4}px;
                padding-left: 10px;
            }
            .left-column > .container > div.section-title-keyline:before { 
                content: '';
                height: 2px;
                background-color: ${modelTitleColor || modelBaseColor};
                position: absolute;
                top: 17px;
                left: 0;
                width: 50px;
            }
            .left-column > .container > div.section-title-keyline:after { 
                content: '';
                height: 2px;
                background-color: ${modelTitleColor || modelBaseColor};
                position: absolute;
                top: 17px;
                right: 0;
                width: 50px;
            }
            .left-column > * .section-title-icon {
                margin: 0 auto;
                display: block;
                font-size: 20px;
                width: 50px;
            }
            .section-title-icon{
                display: table-cell;
                vertical-align: middle;
                width: 20px;
                text-align: center;
            }
            .right-column > * i.fas {
                font-size: 15px;
                background-color: ${modelTitleColor || modelBaseColor};
                border-radius: 5px;
                color: white;
                padding: 5px;
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                padding: 20px 20px 0px 20px;
                display: block;
                background-color: transparent;
            }
            .profile-pic {
                border: 3px solid white;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                display: block;
                width: unset;
                padding: 20px;
                text-shadow: 0px 0px 5px black;
                background-color: transparent;
                text-align: center;
            }
            .name {
                padding-top: 0px;
                color: white;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);	
            }
            .surname{
                color: white;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);	
            }
            .professional-title{
                margin-top: 10px;
                color: white;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);		
            }
            /* ----------------------------------------------- VIDEO */
            .left-column > .video-container.container > .video-content.content {
                text-align: center;
            }
            /* ----------------------------------------------- ABILITIES */
            .left-column > * .ability-level-single-bar-background, .ability-level-bars-background {
                border: 3px solid white;
            }

        `

    }
}