

import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal, ModalContent, ModalBody, Button, useDisclosure, Link } from "@nextui-org/react";
import { ref, onValue } from "firebase/database";
import { produce } from "immer"

import { setUser } from 'cvpop-redux-sdk';
import { illustrations } from "cvpop-assets-sdk"
import { urls } from "cvpop-constants-sdk";
import { t } from "cvpop-localization-sdk";

import { rtDb } from '../../../controllers/firebase';
import { useColorScheme } from '../../../controllers/hooks';
import { openUrl } from '../../../controllers/app';

import WebCenterView from '../views/WebCenterView';



const WebPrivacyModal = ({ }) => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const { terms, privacy } = urls.legal

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const { isOpen, onOpen, onClose } = useDisclosure();

    // ------------------------------------------------------------------------------------------------------
    const runAccetpTerms = useCallback(() => {
        const consents = { ...user.consents, termsAcceptance: true, termsAcceptanceTime: new Date().getTime()  }
        d(setUser({ user: produce(user, d => { d.consents = consents}) }))
    }, [user]);

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (!user) return
        const unsb = onValue(ref(rtDb, '/termsAndPrivacy'), snap => {
            const { terms, privacy } = snap.val()
            const { termsAcceptanceTime } = user.consents || {};
            (!termsAcceptanceTime || termsAcceptanceTime < terms || termsAcceptanceTime < privacy) ? onOpen() : onClose()
        });
        return () => unsb()
    }, [user])

    // ------------------------------------------------------------------------------------------------------
    return (
        <Modal
            isOpen={isOpen}
            isDismissable={false}
            isKeyboardDismissDisabled={true}
            backdrop={"blur"}
            className={`${cs} text-foreground`}
            hideCloseButton
        >
            <ModalContent>
                <ModalBody>
                    <div className="max-w-80 self-center cursor-default">
                        <WebCenterView
                            imageProps={{ src: illustrations.terms }}
                            title={t("introPersonalDataTitle")}
                            message={t("termsAndPrivacyMsg")}
                        />
                    </div>
                    <Button color="primary" className='mt-3' onClick={() => runAccetpTerms()}>
                        {t("continue")}
                    </Button>
                    <p className='font-sans text-xs text-center my-3'>
                        <span>{t("introDisclaimer")} </span>
                        <Link href={"#"} onClick={() => openUrl(terms, platform)} className='font-sans text-xs'>{t("terms")}</Link>
                        <span> {t("and")} </span>
                        <Link href={"#"} onClick={() => openUrl(privacy, platform)} className='font-sans text-xs'>{t("privacy")}</Link>
                    </p>
                </ModalBody>
            </ModalContent>
        </Modal>
    )

}

export default WebPrivacyModal

