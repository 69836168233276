import { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { Card, CardBody, CardFooter } from "@nextui-org/react"
import { useParams } from "react-router-dom"

import { getEnglishLabelFromLanguageCode } from "cvpop-utility-sdk"
import { t } from "cvpop-localization-sdk"
import { illustrations } from "cvpop-assets-sdk"

import { openUrl } from "../../../controllers/app"


const WebServices = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------
    const fiverrUserLng = useMemo(() => language === "pt" ? "br" : (language || "en"), [language])
    const fiverrFilterLng = useMemo(() => getEnglishLabelFromLanguageCode(fiverrUserLng).toLowerCase(), [fiverrUserLng])
    const fiverrSubDomain = useMemo(() => ["de", "es", "fr", "br", "it", "nl"].includes(fiverrUserLng) ? fiverrUserLng : "www", [fiverrUserLng])
    const serviceList = useMemo(() => [
        { id: "cv", cat: "writing-translation", key: "resume-writing" },
        { id: "coach", cat: "lifestyle", key: "career-counseling" },
        { id: "linkedin", cat: "writing-translation", key: "linkedin-profile-services" },
        { id: "letter", cat: "writing-translation", key: "cover-letter-services" },
    ], [])

    // ------------------------------------------------------------------------------------------------------
    const goToService = useCallback(i => {
        const affPreLink = "https://go.fiverr.com/visit/?bta=777819&brand=fiverrcpa&landingPage=https%3A%2F%2F";
        const affPostLink = `.fiverr.com%2Fcategories%2F${i.cat}%2F${i.key}%3Fsource%3Ddrop_down_filters%26ref%3Dlanguage%253A`;
        openUrl(`${affPreLink}${fiverrSubDomain}${affPostLink}${fiverrFilterLng}`, platform)
    }, [fiverrSubDomain, fiverrFilterLng])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-8">
                {serviceList.map(e => (
                    <Card key={e.id} shadow="sm" isPressable onPress={() => goToService(e)}>
                        <CardBody className="p-0">
                            <img width="100%" className="w-full object-cover" src={illustrations[`${e.id}service`]} />
                        </CardBody>
                        <CardFooter className="text-small ">
                            <div className="flex flex-col text-left w-full">
                                <p className="font-bold text-left">{t(`${e.id}ServiceTitle`)}</p>
                                <p className="text-xs font-light text-default-400">{t(`${e.id}ServiceMsg`)}</p>
                            </div>
                        </CardFooter>
                    </Card>
                ))}
            </div>
            <p className="mt-4 text-xs text-default-600">{t("fiverrDisclaimer")}</p>
        </div>
    )
}

export default WebServices