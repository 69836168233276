import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from "@nextui-org/react";
import { produce } from "immer"

import { setUser } from 'cvpop-redux-sdk'
import { t } from "cvpop-localization-sdk";

import WebIcon from '../../atoms/WebIcon';

const WebIntroJobTitle = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const n = useNavigate();
    const d = useDispatch();

    // ------------------------------------------------------------------------------------------------------
    const [jobTitle, setJobTitle] = useState(user.professionalTitle || "")

    // ------------------------------------------------------------------------------------------------------
    const setUserJobTitle = useCallback(() => {
        d(setUser({ user: produce(user, d => { d.professionalTitle = jobTitle }) }))
        n(`/app/${platform}/onboarding/avatar`)
    }, [user, jobTitle, d, n])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='bg-content1 flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <div className='max-w-md min-w-80 m-3'>

                {
                    platform !== "web" && (
                        <Button isIconOnly variant={"light"} className='mb-3' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }

                <h1 className='text-3xl mb-3 font-bold'>
                    {t("niceToMeetYou")} {user.firstName}!
                </h1>
                <h1 className='text-xl mb-3'>
                    {t("whatsYourJobTitle")}
                </h1>

                <Input
                    defaultValue={jobTitle}
                    size={"lg"}
                    isClearable
                    autoFocus
                    autoCapitalize={"words"}
                    onChange={({ target }) => setJobTitle(target.value)}
                    onKeyDown={({ key }) => (key === "Enter" && jobTitle) && setUserJobTitle()}
                    placeholder={t("jobTitlePh")}
                />

                <Button
                    color={"primary"}
                    className='mt-3'
                    isDisabled={!jobTitle}
                    fullWidth
                    onPress={() => setUserJobTitle()}
                >
                    {t('continue')}
                </Button>
            </div>
        </div>
    )
}

export default WebIntroJobTitle