import React, { useCallback, useMemo } from "react";
import { Card, CardBody } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";

import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import WebEditor from "../../../atoms/WebEditor";
import WebHeader from "../../../molecules/views/WebHeader";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const WebOnlyEditor = ({ elType }) => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------
    const key = useMemo(() => elType === "Summary" ? "summary" : "coverLetter", [elType])
    const title = useMemo(() => elType === "Summary" ? "cvLabelSummary" : elType === "CoverLetter" ? "coverLetter" : `cvLabelSkills${elType}`, [elType])
    const isSkill = useMemo(() => !["Summary", "CoverLetter"].includes(elType), [elType])
    const cvPath = useMemo(() => isSkill ? `SkillsPassport.LearnerInfo.Skills[${elType}].Description` : `SkillsPassport.LearnerInfo.Identification.${elType}.Description`, [elType, isSkill])
    const sectionId = useMemo(() => {
        switch (elType) {
            case "Summary": return "SUMMARY";
            case "CoverLetter": return "COVER_LETTER";
            case "Communication": return "SKILL_COM";
            case "Organisational": return "SKILL_ORG";
            case "JobRelated": return "SKILL_PRO";
            case "Computer": return "SKILL_DIG";
            case "Other": return "SKILL_OTH";
        }
    }, [elType])

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])

    // ------------------------------------------------------------------------------------------------------
    return (
        <>
            <WebHeader title={t(title)} />

            <div className="p-5">
                <Card shadow={"none"}>
                    <CardBody>

                        <WebEditor
                            keyId={`toolbar${key}`}
                            elType={elType}
                            onChange={t => editCv(produce(cv, d => void _.set(d, cvPath, t === "<p><br></p>" ? "" : t)))}
                            value={
                                isSkill ?
                                    (cv.SkillsPassport.LearnerInfo.Skills[elType]?.Description) || "" :
                                    (cv.SkillsPassport.LearnerInfo.Identification[elType]?.Description) || ""
                            }

                        />

                        {elType === 'CoverLetter' && <p className="text-xs text-default-600 mt-2">{t('coverLetterEmailExp')}</p>}
                    </CardBody>
                </Card>

                <WebAIAssistantButton sectionId={sectionId} />
            </div>

        </>

    )
}

export default WebOnlyEditor