import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Button } from "@nextui-org/react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { doc, collection, setDoc } from "firebase/firestore";

import { base64FileToJson, createCvObject } from "cvpop-utility-sdk";
import { setCv } from "cvpop-redux-sdk";
import { lotties } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk";

import Lottie from 'react-lottie';

import { createFbId, errorHandler, fileToBase64 } from '../../../controllers/app';
import { db, auth, functions } from '../../../controllers/firebase';

import WebIcon from '../../atoms/WebIcon';


const WebIntroSpinner = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()
    const { cvFile } = useLocation().state || {}

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------
    const pdfApi = useMemo(() => httpsCallable(functions, 'createcvfrompdf', { timeout: 180000 }), []);
    const docApi = useMemo(() => httpsCallable(functions, 'createcvfromdocx', { timeout: 180000 }), []);

    const deltaDivider = useMemo(() => !!cvFile ? 15 : 5, [cvFile])
    const maxProgress = useMemo(() => !!cvFile ? 95 : 100, [cvFile])

    // ------------------------------------------------------------------------------------------------------
    const [progress, setProgress] = useState(0)

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!cvFile) return

        fileToBase64(cvFile)
            .then(base64Data => base64FileToJson(user, base64Data.split(",")[1], cvFile.type, cvFile.type === "application/pdf" ? pdfApi : docApi, createFbId, atob))
            .then(({ importedCv }) => {
                const ref = doc(collection(db, "00_RESUMES_00"));
                const cv = createCvObject(fbuser, user, ref.id, importedCv, "createdByImport");
                d(setCv({ cv }))
                return setDoc(doc(db, "00_RESUMES_00", ref.id), cv)
            })
            .then(() => setProgress(100))
            .catch(e => {
                errorHandler("err_filetojson_onboarding", e)
                n(-1)
            })

    }, [user, fbuser, cvFile, pdfApi, docApi, d, n])

    useEffect(() => {
        if (progress >= 100) {
            setTimeout(() => n(`/app/${platform}/onboarding/features`), 500)
            return
        }

        const interval = setInterval(() => {
            const delta = Math.random() / deltaDivider;
            setProgress((progress + delta) > maxProgress ? maxProgress : progress + delta)
        }, 10);

        return () => clearInterval(interval);
    }, [progress, deltaDivider, maxProgress, n])

    // ------------------------------------------------------------------------------------------------------
    return (

        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                {
                    platform !== "web" && (
                        <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }
                <CardBody>
                    <div className="max-w-80 self-center">
                        <Lottie options={{ animationData: lotties.configure }} autoPlay loop style={{ cursor: "default" }} />
                    </div>
                    <p className="font-sans font-bold text-2xl text-center mt-3">{t("personalizingYourExperience")}</p>
                    <p className="font-sans text-center mt-3">{t("introSpinnerMsg")}</p>

                    {
                        progress < 100 ? (
                            <div className='mt-3 rounded-xl text-center bg-default'>
                                <div className='py-2 rounded-xl text-small truncate bg-primary text-white' style={{ width: `${progress}%` }}>
                                    <span>{progress < 100 ? `${parseInt(progress)}%` : t("continue")}</span>
                                </div>
                            </div>
                        ) : (
                            <Button
                                color="primary"
                                className='mt-3'
                                onClick={() => progress >= 100 && n(`/app/${platform}/onboarding/features`)}
                            >
                                {t("continue")}
                            </Button>
                        )
                    }


                </CardBody>
            </Card>
        </div>



    )
}

export default WebIntroSpinner