import { useEffect } from 'react';
import { Outlet } from "react-router-dom";

import WebNavbar from '../../atoms/WebNavbar';
import WebHomeSidebar from '../../organisms/sidebars/WebHomeSidebar';


const WebHome = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (window.webkit?.messageHandlers["askForRate"]) window.webkit.messageHandlers["askForRate"].postMessage("inapp");
    }, [])


    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className="min-h-screen">

            <WebNavbar />

            <div className={"flex"}>
                <WebHomeSidebar />
                <div className="w-full ml-72">
                    <Outlet />
                </div>
            </div>

        </div>
    )
};

export default WebHome;