const { t } = require("cvpop-localization-sdk")

const { userHasCap } = require("../../user")
const { dateToString } = require("../../script")

module.exports = {
    footer: (cv = {}, user = {}) => {

        const { modelBaseColor = "#247BA0", lng, hideCvDate, hideCvPagination } = cv.Metadata

        const locale = lng === 'nb' ? 'no' : lng;
        const copyright = userHasCap(user, 'WATERMARK') ?
            `<a>${t('cvLabelFooterCv', { locale })}</a>` :
            `<span style="color: ${modelBaseColor};">${t('cvLabelFooterCopyright', { locale })} - <a style="color: ${modelBaseColor};" href="https://cvpop.com/" target="_blank">www.cvpop.com</a></span>`


        const dateObj = new Date();
        const footerDate = { Year: dateObj.getUTCFullYear(), Month: dateObj.getUTCMonth() + 1, Day: dateObj.getUTCDate() }
        const footerText = hideCvDate ? "&nbsp;" : `${hideCvDate ? "" : dateToString(cv, footerDate, "CV_DATE", locale)}`;
        const footerPagination = hideCvPagination ? "&nbsp;" : ` ${t("cvLabelFooterPage", { locale })} <span class='pageNumber'></span>/<span class='totalPages'></span>`;

        return `
        <div style="height: 30px; width: 100%; display: inline-block; margin-top: 20px; text-align: center; font-size: 7px; color: ${modelBaseColor}">
            <div style="line-height: 30px; height: 30px; width: 20%; display: inline-block; margin-top: 10px;">
                ${footerText}
            </div>
            <div style="line-height: 30px; height: 30px; width: 58%; display: inline-block;margin-top: 10px;">
                ${copyright}
            </div>
            <div style="line-height: 30px; height: 30px;width: 20%; display: inline-block;margin-top: 10px;">
                ${footerPagination}
            </div>
        </div>
        `;

    }
}