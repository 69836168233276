import React, { useCallback, useMemo } from "react";
import { Card, CardBody, Listbox, ListboxItem, Button } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";
import { httpsCallable } from "firebase/functions";

import { illustrations } from "cvpop-assets-sdk";
import { getEnglishLabelFromLanguageCode, isProUser, jsonToSimpleJson } from "cvpop-utility-sdk";
import { setCv, setSpin } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { functions } from "../../../controllers/firebase";
import { errorHandler, goToPaywall } from "../../../controllers/app";

import WebIcon from "../../atoms/WebIcon"
import WebCenterView from "../../molecules/views/WebCenterView";
import WebHeader from "../../molecules/views/WebHeader";
import WebListItemHeaderView from "../../molecules/views/WebListItemHeaderView";


const WebCVScan = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------
    const { rating } = cv.Metadata || {};

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'createscan', { timeout: 120000 }), []);
    const isPro = useMemo(() => isProUser(user), [user])
    const docAdviseList = useMemo(() => !rating ? [] : [].concat([], ..._.map(rating.documentAdvise, (e, k) => _.map(e, subEl => ({ ...subEl, k })))), [rating])
    const secAdviseList = useMemo(() => !rating ? [] : [].concat([], ..._.map(rating.sectionAdvise, (e, k) => _.map(e, subEl => ({ ...subEl, k })))), [rating])

    // ------------------------------------------------------------------------------------------------------
    const getRating = useCallback(async () => {

        d(setSpin(true))
        api({ cv: jsonToSimpleJson(cv), targetLng: getEnglishLabelFromLanguageCode(user.language) })
            .then(({ data }) => d(setCv({ cv: produce(cv, d => { d.Metadata.rating = { createdAt: new Date().getTime(), ...data } }) })))
            .catch(e => errorHandler("err_request_analysis", e))
            .finally(() => d(setSpin(false)))

    }, [cv, user]);


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvScan")} />
            <div className="p-5">

                {
                    !isPro || !rating ? (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations.rating }}
                                title={t("featScan")}
                                message={t("scanCvMsg")}
                            >
                                <Button
                                    color="primary"
                                    className="mt-6"
                                    onPress={() => !isPro ? goToPaywall(offering, platform, n) : getRating()}
                                    endContent={!isPro && (
                                        <div className='w-6 h-6 flex justify-center items-center shadow-2xl rounded-full'>
                                            <WebIcon name={"workspace_premium"} className={"filled text-yellow-400"}/>
                                        </div>
                                    )}
                                >
                                    {t(!isPro ? "unlock" : "makeScan")}
                                </Button>
                            </WebCenterView>
                        </div>
                    ) : (
                        <div>
                            <Card shadow={"none"}>
                                <CardBody>
                                    <p className="text-2xl font-bold text-center">{rating.generalScore} / 10</p>
                                    <p className="text-xl font-bold text-center">{t("overallRating")}</p>
                                </CardBody>
                            </Card>
                            <div className="grid grid-cols-3 gap-4 mt-4">
                                <Card shadow={"none"}>
                                    <CardBody>
                                        <p className="text-center">{rating.contentScore} / 10</p>
                                        <p className="text-center">{t("content")}</p>
                                    </CardBody>
                                </Card>
                                <Card shadow={"none"}>
                                    <CardBody>
                                        <p className="text-center">{rating.grammarScore} / 10</p>
                                        <p className="text-center">{t("grammar")}</p>
                                    </CardBody>
                                </Card>
                                <Card shadow={"none"}>
                                    <CardBody>
                                        <p className="text-center">{rating.lengthScore} / 10</p>
                                        <p className="text-center">{t("length")}</p>
                                    </CardBody>
                                </Card>
                            </div>

                            <WebListItemHeaderView text={t('document')} />
                            <Card shadow={"none"}>
                                <CardBody>
                                    <Listbox aria-label="Actions">
                                        {docAdviseList.map((e, i) => (
                                            <ListboxItem
                                                key={`documentAdvise${i}`}
                                                variant={"flat"}
                                                classNames={{ title: "whitespace-normal" }}
                                                title={e.advise}
                                                startContent={(
                                                    <WebIcon
                                                        name={e.type === "GOOD" ? "check" : e.type === "WARNING" ? "warning" : "report"}
                                                        className={`filled text-${e.type === "GOOD" ? "success" : e.type === "WARNING" ? "warning" : "danger"}`}
                                                    />
                                                )}
                                            />
                                        ))}
                                    </Listbox>
                                </CardBody>
                            </Card>

                            <WebListItemHeaderView text={t('sections')} />
                            <Card shadow={"none"}>
                                <CardBody>
                                    <Listbox aria-label="Actions" >
                                        {secAdviseList.map((e, i) => (
                                            <ListboxItem
                                                key={`sectionAdvise${i}`}
                                                classNames={{ title: "whitespace-normal" }}
                                                variant={"flat"}
                                                title={e.advise}
                                                onPress={e.type !== "GOOD" && e.k !== "skillsAdviseList" ? () => {
                                                    const path = e.k === "workExperienceAdviseList" ? "work-experience" :
                                                        e.k === "educationAdviseList" ? "education" :
                                                            e.k === "abilityAdviseList" ? "abilities" :
                                                                "keywords"
                                                    n(`/app/${platform}/cv/edit/${path}`)
                                                } : null}
                                                startContent={(
                                                    <WebIcon
                                                        name={e.type === "GOOD" ? "check" : e.type === "WARNING" ? "warning" : "report"}
                                                        className={`filled text-${e.type === "GOOD" ? "success" : e.type === "WARNING" ? "warning" : "danger"}`}
                                                    />
                                                )}
                                                endContent={!(e.type === "GOOD" || e.k === "skillsAdviseList") && (<WebIcon name={"chevron_right"} className={"text-default-400"} />)}
                                            />
                                        ))}
                                    </Listbox>
                                </CardBody>
                            </Card>

                            <Button
                                fullWidth
                                color="primary"
                                className="mt-4"
                                onPress={() => getRating()}
                            >
                                {t("makeNewScan")}
                            </Button>

                        </div>
                    )
                }

            </div>

        </div>
    )
}

export default WebCVScan