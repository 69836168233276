const { sectionTitle } = require("./section-title")
const { sectionSubtitle } = require("./section-subtitle")
const { sectionPeriod } = require("./section-period")
const { sectionDescription } = require("./section-description")

const { formatAddress } = require("../../script")

module.exports = {
    education: cv => {

        const { lng } = cv.Metadata
        const { Education = [] } = cv.SkillsPassport.LearnerInfo

        if (Education.length === 0) return ""

        return `
        <div class="education-container container">
            ${sectionTitle(cv, "cvLabelEducation", "Education", "fas fa-graduation-cap")}
            ${Education.map(({ Title, Organisation, Period, Activities }) => `
                <div class="education-content content">
                    <div class="education-institute">
                        <div class="education-instatitute-name">
                            ${Title ? sectionSubtitle("education", Title) : ""}
                            ${Organisation.Name ? `
                                <p class="organisation-name ptXS">
                                    ${Organisation.Name} ${formatAddress(Organisation.ContactInfo.Address.Contact, lng) ? `<span class="organisation-address">| ${formatAddress(Organisation.ContactInfo.Address.Contact, lng)}</span>` : ""}
                                </p>
                            ` : ""}
                        </div>
                        <div class="education-instatitute-period">
                            ${sectionPeriod(cv, Period, "education")}
                        </div>
                    </div>

                    <div class="section-with-logo">
                        ${Activities ? sectionDescription(Activities) : ""}
                        ${Organisation.Logo ? `
                            <div class="logo-container">
                                <div class="logo" style="background-image: url(data:image/png;base64,${Organisation.Logo.Data})"></div>
                            </div>
                        `: ""}
                    </div>
                </div>
            `).join("")}
        </div>
        `
    }
}
