import { useEffect } from "react"
import { Link } from "@nextui-org/react";

const WebLinkedinRedirect = () => {

    useEffect(() => {
        window.location.replace("https://www.linkedin.com/in/");
    }, [])

    return (
        <div className="flex items-center justify-center h-screen">
            <p>If you are not automatically redirected, <Link href="https://www.linkedin.com/in/">click here</Link>.</p>
        </div>
    )
}

export default WebLinkedinRedirect