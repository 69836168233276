import { useMemo, useRef, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Card, CardBody } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { produce } from "immer"
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

import { setCv } from "cvpop-redux-sdk";
import { illustrations, icons } from "cvpop-assets-sdk";
import { isProUser } from "cvpop-utility-sdk";
import { t } from "cvpop-localization-sdk"

import * as mime from 'mime-db';

import { confirmDelete, createFbId, errorHandler, goToPaywall, openUrl, showAlert } from "../../../../controllers/app";
import { auth, functions, storage } from "../../../../controllers/firebase";
import { useColorScheme } from "../../../../controllers/hooks";

import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const mymeSupported = [
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation"
]

const WebAttachment = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const inputRef = useRef()
    const fbuser = auth.currentUser
    const uid = fbuser.uid

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)
    const [selItemInd, setSelItemInd] = useState(-1)

    // ------------------------------------------------------------------------------------------------------
    const list = useMemo(() => (cv.SkillsPassport.LearnerInfo.Attachment || []), [cv])
    const api = useMemo(() => httpsCallable(functions, 'createqrcode'), []);
    const isPro = useMemo(() => isProUser(user), [user])

    // ------------------------------------------------------------------------------------------------------
    const addAttacment = useCallback(async f => {

        if (!mymeSupported.includes(f.type)) return showAlert(t("errTitle"), t("notSupportedTypeFile"))
        if (f.size > 10485760) return showAlert(t("errTitle"), t("attachDimFileExceed"))
        if (list.find(e => e.filename === f.name)) return showAlert(t('errTitle'), t('attachFileAlreadyExist')) // avoind same names

        try {

            setLoading(true)
            const newAttachment = await uploadAttachment(f)

            d(setCv({
                cv: produce(cv, d => {
                    !d.SkillsPassport.LearnerInfo.Attachment ?
                        d.SkillsPassport.LearnerInfo.Attachment = [newAttachment] :
                        d.SkillsPassport.LearnerInfo.Attachment.push(newAttachment)
                })
            }))
            setLoading(false)

        } catch (e) {
            setLoading(false)
            errorHandler("err_add_attacchment", e)
        }

    }, [uid, cv])

    const uploadAttachment = useCallback(async f => {

        let url, qrCodeData;

        const id = createFbId()
        const stRef = ref(storage, `attachments/${uid}/${cv.Metadata.id}/${f.name}`);

        try {
            const snap = await uploadBytes(stRef, f)
            url = await getDownloadURL(snap.ref)
            qrCodeData = await api({ url })
        } catch (e) {
            deleteObject(stRef).catch(e => errorHandler("err_delete_attachment", e, true))
            return Promise.reject(e)
        }

        return {
            id, url,
            qrCodeData: qrCodeData.data,
            createdAt: new Date().getTime(),
            filename: f.name,
            filesize: f.size,
            filetype: f.type
        }

    }, [uid, cv])


    const deleteAttachment = useCallback(id => {

        const item = list.find(e => e.id === id)

        setLoading(true)
        deleteObject(ref(storage, `attachments/${uid}/${cv.Metadata.id}/${item.filename}`))
            .then(() => d(setCv({
                cv: produce(cv, d => {
                    const index = d.SkillsPassport.LearnerInfo.Attachment.findIndex(e => e.id === item.id)
                    d.SkillsPassport.LearnerInfo.Attachment.splice(index, 1)
                })
            })))
            .catch(e => errorHandler("err_delete_attachment", e))
            .finally(() => setLoading(false))

    }, [cv, list])

    // ------------------------------------------------------------------------------------------------------ 
    return (
        <div>
            <WebHeader title={t("cvLabelAttachment")} />
            <div className="p-5">

                {
                    (list.length === 0 || !isPro) && (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations.attachment }}
                                title={t(!isPro ? "featAttachment" : `cvLabelAttachment`)}
                                message={t(!isPro ? "attachmentMsg" : "emptyListMsg")}
                            >
                                <Button
                                    color="primary"
                                    className="mt-6"
                                    isLoading={loading}
                                    onPress={() => {
                                        if (!isPro) return goToPaywall(offering, platform, n)
                                        if (!inputRef.current) return
                                        inputRef.current.value = ""
                                        inputRef.current.click()
                                    }}
                                    endContent={!isPro && (
                                        <div className='w-6 h-6 flex justify-center items-center shadow-2xl rounded-full'>
                                            <span className="material-symbols-outlined filled text-yellow-400">
                                                workspace_premium
                                            </span>
                                        </div>
                                    )}
                                >
                                    {t(!isPro ? "unlock" : 'add')}
                                </Button>

                                <WebAIAssistantButton color={"secondary"} sectionId={"ATTACHMENT"} />
                            </WebCenterView>
                        </div>
                    )
                }


                <div className="grid grid-cols-2 gap-4">
                    {list.map(({ id, filetype, filename, url }, i) => (

                        <Dropdown
                            key={id}
                            backdrop="blur"
                            className={`${cs} text-foreground`}
                        >
                            <DropdownTrigger>
                                <Card>
                                    <CardBody>
                                        <img src={icons[`filetype${mime[filetype].extensions[0] || ""}`]} className="w-full object-contain h-[100px]" />
                                        <p className="mx-3 text-xs text-center truncate">{filename}</p>
                                    </CardBody>
                                </Card>
                            </DropdownTrigger>
                            <DropdownMenu
                                color={"primary"}
                                aria-label="Static Actions"
                                onAction={k => {
                                    if (k === "delete") return confirmDelete({ cb: () => deleteAttachment(id) })
                                    if (k === "open") return openUrl(url, platform)
                                    if (k === "qr") {
                                        setSelItemInd(i)
                                        return onOpen()
                                    }
                                }}
                            >
                                <DropdownItem key="open" title={t("open")} endContent={<WebIcon name={"open_in_new"} />} />
                                <DropdownItem key="qr" title={t("showQrCode")} endContent={<WebIcon name={"qr_code_2"} />} />
                                <DropdownItem key="delete" title={t("delete")} className="text-danger" color="danger" endContent={<WebIcon name={"delete"} />} />
                            </DropdownMenu>
                        </Dropdown>

                    ))}
                </div>

                <input
                    type="file"
                    className='hidden'
                    accept={mymeSupported.join(",")}
                    ref={inputRef}
                    onChange={async ({ target }) => target.files.length > 0 && addAttacment(target.files[0])}
                />

                {
                    (list.length > 0 && list.length < 4) && (
                        <Button
                            fullWidth
                            isLoading={loading}
                            color={"primary"}
                            className="mt-3"
                            onPress={() => inputRef.current && inputRef.current.click()}
                        >
                            {t("add")}
                        </Button>
                    )
                }

                <WebAIAssistantButton color={"secondary"} sectionId={"ATTACHMENT"} />
            </div>

            <Modal isOpen={isOpen} hideCloseButton onOpenChange={onOpenChange} backdrop={"blur"} className={`${cs} text-foreground`}>
                <ModalContent>
                    {
                        selItemInd >= 0 && (
                            <>
                                <ModalHeader>{list[selItemInd]?.filename}</ModalHeader>
                                <ModalBody>
                                    <img alt={t("qrCode")} src={`data:$image/png;base64,${list[selItemInd]?.qrCodeData}`} />
                                </ModalBody>
                            </>
                        )
                    }
                    <ModalFooter>
                        <Button color={"primary"} onPress={() => onClose()}>
                            {t("close")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </div>

    )
}

export default WebAttachment