import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { CardBody, Card, Select, SelectItem } from '@nextui-org/react';
import { produce } from "immer"
import { setCv } from "cvpop-redux-sdk";
import { cvPickerItems } from "cvpop-constants-sdk";
import { t } from "cvpop-localization-sdk";

import _ from "lodash";

import WebHeader from '../../../molecules/views/WebHeader';
import WebListItemHeaderView from '../../../molecules/views/WebListItemHeaderView';
import { useColorScheme } from '../../../../controllers/hooks';


const WebDateFormat = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const { Metadata } = cv

    // ------------------------------------------------------------------------------------------------------
    const opts = useMemo(() => ["Birth", "Education", "Work", "Cv"], [])
    const viewTypeItems = useMemo(() => cvPickerItems.viewDate(), [])
    const formatTypeItems = useMemo(() => cvPickerItems.formatDate(), [])


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t('dateFormat')} />
            <div className="mx-5 mb-5">
                {
                    opts.map((e, i) => (
                        <div key={`view${e}`}>
                            <WebListItemHeaderView text={t(`date${e}`)} />
                            <Card>
                                <CardBody>
                                    <Select
                                        label={t("viewType")}
                                        defaultSelectedKeys={[Metadata[`view${e}Date`] || ""]}
                                        disallowEmptySelection={true}
                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                        onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata[`view${e}Date`] = target.value }) }))}

                                    >
                                        {viewTypeItems.map(({ code, label }) => (<SelectItem key={code} value={code}>{t(label)}</SelectItem>))}
                                    </Select>

                                    <Select
                                        label={t("formatType")}
                                        defaultSelectedKeys={[Metadata[`format${e}Date`] || ""]}
                                        disallowEmptySelection={true}
                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                        onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata[`format${e}Date`] = target.value }) }))}
                                        className="mt-4"
                                    >
                                        {formatTypeItems.map(({ code, label }) => (<SelectItem key={code} value={code}>{t(label)}</SelectItem>))}
                                    </Select>

                                </CardBody>
                            </Card>
                        </div>

                    ))
                }
            </div>

        </div>
    )
}

export default WebDateFormat