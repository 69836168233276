import React, { useMemo, useState } from 'react';
import { Card, CardBody, Button, Pagination } from "@nextui-org/react"
import { useNavigate, useParams } from "react-router-dom";

import { illustrations } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk";

import { auth } from "../../../controllers/firebase"

const WebIntroFeatures = () => {

    // ------------------------------------------------------------------------------------------------------
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const fbuser = auth.currentUser;

    // ------------------------------------------------------------------------------------------------------
    const [page, setPage] = useState(1)

    // ------------------------------------------------------------------------------------------------------
    const featList = useMemo(() => ["Confidence", "Customization", "Rating", "Standout", "Hired"], [])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                <CardBody>
                    <h1 className='text-3xl m-3 font-bold'>
                        {fbuser.displayName}, {t("introFeatTitle")}
                    </h1>
                    {featList.map((e, i) => (
                        <div key={`feature${e}`} className={`max-w-80 self-center ${i !== page - 1 ? "hidden" : ""}`}>
                            <div>
                                <img
                                    alt={t("introFeatTitle")}
                                    src={illustrations[e.toLowerCase()]}
                                    className={`rounded-xl object-contain`}
                                    style={{ minHeight: 320 }}
                                />
                                <p className="font-sans text-center mt-3">{t(`intro${e}Feat`)}</p>
                            </div>
                        </div>
                    ))}

                    <Pagination showControls total={5} initialPage={1} className='mt-3 m-auto' onChange={p => setPage(p)} />

                    <Button
                        className='mt-3'
                        color='primary'
                        fullWidth
                        onClick={() => {

                            if (window.webkit?.messageHandlers["askForRate"]) return n(`/app/${platform}/onboarding/feedback`)
                            if (platform === "macos" || platform === "visionos") return n(`/app/${platform}/onboarding/paywall`, { state: { onboarding: true } })
                            if (platform === "web") return n(`/app/${platform}/onboarding/convert`)
                        }}
                    >
                        {t("next")}
                    </Button>

                </CardBody>

            </Card>
        </div>
    )
}

export default WebIntroFeatures