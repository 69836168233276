import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardFooter, Image } from "@nextui-org/react";

import { illustrations } from "cvpop-assets-sdk";
import { t } from "cvpop-localization-sdk"
import { setCvList } from "cvpop-redux-sdk";

import { errorHandler } from "../../../controllers/app";
import { auth, db } from "../../../controllers/firebase"

import WebAddCVButton from "../../molecules/buttons/WebAddCVButton";
import WebSpinnerView from "../../molecules/views/WebSpinnerView";
import WebErrorView from "../../molecules/views/WebErrorView";
import WebCenterView from "../../molecules/views/WebCenterView";
import WebCVCard from "../../molecules/cards/WebCVCard";


const WebCvList = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cvList } = useSelector(state => state.cvReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------------------------
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [retry, setRetry] = useState(false);

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        setLoading(true);
        setError(false)
        const q = query(collection(db, "00_RESUMES_00"), where("Metadata.uid", "==", fbuser.uid), orderBy("Metadata.updatedAt", "desc"));
        const fbSub = onSnapshot(q, s => {
            d(setCvList(s.docs.map(e => e.data())))

            setLoading(false)
        }, e => {
            setError(true)
            errorHandler("err_fb_cv_snapshot", e, true)
        });

        return () => fbSub()
    }, [d, fbuser.uid, retry])


    // ------------------------------------------------------------------------------------------------------------------------
    return error ? (
        <div className="h-full flex items-center justify-center" style={{ minHeight: `calc(100vh - 4rem)` }}>
            <WebErrorView buttonProps={{ onClick: () => setRetry(!retry) }} />
        </div>
    ) : loading ? (
        <WebSpinnerView />
    ) : cvList.length === 0 ? (
        <div className="h-full flex items-center justify-center" style={{ minHeight: `calc(100vh - 4rem)` }}>
            <WebCenterView
                imageProps={{ src: illustrations.cv }}
                title={t("yourCvs")}
                message={t("landingTitle")}
            >
                <WebAddCVButton />
            </WebCenterView>
        </div>
    ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 p-8">
            {cvList.length > 0 && fbuser.isAnonymous && (
                <Card shadow="sm" isPressable onPress={() => n(`/app/${platform}/convert`)}>
                    <CardBody className="p-0">
                        <Image width="100%" radius="none" className="w-full object-cover h-[250px]" src={illustrations.warning} />
                    </CardBody>
                    <CardFooter className="text-small ">
                        <div className="flex flex-col text-left w-full">
                            <p className="font-bold text-left text-danger">{t('saveProgress')}</p>
                            <p className="text-xs font-light text-default-400">{t('saveProgressMsg')}</p>
                        </div>
                    </CardFooter>
                </Card>
            )}
            {cvList.map(cv => (
                <WebCVCard cv={cv} key={cv.Metadata.id} />
            ))}
            <WebAddCVButton type={"card"} />
        </div>
    )
}

export default WebCvList