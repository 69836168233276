const { sectionTitle } = require("./section-title")

module.exports = {
    portfolio: cv => {

        const { Portfolio = [] } = cv.SkillsPassport.LearnerInfo

        if (Portfolio.length === 0) return ""

        return `
            <div class="portfolio-container container">
                ${sectionTitle(cv, "cvLabelPortfolio", "Portfolio", "fas fa-images")}
                <div class="portfolio-content content">
                    ${Portfolio.map(({ MimeType, Data }) => `
                        <div class="portfolio-item" style="background-image: url(data:${MimeType};base64,${Data})"></div>
                    `).join("")}
                </div>
            </div>  
        `

    }
}
            