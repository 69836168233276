import { Button } from "@nextui-org/react";


import { illustrations } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

// import AppButton from "../../atoms/AppButton"
// import AppCenterScrollView from "../scrollviews/AppCenterScrollView"
import WebCenterView from "./WebCenterView"


const WebErrorView = ({ buttonProps }) => {

    // ------------------------------------------------------------------------------------------------------
    return (
        <WebCenterView
            imageProps={{ src: illustrations.error }}
            title={t("errTitle")}
            message={t("genericError")}
        >
            {
                !_.isEmpty(buttonProps) && (
                    <Button color={"primary"} className="mt-4" {...buttonProps}>
                        {buttonProps.text || t("retry")}
                    </Button>
                )
            }

        </WebCenterView>
    )
    // return (
    // <SafeAreaView style={[layout.flex]}>
    //     <AppCenterScrollView
    //         imageProps={{ source: illustrations.error }}
    //         title={t("errTitle")}
    //         message={t("genericError")}
    //     />
    //     {
    //         !_.isEmpty(buttonProps) && (
    //             <AppButton
    //                 text={t("retry")}
    //                 style={[margin.T20]}
    //                 {...buttonProps}
    //             />
    //         )
    //     }

    // </SafeAreaView>
    // )
}

export default WebErrorView