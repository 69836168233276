import { useCallback, useState } from "react"
import { Card, CardBody, Input, Listbox, ListboxItem, Select, SelectItem, Switch, Button } from "@nextui-org/react"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, Outlet } from "react-router";
import { getBoolean } from "@firebase/remote-config";
import { doc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { useMemo } from "react";
import { produce } from "immer"

import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"
import { cvPickerItems } from "cvpop-constants-sdk";
import { userHasCap } from "cvpop-utility-sdk";

import { auth, db, remoteConfig, storage } from "../../../../controllers/firebase";
import { confirmDelete, errorHandler, goToPaywall } from "../../../../controllers/app";

import WebHeader from "../../../molecules/views/WebHeader";
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView";
import { useColorScheme } from "../../../../controllers/hooks";


const WebCVSettings = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { cv } = useSelector(state => state.cvReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------------------------
    const fbuser = auth.currentUser;
    const n = useNavigate()
    const d = useDispatch();
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const met = useMemo(() => cv.Metadata, [cv])
    const languageItems = useMemo(() => cvPickerItems.labelLanguage(), [])
    const sizeItems = useMemo(() => cvPickerItems.pdfSize(), [])


    const sizeOn = useMemo(() => (getBoolean(remoteConfig, "compressCv")), [])

    const watCap = useMemo(() => userHasCap(user, "WATERMARK"), [user.plan, user.capability])
    const sizeCap = useMemo(() => userHasCap(user, "PDF_SIZE"), [user.plan, user.capability])

    // ------------------------------------------------------------------------------------------------------
    const runDeleteCv = useCallback(async () => {

        const { id, uid } = cv.Metadata

        setLoading(true)
        deleteDoc(doc(db, "00_RESUMES_00", id))
            .then(async () => {

                try {
                    const hasVideo = cv.SkillsPassport.LearnerInfo.Video;
                    if (hasVideo) await deleteObject(ref(storage, `videoResume/${uid}-${id}.mp4`))

                    const attachments = cv.SkillsPassport.LearnerInfo.Attachment || []
                    for (let f in attachments) await deleteObject(ref(storage, `attachments/${uid}/${id}/${attachments[f].filename}`))
                } catch (e) {
                    errorHandler("err_delete_cv_accessories", e, true)
                }

                n(`/app/${platform}/home/cv-list`)

            })
            .catch(e => errorHandler("err_delete_cv", e))
            .finally(() => setLoading(false))

    }, [cv])


    // ------------------------------------------------------------------------------------------------------------------------
    if (![`/app/${platform}/cv/settings/`, `/app/${platform}/cv/settings`].includes(pathname)) return <Outlet/>
    return (
        <div>
            <WebHeader title={t("cvSettings")} />
            <div className="mx-5 mb-5">

                <WebListItemHeaderView text={t('generals')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">

                        <Input
                            startContent={<span className="material-symbols-outlined">title</span>}
                            placeholder={t("name")}
                            defaultValue={met.name}
                            onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.name = target.value ? target.value : `${t("cv")} ${fbuser.displayName}`.trim() }) }))}
                        />
                        <Listbox
                            aria-label="Actions"
                            onAction={k => n(`/app/${platform}/cv/settings/${k}`)}
                            variant={"flat"}
                        >
                            <ListboxItem
                                key="date-format"
                                startContent={<span className="material-symbols-outlined">edit_calendar</span>}
                                endContent={<span className="material-symbols-outlined">chevron_right</span>}
                            >
                                {t('dateFormat')}
                            </ListboxItem>
                            <ListboxItem
                                key="sort-sections"
                                startContent={<span className="material-symbols-outlined">format_line_spacing</span>}
                                endContent={<span className="material-symbols-outlined">chevron_right</span>}
                            >
                                {t('sectionSort')}
                            </ListboxItem>
                        </Listbox>

                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('sectionTitles')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">

                        <Select
                            label={t("language")}
                            defaultSelectedKeys={[met.lng]}
                            disallowEmptySelection={true}
                            classNames={{ popoverContent: `${cs} text-foreground` }}
                            onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.lng = target.value }) }))}
                        >
                            {languageItems.map(({ code, label }) => (<SelectItem key={code} value={code}>{label}</SelectItem>))}
                        </Select>
                        <Listbox
                            aria-label="Actions"
                            onAction={k => n(`/app/${platform}/cv/settings/${k}`)}
                            variant={"flat"}
                        >
                            <ListboxItem
                                key="custom-titles"
                                startContent={<span className="material-symbols-outlined">abc</span>}
                                endContent={<span className="material-symbols-outlined">chevron_right</span>}
                            >
                                {t('customizeLabels')}
                            </ListboxItem>
                        </Listbox>

                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('export')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">

                        {sizeOn && sizeCap && (
                            <Select
                                label={t("featCompression")}
                                defaultSelectedKeys={[(met.pdfSize || "STANDARD")]}
                                disallowEmptySelection={true}
                                classNames={{ popoverContent: `${cs} text-foreground` }}
                                startContent={<span className="material-symbols-outlined">folder_zip</span>}
                                onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.pdfSize = target.value }) }))}
                                style={{ backgroundColor: "transparent", boxShadow: "none" }}
                            >
                                {sizeItems.map(({ code, label }) => (<SelectItem key={code} value={code}>{t(label)}</SelectItem>))}
                            </Select>
                        )}

                        <Listbox
                            aria-label="Actions"
                            variant={"flat"}
                        >

                            {sizeOn && !sizeCap && (
                                <ListboxItem
                                    key="compression"
                                    onClick={() => goToPaywall(offering, platform, n)}
                                    startContent={<span className="material-symbols-outlined">folder_zip</span>}
                                    endContent={<span className="material-symbols-outlined filled text-yellow-400">workspace_premium</span>}
                                >
                                    {t('featCompression')}
                                </ListboxItem>
                            )}

                            {
                                !watCap && (
                                    <ListboxItem
                                        key="watermark"
                                        onClick={() => goToPaywall(offering, platform, n)}
                                        startContent={<span className="material-symbols-outlined">branding_watermark</span>}
                                        endContent={<span className="material-symbols-outlined filled text-yellow-400">workspace_premium</span>}
                                    >
                                        {t('featWatermaker')}
                                    </ListboxItem>
                                )
                            }


                            <ListboxItem
                                key="hide-cv-date"
                                startContent={<span className="material-symbols-outlined">event_busy</span>}
                                endContent={(
                                    <Switch
                                        aria-label="Automatic updates"
                                        value={(met.hideCvDate)}
                                        onValueChange={v => d(setCv({ cv: produce(cv, d => { d.Metadata.hideCvDate = v }) }))}
                                    />
                                )}
                            >
                                {t('hideCvDate')}
                            </ListboxItem>

                            <ListboxItem
                                key="hide-pagination"
                                startContent={<span className="material-symbols-outlined">123</span>}
                                endContent={(
                                    <Switch
                                        aria-label="Automatic updates"
                                        value={(met.hideCvPagination)}
                                        onValueChange={v => d(setCv({ cv: produce(cv, d => { d.Metadata.hideCvPagination = v }) }))}
                                    />
                                )}
                            >
                                {t('hideCvPagination')}
                            </ListboxItem>
                        </Listbox>

                    </CardBody>
                </Card>

                <Button
                    fullWidth
                    color={"danger"}
                    className='mt-4'
                    isLoading={loading}
                    onPress={() => confirmDelete({ cb: () => runDeleteCv() })}
                >
                    {t('delete')}
                </Button>
            </div>
        </div>
    )
}

export default WebCVSettings