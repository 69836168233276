const { sectionTitle } = require("./section-title")

module.exports = {
    driverLicences: cv => {

        const { Description = [] } = cv.SkillsPassport.LearnerInfo.Skills.Driving || {}

        if (Description.length === 0) return ""

        return `
            <div class="drive-licence-container container">
                ${sectionTitle(cv, "cvLabelDriverLicence", "Driving", "fas fa-car-side")}
                <div class="drive-licence-content content">
                    <p class="drive-licence-text ptXS">
                        ${Description.map(title => title).join(", ")}
                    </p>
                </div>
            </div>
        `
    }
}