import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Button } from "@nextui-org/react"
import { useNavigate, useParams } from 'react-router-dom';
import { doc, deleteDoc } from "firebase/firestore";

import { illustrations } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk";

import { db } from '../../../controllers/firebase';

import { showAlert } from '../../../controllers/app';

import WebIcon from '../../atoms/WebIcon';


const WebIntroImportCV = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const inputRef = useRef()

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                {
                    platform !== "web" && (
                        <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }
                <CardBody>
                    <div className="max-w-80 self-center">
                        <img alt={t("introImportCvTitle")} src={illustrations.cv} />
                    </div>
                    <p className="font-sans font-bold text-2xl text-center mt-3">{t("introImportCvTitle")}</p>
                    <p className="font-sans text-center mt-3">{t("introImportCvMsg")}</p>

                    <Button
                        color="primary"
                        className='mt-3'
                        onClick={() => {
                            if (!inputRef.current) return
                            inputRef.current.value = ""
                            inputRef.current.click()
                        }}
                    >
                        {t("import")}
                    </Button>

                    <Button className='mt-3' color='primary' variant={"light"} onClick={() => n(`/app/${platform}/onboarding/spinner`)}>
                        {t("notYet")}
                    </Button>

                    <input
                        type="file"
                        className='hidden'
                        accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ref={inputRef}
                        onChange={({ target }) => {
                            if (target.files.length === 0) return
                            if (!["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(target.files[0].type))
                                return showAlert(t("errTitle"), t("notSupportedTypeFile"))

                            if (cv) deleteDoc(doc(db, "00_RESUMES_00", cv.Metadata.id));
                            n(`/app/${platform}/onboarding/spinner`, { state: { cvFile: target.files[0] } })
                        }}
                    />

                </CardBody>
            </Card>
        </div>
    )
}

export default WebIntroImportCV