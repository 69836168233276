module.exports = {
    styleStockholm: cv => {

        const { modelBaseColor = "#247BA0", modelTitleColor, lng } = cv.Metadata
        
        return `
            /* ----------------------------------------------- DOCUMENT */
            .header{
                padding: 40px;
                background-color: ${modelBaseColor};
            }
            .body{
                padding: 10px 40px 0px 40px;
            }
            .container{
                display: table;
                width: 650px;
                margin-top: 30px;
                margin-left: 30px;
                background-color: #e9e9e9;
            }
            .content{
                margin-bottom: 5px;
                padding-top: 5px;
                padding-right: 10px;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                display: table-cell;
                width: 180px;
                font-weight: bold;
                vertical-align: top;
            }
            .section-title-content{
                background-color: ${modelTitleColor || modelBaseColor};
                padding: 10px;
                margin-top: -20px;
                margin-left: -20px;
                margin-right: 20px;
            }
            .section-title{
                color: white;
                text-align: center;
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                background-color: transparent;
                ${lng === "ar" ? "padding-right: 30px" : "padding-left: 30px"}; 
            }
            .profile-pic {
                width: 120px;
                height: 170px;
                border: 3px solid white;
                border-radius: 0px !important;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                text-shadow: 0px 0px 5px black;
                background-color: transparent;

            }
            .name, .surname, .professional-title {
                color: white;
                font-weight: bold;
            }
            .professional-title{
                padding-top: 0px;
            }
            /* ----------------------------------------------- CONTAC INFO */
            .contact-info-tag-icon{
                color: black;
            }
            /* ----------------------------------------------- ABILITIES */
            .ability-level-single-bar-background, .ability-level-bars-background{
                background-color: white;
                border: 3px solid white;
            }
            /* ----------------------------------------------- PORTFOLIO */
            .portfolio-item {
                height: 150px;
                width: 150px;
                margin-right: 0;
            }
            /* ----------------------------------------------- ATTACHMENT */
            .attachment-item {
                height: 110px;
                width: 110px;
            }
        `

    }
}