import { colors, padding } from "cvpop-constants-sdk";

import _ from "lodash"
import Lottie from 'react-lottie';

import { useColorScheme } from "../../../controllers/hooks"


const WebCenterView = ({ testID, title, message, contentContainerStyle, children, ...props }) => {

    // ------------------------------------------------------------------------------------------------------
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const { lottieProps, imageProps } = props

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className="min-h-fit min-w-fit flex justify-center items-center flex-col">
            <div className="flex flex-col max-w-lg">
                {
                    lottieProps && (
                        <div
                            className="max-w-80 self-center"
                        >
                            <Lottie
                                options={{ ...lottieProps }}
                                autoPlay
                                loop
                                style={{ cursor: "default"}}

                             />
                        </div>

                    )
                }

                {
                    imageProps && (
                        <div
                            className="max-w-80 self-center"
                            style={imageProps.border && { ...padding.A5, borderStyle: "solid", borderWidth: 5, borderRadius: 30, borderColor: colors[cs].blue }}>
                            <img src={imageProps.src} className="max-w-80 self-center" style={imageProps.style} />
                        </div>
                    )
                }

                {
                    title && (
                        <p className="text-3xl font-bold text-center mt-6">
                            {title}
                        </p>
                    )
                }

                {
                    message && (
                        <p className="text-base text-center mt-6">
                            {message}
                        </p>
                    )
                }

                {
                    children && (children)
                }
            </div>
        </div>
    )
}

export default WebCenterView