const { styleAmsterdam } = require("./style-amsterdam")
const { styleAtene } = require("./style-atene")
const { styleBruxelles } = require("./style-bruxelles")
const { styleDublin } = require("./style-dublin")
const { styleHelsinki } = require("./style-helsinki")
const { styleIstanbul } = require("./style-istanbul")
const { styleLisbon } = require("./style-lisbon")
const { styleLondon } = require("./style-london")
const { styleMadrid } = require("./style-madrid")
const { styleMilan } = require("./style-milan")
const { styleMoscow } = require("./style-moscow")
const { styleNewYork } = require("./style-newyork")
const { styleParis } = require("./style-paris")
const { stylePechino } = require("./style-pechino")
const { styleRio } = require("./style-rio")
const { styleShanghai } = require("./style-shanghai")
const { styleStockholm } = require("./style-stockholm")
const { styleSydney } = require("./style-sydney")
const { styleTokyo } = require("./style-tokyo")

module.exports = {
    getTemplateCss: cv => {

        const { modelType = "london" } = cv.Metadata

        if (modelType === "amsterdam") return styleAmsterdam(cv)
        if (modelType === "atene") return styleAtene(cv)
        if (modelType === "bruxelles") return styleBruxelles(cv)
        if (modelType === "dublin") return styleDublin(cv)
        if (modelType === "helsinki") return styleHelsinki(cv)
        if (modelType === "istanbul") return styleIstanbul(cv)
        if (modelType === "lisbon") return styleLisbon(cv)
        if (modelType === "london") return styleLondon(cv)
        if (modelType === "madrid") return styleMadrid(cv)
        if (modelType === "milan") return styleMilan(cv)
        if (modelType === "moscow") return styleMoscow(cv)
        if (modelType === "newyork") return styleNewYork(cv)
        if (modelType === "paris") return styleParis(cv)
        if (modelType === "pechino") return stylePechino(cv)
        if (modelType === "rio") return styleRio(cv)
        if (modelType === "shanghai") return styleShanghai(cv)
        if (modelType === "stockholm") return styleStockholm(cv)
        if (modelType === "sydney") return styleSydney(cv)
        if (modelType === "tokyo") return styleTokyo(cv)
        return ""
    }
}