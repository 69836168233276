const { t } = require("cvpop-localization-sdk")

const { dateToString } = require("../../script")

module.exports = {
    sectionPeriod: (cv, value, type) => {

        if (!value) return ""

        const { lng } = cv.Metadata
        const { From, To, Current } = value

        const locale = lng === 'nb' ? 'no' : lng

        return `
        <div class="section-period-container ptXS">
            <p class="section-period-content">
                <span class="section-startdate">${dateToString(cv, From, type, locale)}</span>
                <span class="section-enddate"> - ${Current ? t("cvLabelInProgress", { locale }) : dateToString(cv, To, type, locale)}</span>
            </p>
        </div>
        `
    }
}