module.exports = {
    displayName: (cv = {}) => {

        const { modelType = "london" } = cv.Metadata
        const { PersonName = {}, ProfessionalTitle = "" } = cv.SkillsPassport.LearnerInfo.Identification

        if (["amsterdam", "atene", "dublin", "milan", "moscow", "shanghai", "stockholm"].includes(modelType)) return `
            <div class="name-container">
                <p class="name">${PersonName.FirstName}</p>
                <p class="surname">${PersonName.Surname}</p>
                <p class="professional-title">${ProfessionalTitle}</p>
            </div>`

        if (["lisbon"].includes(modelType)) return `
            <div class="name-container">
                <p class="name">${PersonName.FirstName} ${PersonName.Surname}</p>
                <p class="professional-title">${ProfessionalTitle}</p>
            </div>`

        if (["pechino"].includes(modelType)) return `
            <div class="name-container">
                <div class="name"><span>${PersonName.FirstName}</span></div>
                <div class="surname"><span>${PersonName.Surname}</span></div>
                <p class="professional-title"><span>${ProfessionalTitle}</span></p>
            </div>`

        return `
            <div class="name-container">
                <p class="name ${ProfessionalTitle ? "name-with-pro-title" : ""}">
                    ${PersonName.FirstName} ${PersonName.Surname}
                </p>
                ${ProfessionalTitle ? `<p class="professional-title">${ProfessionalTitle}</p>` : ""}
            </div>`
    }
}