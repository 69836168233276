const { languages } = require("cvpop-collections-sdk");
const { t } = require("cvpop-localization-sdk")

const _ = require("lodash")

const { sectionTitle } = require("./section-title")
const { sectionSubtitle } = require("./section-subtitle")

module.exports = {
    foreignLanguage: cv => {

        const { lng } = cv.Metadata
        const { ForeignLanguage = [] } = cv.SkillsPassport.LearnerInfo.Skills.Linguistic

        const locale = lng === 'nb' ? 'no' : lng

        if (ForeignLanguage.length === 0) return ""
        return `
        <div class="foreign-lng-container container">
            ${sectionTitle(cv, "cvLabelForeignLanguage", "ForeignLanguage", "fas fa-language")}

            ${ForeignLanguage.map(({ Description = {}, ProficiencyLevel = {}, Certificate = [] }) => `
                <div class="foreign-lng-content content">

                    ${Description.Code ? sectionSubtitle("languages", (languages[locale].find(v => v.code === Description.Code) || { label: Description.Code }).label) : ""}

                    ${_.some([ProficiencyLevel.Listening, ProficiencyLevel.Reading, ProficiencyLevel.SpokenInteraction, ProficiencyLevel.SpokenProduction, ProficiencyLevel.Writing]) ? `
                        <div class="foreign-lng-level-container ptXS">
                            ${ProficiencyLevel.Listening ? `
                                <p class="foreign-lng-level">
                                    <span class="foreign-lng-level-label">${t("cvLabelForeignLanguageListening", { locale })}:</span> 
                                    <span class="foreign-lng-level-content">${ProficiencyLevel.Listening}</span> 
                                </p>
                            ` : ""}

                            ${ProficiencyLevel.Reading ? `
                                <p class="foreign-lng-level">
                                    <span class="foreign-lng-level-label">${t("cvLabelForeignLanguageReading", { locale })}:</span> 
                                    <span class="foreign-lng-level-content">${ProficiencyLevel.Reading}</span> 
                                </p>
                            ` : ""}

                            ${ProficiencyLevel.SpokenInteraction ? `
                                <p class="foreign-lng-level">
                                    <span class="foreign-lng-level-label">${t("cvLabelForeignLanguageSpokenInteraction", { locale })}:</span> 
                                    <span class="foreign-lng-level-content">${ProficiencyLevel.SpokenInteraction}</span> 
                                </p>
                            ` : ""}

                            ${ProficiencyLevel.SpokenProduction ? `
                                <p class="foreign-lng-level">
                                    <span class="foreign-lng-level-label">${t("cvLabelForeignLanguageSpokenProduction", { locale })}:</span> 
                                    <span class="foreign-lng-level-content">${ProficiencyLevel.SpokenProduction}</span> 
                                </p>
                            ` : ""}

                            ${ProficiencyLevel.Writing ? `
                                <p class="foreign-lng-level">
                                    <span class="foreign-lng-level-label">${t("cvLabelForeignLanguageWriting", { locale })}:</span> 
                                    <span class="foreign-lng-level-content">${ProficiencyLevel.Writing}</span> 
                                </p>
                            ` : ""}

                        </div>
                    ` : ""}

                    ${Certificate.length > 0 ? `
                        <p class="certificate-container ptXS">
                            <span class="certificate-label">${t("certificates", { locale })}:</span>
                            <span class="certificate-content">
                                ${Certificate.map(({ Title }) => Title).join(", ")}
                            </span>
                        </p>
                    ` : ""}
                    
                </div>
            `).join("")}
        </div>
        `

    }
}