import { createSlice } from '@reduxjs/toolkit'

const cvSlice = createSlice({
    name: 'cv',
    initialState: {
        cv: null,
        cvList: [],
        cvLocalList: {},
        cvItmLogoWebImg: null,
        cvBgWebImg: null
    },
    reducers: {
        setCv(state, action) {
            state.cv = action.payload.cv || null
        },
        setCvList(state, action) {
            state.cvList = action.payload || []
        },
        setCvLocalList(state, action) {
            state.cvLocalList = action.payload || {}
        },
        setCvItmLogoWebImg(state, action) {
            state.cvItmLogoWebImg = action.payload || null
        },
        setCvBgWebImg(state, action) {
            state.cvBgWebImg = action.payload || null
        },
    }
})

export default cvSlice