import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import { configureStore } from '@reduxjs/toolkit'
import { NextUIProvider } from "@nextui-org/react";
import { PersistGate } from 'redux-persist/integration/react';
import { combineReducers } from "redux";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { Provider } from 'react-redux';

import { userReducer, cvReducer, appReducer, newsReducer, webReducer, courseReducer, jobReducer, bookReducer } from 'cvpop-redux-sdk';
import { supportedWebPlatforms } from "cvpop-constants-sdk";

import storage from 'redux-persist/lib/storage'

import { getCvMiddleware, getUserMiddleware } from './controllers/listeners';

import WebSign from './components/pages/WebSign';
import WebPaywall from './components/pages/WebPaywall';

import WebIntro from './components/pages/onboarding/WebIntro';
import WebIntroDisplayName from './components/pages/onboarding/WebIntroDisplayName';
import WebIntroJobTitle from './components/pages/onboarding/WebIntroJobTitle';
import WebIntroAvatar from './components/pages/onboarding/WebIntroAvatar';
import WebIntroSeniority from './components/pages/onboarding/WebIntroSeniority';
import WebIntroGoal from './components/pages/onboarding/WebIntroGoal';
import WebIntroTiming from './components/pages/onboarding/WebIntroTiming';
import WebIntroPersonality from './components/pages/onboarding/WebIntroPersonality';
import WebIntroLocation from './components/pages/onboarding/WebIntroLocation';
import WebIntroImportCV from './components/pages/onboarding/WebIntroImportCV';
import WebIntroSpinner from './components/pages/onboarding/WebIntroSpinner';
import WebIntroFeatures from './components/pages/onboarding/WebIntroFeatures';
import WebIntroFeedback from "./components/pages/onboarding/WebIntroFeedback";

import WebHome from './components/pages/home/WebHome';
import WebAccount from './components/pages/home/WebAccount';
import WebCvList from './components/pages/home/WebCvList';
import WebServices from "./components/pages/home/WebServices";
import WebSalary from "./components/pages/home/WebSalary";
import WebNewsContainer from "./components/pages/home/news/WebNewsContainer";
import WebNewsDetail from "./components/pages/home/news/WebNewsDetail";
import WebCourseContainer from "./components/pages/home/course/WebCourseContainer";
import WebCourseDetail from "./components/pages/home/course/WebCourseDetail";
import WebJobContainer from "./components/pages/home/job/WebJobContainer";
import WebJobDetail from "./components/pages/home/job/WebJobDetail";
import WebBookContainer from "./components/pages/home/book/WebBookContainer";
import WebBookDetail from "./components/pages/home/book/WebBookDetail";

import WebCVOverview from './components/pages/cv/WebCVOverview';
import WebCVStyle from './components/pages/cv/style/WebCVStyle';
import WebCVScan from './components/pages/cv/WebCVScan';
import WebCVPublicLink from "./components/pages/cv/WebCVPublicLink";

import WebCVEdit from './components/pages/cv/edit/WebCVEdit';
import WebPersonalInfo from './components/pages/cv/edit/WebPersonalInfo';
import WebPhoto from './components/pages/cv/edit/WebPhoto';
import WebContactInfo from './components/pages/cv/edit/WebContactInfo';
import WebOnlyEditor from './components/pages/cv/edit/WebOnlyEditor';
import WebFoundamentalItem from './components/pages/cv/edit/WebFoundamentalItem';
import WebDriverLicense from './components/pages/cv/edit/WebDriverLicense';
import WebDriverLicenseSpecial from './components/pages/cv/edit/WebDriverLicenseSpecial';
import WebMotherTongue from './components/pages/cv/edit/WebMotherTongue';
import WebForeignLanguage from './components/pages/cv/edit/WebForeignLanguage';
import WebHeadline from './components/pages/cv/edit/WebHeadline';
import WebKeyword from './components/pages/cv/edit/WebKeyword';
import WebAbility from './components/pages/cv/edit/WebAbility';
import WebPortfolio from './components/pages/cv/edit/WebPortfolio';
import WebAchievement from './components/pages/cv/edit/WebAchievement';
import WebAttachment from './components/pages/cv/edit/WebAttachment';
import WebSignature from './components/pages/cv/edit/WebSignature';
import WebVideo from './components/pages/cv/edit/WebVideo';

import WebModel from './components/pages/cv/style/WebModel';
import WebPhotFormat from './components/pages/cv/style/WebPhotoFormat';
import WebTexture from './components/pages/cv/style/WebTexture';
import WebBgImage from './components/pages/cv/style/WebBgImage';

import WebCVSettings from './components/pages/cv/settings/WebCVSettings';
import WebDateFormat from './components/pages/cv/settings/WebDateFormat';
import WebSectionSort from './components/pages/cv/settings/WebSectionSort';
import WebCustomizeTitle from './components/pages/cv/settings/WebCustomizeTitle';

import WebLinkedinRedirect from './components/pages/linkedin/WebLinkedinRedirect';
import WebLinkedinCallback from './components/pages/linkedin/WebLinkedinCallback';

import WebRoot from './components/pages/WebRoot';
import WebAppContainer from './components/pages/WebAppContainer';
import WebCredits from './components/pages/home/WebCredits';
import WebError from './components/pages/WebError';
import WebRecoverCV from "./components/pages/WebRecoverCV";
import WebPublicCV from "./components/pages/WebPublicCV";



const store = configureStore({
	reducer: persistReducer({ key: 'root', storage }, combineReducers({ userReducer, cvReducer, newsReducer, courseReducer, appReducer, webReducer, jobReducer, bookReducer })),
	middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] } })
		.prepend([getUserMiddleware().middleware, getCvMiddleware().middleware])
});

const persistor = persistStore(store)

const router = createBrowserRouter([
	{
		path: "/",
		element: <WebRoot />,
		errorElement: <WebError />,
		children: [
			{ path: "linkedin", element: <WebLinkedinRedirect /> },
			{ path: "linkedin/callback", element: <WebLinkedinCallback /> },
			{ path: "linkedin/callback.html", element: <WebLinkedinCallback /> },

			{ path: "cv/:publicLinkId", element: <WebPublicCV /> },
			{ path: "recover-cv/:result", element: <WebRecoverCV /> },

			{
				path: "app",
				loader: () => {
					let platform = "web"
					if (window.webkit && window.webkit.messageHandlers) {
						if (window.webkit.messageHandlers.macos) platform = "macos"
						if (window.webkit.messageHandlers.visionos) platform = "visionos"
					}
					return redirect(`/app/${platform}`);
				},
			},

			{
				path: "/app/:platform",
				loader: ({ params, request }) => {
					const { platform } = params
					const { url } = request
					if (platform === "macos" && !window?.webkit?.messageHandlers?.macos) return redirect(url.replace("macos", "web"))
					if (platform === "visionos" && !window?.webkit?.messageHandlers?.visionos) return redirect(url.replace("visionos", "web"))
					if (!supportedWebPlatforms.includes(platform)) return redirect(url.replace(platform, "web"))
					return null
				},
				element: <WebAppContainer />,
				children: [
					{ path: "sign", element: <WebSign key={"login"} action={"login"} /> },
					{ path: "reauth", element: <WebSign key={"reauth"} action={"reauth"} /> },
					{ path: "convert", element: <WebSign key={"convert"} action={"convert"} /> },
					{ path: "paywall", element: <WebPaywall /> },

					{
						path: "onboarding",
						element: <WebIntro />,
						children: [
							{ path: "first-name", element: <WebIntroDisplayName key={"firstName"} type={"first"} /> },
							{ path: "last-name", element: <WebIntroDisplayName key={"lastName"} type={"last"} /> },
							{ path: "job-title", element: <WebIntroJobTitle /> },
							{ path: "avatar", element: <WebIntroAvatar /> },
							{ path: "seniority", element: <WebIntroSeniority /> },
							{ path: "goal", element: <WebIntroGoal /> },
							{ path: "timing", element: <WebIntroTiming /> },
							{ path: "personality", element: <WebIntroPersonality /> },
							{ path: "location", element: <WebIntroLocation /> },
							{ path: "import-cv", element: <WebIntroImportCV /> },
							{ path: "spinner", element: <WebIntroSpinner /> },
							{ path: "features", element: <WebIntroFeatures /> },
							{ path: "feedback", element: <WebIntroFeedback /> },
							{ path: "paywall", element: <WebPaywall key={"onboardingPaywall"} onboarding={true} /> },
							{ path: "convert", element: <WebSign key={"convert"} onboarding={true} action={"convert"} /> },
						]
					},

					{
						path: "cv",
						element: <WebCVOverview />,
						children: [
							{
								path: "edit",
								element: <WebCVEdit />,
								children: [
									{ path: "personal-data", element: <WebPersonalInfo /> },
									{ path: "photo", element: <WebPhoto /> },
									{ path: "contact-info-phones", element: <WebContactInfo elType={"Telephone"} /> },
									{ path: "contact-info-websites", element: <WebContactInfo elType={"Website"} /> },
									{ path: "contact-info-social-accounts", element: <WebContactInfo elType={"SocialAccount"} /> },
									{ path: "contact-info-instant-messaging", element: <WebContactInfo elType={"InstantMessaging"} /> },

									{ path: "summary", element: <WebOnlyEditor elType={"Summary"} /> },
									{ path: "work-experience", element: <WebFoundamentalItem elType={"WorkExperience"} /> },
									{ path: "education", element: <WebFoundamentalItem elType={"Education"} /> },
									{ path: "communication-skills", element: <WebOnlyEditor elType={"Communication"} /> },
									{ path: "organisational-skills", element: <WebOnlyEditor elType={"Organisational"} /> },
									{ path: "job-related-skills", element: <WebOnlyEditor elType={"JobRelated"} /> },
									{ path: "computer-skills", element: <WebOnlyEditor elType={"Computer"} /> },
									{ path: "other-skills", element: <WebOnlyEditor elType={"Other"} /> },

									{ path: "driver-licence", element: <WebDriverLicense /> },
									{ path: "special-driver-licence", element: <WebDriverLicenseSpecial /> },

									{ path: "mother-tongue", element: <WebMotherTongue /> },
									{ path: "foreign-languages", element: <WebForeignLanguage /> },

									{ path: "headline", element: <WebHeadline /> },
									{ path: "keywords", element: <WebKeyword /> },
									{ path: "abilities", element: <WebAbility /> },
									{ path: "portfolio", element: <WebPortfolio /> },
									{ path: "achievements", element: <WebAchievement /> },
									{ path: "attachments", element: <WebAttachment /> },
									{ path: "signature", element: <WebSignature /> },
									{ path: "video-presentation", element: <WebVideo /> },

									{ path: "cover-letter", element: <WebOnlyEditor elType={"CoverLetter"} /> },

								]
							},

							{
								path: "style",
								element: <WebCVStyle />,
								children: [
									{ path: "model", element: <WebModel /> },
									{ path: "photo-format", element: <WebPhotFormat /> },
									{ path: "header-texture", element: <WebTexture /> },
									{ path: "header-image", element: <WebBgImage /> },
								]
							},

							{
								path: "settings",
								element: <WebCVSettings />,
								children: [
									{ path: "date-format", element: <WebDateFormat /> },
									{ path: "sort-sections", element: <WebSectionSort /> },
									{ path: "custom-titles", element: <WebCustomizeTitle /> },
								]
							},


							{ path: "scan", element: <WebCVScan /> },
							{ path: "public-link", element: <WebCVPublicLink /> },

						],
					},

					{
						path: "home",
						element: <WebHome />,
						children: [
							{ path: "cv-list", element: <WebCvList /> },
							{ path: "salary", element: <WebSalary /> },
							{ path: "services", element: <WebServices /> },
							{ path: "account", element: <WebAccount /> },
							{ path: "credits", element: <WebCredits /> },

							{
								path: "book-list",
								element: <WebBookContainer />,
								children: [
									{ path: ":bookId", element: <WebBookDetail /> },
								]
							},

							{
								path: "news-list",
								element: <WebNewsContainer />,
								children: [
									{ path: ":newsId", element: <WebNewsDetail /> },
								]
							},

							{
								path: "course-list",
								element: <WebCourseContainer />,
								children: [
									{ path: ":courseId", element: <WebCourseDetail /> },
								]
							},

							{
								path: "job-list",
								element: <WebJobContainer />,
								children: [
									{ path: ":jobId", element: <WebJobDetail /> },
								]
							}
						],
					},

				],
			}
		]

	},


]);

const App = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<NextUIProvider>
				<RouterProvider router={router} />
			</NextUIProvider>
		</PersistGate>
	</Provider >
);

export default App;
