import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Link, Button } from "@nextui-org/react";
import { useNavigate, useLocation, Outlet, useParams } from 'react-router-dom';
import { signInAnonymously } from "firebase/auth";
import { urls } from "cvpop-constants-sdk";
import { setUser } from 'cvpop-redux-sdk'
import { lotties } from "cvpop-assets-sdk";
import { t } from "cvpop-localization-sdk";

import { auth } from "../../../controllers/firebase";
import { postLogin } from '../../../controllers/user';
import { errorHandler, openUrl } from '../../../controllers/app';

import Lottie from 'react-lottie';

import WebNavbar from "../../atoms/WebNavbar"


const WebIntro = () => {

    // ------------------------------------------------------------------------------------------------------
    const { language } = useSelector(state => state.webReducer)
    const { platform } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------
    const { terms, privacy } = urls.legal

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const isNavSign = useMemo(() => [`/app/${platform}/onboarding/convert`, `/app/${platform}/onboarding/convert/`].includes(pathname), [platform, pathname])
    const isNavOutlet = useMemo(() => ![`/app/${platform}/onboarding`, `/app/${platform}/onboarding/`].includes(pathname), [platform, pathname])

    // ------------------------------------------------------------------------------------------------------
    const createFbAccount = useCallback(() => {
        setLoading(true)
        signInAnonymously(auth)
            .then(cred => postLogin({ user: cred.user, language, platform }))
            .then(user => {
                d(setUser({ user, ignoreListener: true }))
                n(`/app/${platform}/onboarding/first-name`)
            })
            .catch(e => errorHandler("err_create_firebase_account", e))
            .finally(() => setLoading(false))
    }, [d, n, language])

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (!isNavSign && fbuser) n(`/app/${platform}/onboarding/first-name`)
    }, [isNavSign, fbuser])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            {!isNavSign && (<WebNavbar onboarding={true} />)}

            {
                isNavOutlet ? (
                    <Outlet />
                ) : (
                    <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
                        <Card className='max-w-md m-3 mb-0'>
                            <CardBody>
                                <div className="max-w-80 self-center">
                                    <Lottie options={{ animationData: lotties.intro }} autoPlay loop />
                                </div>
                                <p className="font-sans font-bold text-2xl text-center mt-3">{t("start")}</p>
                                <p className="font-sans text-center mt-3">{t("introMsg")}</p>

                                <Button color="primary" className='mt-3' isLoading={loading} onClick={() => createFbAccount()}>
                                    {t("continue")}
                                </Button>

                                <Button color="primary" variant={"light"} className='mt-3' onClick={() => n(`/app/${platform}/sign`)} >
                                    {t("login")}
                                </Button>
                            </CardBody>
                        </Card>
                        <p className='font-sans text-xs text-center my-3'>
                            <span>{t("introDisclaimer")} </span>
                            <Link href={"#"} onClick={() => openUrl(terms, platform)} className='font-sans text-xs'>{t("terms")}</Link>
                            <span> {t("and")} </span>
                            <Link href={"#"} onClick={() => openUrl(privacy, platform)} className='font-sans text-xs'>{t("privacy")}</Link>
                        </p>
                    </div>
                )
            }

        </div>
    );
}

export default WebIntro