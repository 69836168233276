const { cvPickerItems } = require("cvpop-constants-sdk")
const { t } = require("cvpop-localization-sdk")

module.exports = {
    sectionTitle: (cv, tkey, id, icon) => {

        const { Metadata } = cv

        const locale = Metadata.lng === 'no' ? 'nb' : Metadata.lng

        let title = t(tkey, { locale })
        if (Metadata[`customTitle${id}`]) title = Metadata[`customTitle${id}`];
        if (["headline", "addInfoTitle", "gender"].includes(id)) title = t((cvPickerItems[id]().find(e => e.code === tkey) || { label: "" }).label, { locale })

        return `
        <div class="section-title-container">
            ${icon ? `<div class="section-title-icon"><i class="${icon}"></i></div>` : ""}
            <div class="section-title-content">
                <p class="section-title">${title}</p>
            </div>
        </div>
        <div class="section-title-keyline"></div>
        `
    }
}