const _ = require("lodash")

const { sectionTitle } = require("./section-title")

module.exports = {
    video: cv => {

        const { Video = {} } = cv.SkillsPassport.LearnerInfo

        if (_.isEmpty(Video)) return ""
        return `
            <div class="video-container container">
                ${sectionTitle(cv, "cvLabelVideo", "Video", "fas fa-video")}
                <div class="video-content content">
                    <img class="video-item ptXS" src="data:image/png;base64,${Video.qrCodeData}" style="width: 150px; height: 150px">
                </div>
            </div>
        `

    }
}

