import { useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Link, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react"
import { produce } from "immer"
import { collection, where, query, orderBy, limit, getDocs } from "firebase/firestore";

import _ from "lodash"

import { setCv } from "cvpop-redux-sdk"
import { createRandomString } from "cvpop-utility-sdk"
import { illustrations } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk"

import { confirmDelete, errorHandler, openUrl } from "../../../controllers/app"
import { useColorScheme } from "../../../controllers/hooks"

import WebHeader from "../../molecules/views/WebHeader"
import WebCenterView from "../../molecules/views/WebCenterView"
import WebIcon from "../../atoms/WebIcon"
import { db } from "../../../controllers/firebase"


const WebCVPublicLink = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const { publicLinkId } = cv.Metadata

    // ------------------------------------------------------------------------------------------------------
    const publicUrl = useMemo(() => publicLinkId ? `https://cvpop.com/cv/${publicLinkId}` : null, [publicLinkId])

    // ------------------------------------------------------------------------------------------------------
    const onAction = useCallback(i => {
        const opt = { message: t("deletePublicLinkMsg") }
        if (i === "copy") return window.navigator.clipboard.writeText(publicUrl);
        if (i === "delete") return confirmDelete({ opt, cb: () => d(setCv({ cv: produce(cv, d => { delete d.Metadata.publicLinkId }) })) })
    }, [cv])

    const publish = useCallback(async () => {

        setLoading(true)

        let linkId = null
        while (true) {
            linkId = createRandomString()
            try {
                const snap = await getDocs(query(collection(db, "00_RESUMES_00"), where("Metadata.publicLinkId", "==", linkId)))
                if (snap.empty) break
            } catch (e) {
                errorHandler("err_publish_cv", e)
                setLoading(false)
                break;
            }
        }

        d(setCv({ cv: produce(cv, d => { d.Metadata.publicLinkId = linkId }) }))
        setLoading(false)

    }, [cv])


    // ------------------------------------------------------------------------------------------------------
    const renderActionButton = useCallback(() => (
        <Button
            fullWidth
            isLoading={loading}
            className="mt-4"
            color={"primary"}
            onClick={(publicLinkId) ? null : () => publish()}
        >
            {t(publicLinkId ? "showOptions" : "create")}
        </Button>
    ), [loading, publicLinkId])


    return (
        <div>
            <WebHeader title={t("publicLink")} />
            <div className="items-center justify-center flex p-4" style={{ height: `calc(100vh - 140px)` }}>
                <WebCenterView
                    imageProps={{ src: illustrations.publiclink }}
                    title={t(publicLinkId ? "yourCvIsPublic" : "publicLink")}
                    message={!publicLinkId && t("publicLinkMsg")}
                >
                    {publicLinkId && (
                        <Link className="mt-4 text-center self-center" onClick={() => openUrl(publicUrl, platform)}>
                            {publicUrl}
                        </Link>
                    )}

                    {
                        publicLinkId ? (
                            <Dropdown backdrop="blur" className={`${cs} text-foreground`}>
                                <DropdownTrigger>
                                    {renderActionButton()}
                                </DropdownTrigger>
                                <DropdownMenu
                                    aria-label="Static Actions"
                                    color={"primary"}
                                    onAction={onAction}
                                >
                                    <DropdownItem key="copy" title={_.capitalize(t("copy"))} endContent={<WebIcon name={"content_copy"} />} />
                                    <DropdownItem key="delete" title={t("delete")} className="text-danger" color="danger" endContent={<WebIcon name={"delete"} />} />
                                </DropdownMenu>
                            </Dropdown>

                        ) : (renderActionButton())
                    }
                </WebCenterView>
            </div>
        </div>
    )
}

export default WebCVPublicLink