import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Divider } from "@nextui-org/react";
import { useParams } from "react-router-dom";

const WebHeader = ({ title = "", ...props }) => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------
    if (platform === "web") return null
    return (
        <Navbar isBordered className="h-24 bg-content1" {...props}>
            <NavbarBrand>
                <p className="font-bold text-2xl">{title}</p>
            </NavbarBrand>
            <NavbarContent className="hidden sm:flex gap-4" justify="center" style={{}}>
                <NavbarItem>
                    {/* <Link color="foreground" href="#">
                        Features
                    </Link> */}
                </NavbarItem>
                <NavbarItem isActive>
                    {/* <Link href="#" aria-current="page">
                        Customers
                    </Link> */}
                </NavbarItem>
                <NavbarItem>
                    {/* <Link color="foreground" href="#">
                        Integrations
                    </Link> */}
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem className="hidden lg:flex">
                </NavbarItem>
                <NavbarItem>
                    {/* <Button as={Link} color="primary" href="#" variant="flat">
                        Sign Up
                    </Button> */}
                </NavbarItem>
            </NavbarContent>
        </Navbar>
        // </div>
    )
}

export default WebHeader