import { useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, Outlet, useParams, useLocation } from "react-router-dom";
import { Avatar, Listbox, ListboxItem, Button } from "@nextui-org/react";

import { setCourseList } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk";

import _ from "lodash"

import { errorHandler } from "../../../../controllers/app";
import { functions } from "../../../../controllers/firebase";
import { httpsCallable } from "firebase/functions";
import { pictures } from "cvpop-assets-sdk";

const WebCourseContainer = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { courseList } = useSelector(state => state.courseReducer)
    const { language } = useSelector(state => state.webReducer)
    const { platform, courseId } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------------------------
    const courseApi = useMemo(() => httpsCallable(functions, "getcourselist"), [functions]);
    const isWeb = useMemo(() => platform === "web", [platform])

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(2) // start always from 2

    // ------------------------------------------------------------------------------------------------------
    const getCourseList = useCallback(() => {
        setLoading(true)
        courseApi({ jobTitle: user.professionalTitle, page, language })
            .then(({ data }) => {
                d(setCourseList([...courseList, ...data.results]))
                setPage(page + 1)
            })
            .catch(e => errorHandler("err_search_courses", e, true))
            .finally(() => setLoading(false))
    }, [user.professionalTitle, courseList, language, page])

    // ------------------------------------------------------------------------------------------------------------------------ 
    return (
        <div className="flex" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>

            <div className="w-4/12  overflow-y-auto bg-content1 p-1 pb-8 border-r border-divider" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>

                <Listbox
                    variant={"flat"}
                    onAction={k => n(`${k}`)}
                    disabledKeys={[courseId]}
                    classNames={{ base: "pb-0" }}
                    bottomContent={(
                        <Button color={"primary"} className="mt-4" isLoading={loading} onClick={() => getCourseList()}>
                            {t("showMore")}
                        </Button>
                    )}
                >
                    {
                        courseList.map(({ id, title, headline, image_480x270 }, i) => (
                            <ListboxItem
                                key={id}
                                startContent={(<Avatar radius="sm" src={image_480x270} fallback={pictures.genericPlaceholder} name={title} />)}
                                title={title}
                                description={headline}
                                className={courseId === String(id) && `opacity-100 text-primary-foreground bg-primary`}
                                classNames={{ title: "whitespace-normal font-semibold", description: courseId === String(id) && "text-primary-50" }}
                                showDivider={i < courseList.length - 1}
                            />
                        ))
                    }
                </Listbox>

            </div>

            {
                ([`/app/${platform}/home/course-list`, `/app/${platform}/home/course-list/`].includes(pathname)) ? (
                    <div className="w-8/12 bg-content1 flex items-center justify-center" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>
                        <p className="text-3xl text-center text-default p-8">{t("noItemsSelected")}</p>
                    </div>
                ) : (
                    <Outlet />
                )
            }
        </div>
    )
}

export default WebCourseContainer